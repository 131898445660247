import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { EncounterOverride, ExclusionGroup, PreventEncounters } from '../models/ExclusionGroup';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx/app-state/app-state';
import {
	createExclusionGroup,
	getExclusionGroups,
	removeExclusionGroup,
	updateExclusionGroup,
} from '../ngrx/encounters-prevention/excusion-groups/actions';
import {
	getCurrentExclusionGroup,
	getEncounterPreventionLength,
	getExclusionGroupsCollection,
	getExclusionGroupsLength,
	getExclusionGroupsLoaded,
	getExclusionGroupsLoading,
} from '../ngrx/encounters-prevention/excusion-groups/states/exclusion-groups-getters.state';
import { constructUrl } from '../live-data/helpers';
import { filter, map } from 'rxjs/operators';
import { User } from 'app/models/User';

export interface EncounterPreventionWithOverride extends PreventEncounters {
	override?: EncounterOverride;
}

export interface ExclusionGroupWithOverrides extends ExclusionGroup {
	prevented_encounters: EncounterPreventionWithOverride[];
}

export type ExclusionGroupQueryParams = Partial<{
	student: number | number[];
	encounterless: boolean;
}>;

export interface CreateExclusionGroupParams {
	name: string;
	notes: string;
	students: User[];
	enabled: boolean;
}

export interface GetExclusionGroupsWithOverridesParams {
	studentId?: number;
	excludeEncounters?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class EncounterPreventionService {
	exclusionGroups$: Observable<ExclusionGroup[]> = this.store.select(getExclusionGroupsCollection);
	exclusionGroupsLoading$: Observable<boolean> = this.store.select(getExclusionGroupsLoading);
	exclusionGroupsLoaded$: Observable<boolean> = this.store.select(getExclusionGroupsLoaded);
	updatedExclusionGroup$: Observable<ExclusionGroup> = this.store
		.select(getCurrentExclusionGroup)
		.pipe(filter((group): group is ExclusionGroup => group !== undefined));
	exclusionGroupsLength$: Observable<number> = this.store.select(getExclusionGroupsLength);
	encounterPreventionLength$: Observable<number> = this.store.select(getEncounterPreventionLength);

	constructor(private http: HttpService, private store: Store<AppState>) {}

	getExclusionGroupsRequest(queryParams?: ExclusionGroupQueryParams) {
		this.store.dispatch(getExclusionGroups({ queryParams: queryParams || {} }));
	}
	getExclusionGroups(queryParams: ExclusionGroupQueryParams): Observable<ExclusionGroup[]> {
		return this.http.get(constructUrl('v1/exclusion_groups', queryParams));
	}

	createExclusionGroupRequest(group: CreateExclusionGroupParams) {
		this.store.dispatch(createExclusionGroup({ groupData: group }));
	}

	createExclusionGroup(group: CreateExclusionGroupParams): Observable<ExclusionGroup> {
		return this.http.post<ExclusionGroup>(`v1/exclusion_groups`, group);
	}

	updateExclusionGroupRequest(group: ExclusionGroup, updateFields: Partial<CreateExclusionGroupParams>) {
		this.store.dispatch(updateExclusionGroup({ group, updateFields }));
		return this.updatedExclusionGroup$;
	}

	updateExclusionGroup(group: ExclusionGroup, updateFields: Partial<CreateExclusionGroupParams>) {
		return this.http.patch(`v1/exclusion_groups/${group.id}`, updateFields);
	}

	deleteExclusionGroupRequest(group: ExclusionGroup) {
		this.store.dispatch(removeExclusionGroup({ group }));
	}

	deleteExclusionGroup(groupId: number) {
		return this.http.delete(`v1/exclusion_groups/${groupId}`);
	}

	getEncounterPreventionOverrides(groupId?: number): Observable<EncounterOverride[]> {
		return this.http.post('v2/exclusion_groups/list_overrides', { group_id: groupId }, undefined, false);
	}

	getExclusionGroup(groupId: number): Observable<ExclusionGroup> {
		return this.http.get(`v1/exclusion_groups/${groupId}`);
	}

	getExclusionGroupsWithOverrides({
		studentId,
		excludeEncounters,
	}: GetExclusionGroupsWithOverridesParams): Observable<ExclusionGroupWithOverrides[]> {
		const params: ExclusionGroupQueryParams = {};
		if (studentId) {
			params['student'] = studentId;
		}
		if (excludeEncounters) {
			params['encounterless'] = true;
		}
		return forkJoin([this.getExclusionGroups(params), this.getEncounterPreventionOverrides()]).pipe(
			map(([groups, overrides]) => {
				return groups.map((group) => {
					return {
						...group,
						prevented_encounters: (group.prevented_encounters ?? []).map((encounter) => {
							return {
								...encounter,
								override: overrides.find((override) => override.id === encounter?.override_id),
							};
						}),
					};
				});
			})
		);
	}

	getExclusionGroupsWithOverridesStudentMap(excludeEncounters?: boolean): Observable<Map<number, ExclusionGroupWithOverrides[]>> {
		return this.getExclusionGroupsWithOverrides({ excludeEncounters: excludeEncounters }).pipe(
			map((groups) => {
				const studentMap = new Map<number, ExclusionGroupWithOverrides[]>();
				groups.forEach((group) => {
					group.users.forEach((student: User) => {
						const currentGroups = studentMap.get(student.id);
						studentMap.set(student.id, [...(currentGroups || []), group]);
					});
				});
				return studentMap;
			})
		);
	}

	countNumOverrides(group: ExclusionGroupWithOverrides): number {
		return (group.prevented_encounters ?? []).reduce((count, encounter) => count + (encounter?.override_id ? 1 : 0), 0);
	}
}
