import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { School, User } from 'app/models';
import { AuthenticationService } from 'app/services/authentication.service';
import { RouteMemoService } from 'app/services/route-memo.service';
import { UserService } from 'app/services/user.service';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FeatureFlagService, FLAGS } from '../services/feature-flag.service';
import { HttpService } from '../services/http-service';

@Injectable({
	providedIn: 'root',
})
export class RootRedirectGuard implements CanActivate {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private userService: UserService,
		private activeRoute: ActivatedRoute,
		private httpService: HttpService,
		private featureFlags: FeatureFlagService,
		private routeMemo: RouteMemoService
	) {}

	canActivate() {
		return this.authenticationService.isAuthenticated$.pipe(
			switchMap((isAuthenticated) => {
				if (!isAuthenticated) {
					// If the user is not yet authenticated, send them to the login forms.
					return of(this.router.parseUrl('/'));
				}

				return combineLatest([this.featureFlags.isFeatureEnabledV2$(FLAGS.GetStarted), this.httpService.currentSchool$, this.userService.user$]).pipe(
					switchMap(([isGetStartedEnabled, school, user]) => {
						if (user.isParent()) {
							return of(this.router.parseUrl('/parent'));
						}

						return combineLatest([this.routeMemo.recall('/'), this.activeRoute.queryParams]).pipe(
							map(([lastURL, params]) => {
								const state = params['state'];

								if (state) {
									const decodedState = decodeURIComponent(state);
									return this.router.parseUrl(`/${decodedState}`);
								}

								if (lastURL) {
									return this.router.parseUrl(lastURL);
								}

								if (isGetStartedEnabled && this.adminFirstLoginShouldRedirect(user, school)) {
									return this.router.parseUrl('/admin/get-started');
								}

								return this.router.parseUrl('/main');
							})
						);
					})
				);
			})
		);
	}

	adminFirstLoginShouldRedirect(user: User, school: School) {
		return school.onboarding_status !== 'Launched' && user.isAdmin() && user.isFirstLogin();
	}
}
