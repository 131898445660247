<div class="wrapper tw-animate-fadein-100" (mouseleave)="leave.emit()">
	<div class="header">
		<div class="header-content">
			<div class="title">Encounter Prevention</div>
			<div class="subtitle">{{ selectedGroup ? 'This student can’t have passes at the same time as:' : 'Unable to retrieve group' }}</div>
		</div>
	</div>

	<div class="content" *ngIf="selectedGroup">
		<div class="group-wrapper" [@NextStep]="{ value: '', params: frameMotion$ | async }">
			<div
				appCrossPointerEventTarget
				(pointerClickEvent)="goToEncounterPreventionGroup()"
				class="group tw-flex tw-flex-col tw-track-w-2 tw-track-transparent-thumb-gray-400">
				<div class="group-name">
					{{ selectedGroup.name
					}}<span *ngIf="selectedGroup.override_count" class="tw-opacity-50 tw-ml-1 tw-font-normal"
						>{{ selectedGroup.override_count }} override{{ selectedGroup.override_count !== 1 ? 's' : '' }}</span
					>
				</div>
				<div class="prevention-students tw-flex-col tw-items-start tw-mr-auto">
					<div class="student tw-mr-auto" *ngFor="let student of selectedGroup.users">
						<div class="student-picture">
							<div
								*ngIf="student.profile_picture; else d"
								[ngStyle]="{ background: 'url(' + student.profile_picture + ') no-repeat left center/cover', width: '23px', height: '23px' }"></div>
							<ng-template #d>
								<img width="23" height="23" [src]="'./assets/Avatar Default.svg' | resolveAsset" alt="" />
							</ng-template>
						</div>
						<div class="name">{{ student.display_name }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="group-notes tw-m-1.5 tw-mt-0.5 tw-mr-2.5" *ngIf="selectedGroup.notes">{{ selectedGroup.notes }}</div>
		<div class="paginator" *ngIf="filteredGroups.length > 1">
			<div class="button" style="left: 0" *ngIf="pageIndex > 0" appCrossPointerEventTarget (pointerClickEvent)="prevGroup()">
				<img class="tw-h-4.5 tw-w-2.5" [src]="'./assets/Chevron Left (White).svg' | resolveAsset" />
			</div>
			<div class="dots">
				<div
					class="dot"
					*ngFor="let _ of filteredGroups; let i = index"
					[style.background-color]="pageIndex === i ? '#FFFFFF' : 'rgba(255,255,255, 0.5)'"
					appCrossPointerEventTarget
					(pointerClickEvent)="setGroupIndex(i)"></div>
			</div>
			<div class="button" style="right: 0" *ngIf="pageIndex < filteredGroups.length - 1" appCrossPointerEventTarget (pointerClickEvent)="nextGroup()">
				<img class="tw-h-4.5 tw-w-2.5" [src]="'./assets/Chevron Right (White).svg' | resolveAsset" />
			</div>
		</div>
	</div>
</div>
