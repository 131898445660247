import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, from, iif, Observable, of, Subject, throwError, timer } from 'rxjs';
import { concatMap, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Util } from '../../../Util';
import { ConsentMenuComponent } from '../../consent-menu/consent-menu.component';
import { TimerSpinnerComponent } from '../../core/components/timer-spinner/timer-spinner.component';
import { PositionPipe } from '../../core/position.pipe';
import { CreateFormService } from '../../create-hallpass-forms/create-form.service';
import { User, WaitingInLinePass } from '../../models';
import { HallPassesService, METRICS_FOOTER_HEIGHT } from '../../services/hall-passes.service';
import { KioskModeService } from '../../services/kiosk-mode.service';
import { LiveUpdateEvent } from '../../services/live-update.service';
import { ScreenService } from '../../services/screen.service';
import { TimeService } from '../../services/time.service';
import { UserService } from '../../services/user.service';
import { WaitInLineService } from '../../services/wait-in-line.service';
import { FeatureFlagService, FLAGS } from '../../services/feature-flag.service';
import { HomepageService } from '../../services/homepage.service';

const positionTransformer = new PositionPipe().transform;

export enum WaitInLineFavicon {
	InLine = 'assets/icons/Clock (Blue-Gray).png',
	UpdatePosition = 'assets/icons/Clock (Light-Gray).png',
	FrontOfLine = 'assets/icons/Caution.png',
	HourglassToggle1 = 'assets/icons/Hourglass filled.png',
	HourglassToggle2 = 'assets/icons/Hourglass Empty.png',
	Transparent = 'assets/icons/transparent.png',
}

export enum WILHeaderOptions {
	Delete = 'delete',
	Start = 'start',
}

/**
 * This component is the main piece of UI for interacting with Waiting In Line and is
 * responsible for the following:
 *
 * - Starting a WaitingInLinePass
 * - Deleting a WaitingInLinePass
 * - Showing component as a fullscreen focused dialog when a pass is ready to start (student and kiosk)
 * - Showing component as a dialog for passes currently waiting in line
 */
@Component({
	selector: 'wait-in-line-card',
	templateUrl: './wait-in-line-card.component.html',
	styleUrls: ['./wait-in-line-card.component.scss'],
	providers: [PositionPipe],
})
export class WaitInLineCardComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	// TODO: make @Input wil into a setter
	@Input() wil: WaitingInLinePass;
	@Input() forStaff: boolean;

	@ViewChild(TimerSpinnerComponent) timeSpinner: TimerSpinnerComponent;

	@ViewChild('waitingDots') set dotsSpan(span: ElementRef<HTMLSpanElement>) {
		if (!span) {
			return;
		}

		timer(0, 750)
			.pipe(
				takeUntil(this.destroy$),
				tap((count) => {
					span.nativeElement.innerText = '.'.repeat(count % 4);
					count++;
				})
			)
			.subscribe();
	}

	@HostListener('window:resize', ['$event.target'])
	onResize() {
		let footerHeight = 0;
		if (this.openedFromPassTile) {
			footerHeight = METRICS_FOOTER_HEIGHT;
		}
		this.hallPassService.scaleMatDialog(this.dialogRef, this.viewContainerRef.element.nativeElement, footerHeight);
		this.cdr.detectChanges();
	}

	gradient: string;
	requestLoading = false;
	frameMotion$: BehaviorSubject<any>;
	destroy$: Subject<any> = new Subject<any>();
	acceptingPassTimeRemaining: number;
	user: User;
	originRoomIcon: string;
	backgroundGradient: string;
	formattedDuration = '';
	wilUiRefresh: boolean;
	closedByWilStart: boolean;

	waitInLineTitle: Observable<string> = timer(0, 750).pipe(
		takeUntil(this.destroy$),
		map((count) => {
			return `${this.positionPipe.transform(this.wil.line_position)} in Line${'.'.repeat(count % 4)}`;
		})
	);
	waitInLineRefreshTitle: Observable<string> = timer(0, 750).pipe(
		takeUntil(this.destroy$),
		map((count) => {
			return `Waiting in Line${'.'.repeat(count % 4)}`;
		})
	);

	constructor(
		@Optional() private dialogRef: MatDialogRef<WaitInLineCardComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public dialogData: {
			nextInLine: boolean;
			pass: WaitingInLinePass;
			forStaff: boolean;
		},
		private viewContainerRef: ViewContainerRef,
		private userService: UserService,
		private formService: CreateFormService,
		private hallPassService: HallPassesService,
		private dialog: MatDialog,
		public kioskService: KioskModeService,
		private cdr: ChangeDetectorRef,
		private wilService: WaitInLineService,
		private positionPipe: PositionPipe,
		private screenService: ScreenService,
		private timeService: TimeService,
		private featureFlagService: FeatureFlagService,
		private homepageService: HomepageService
	) {}

	get openedFromPassTile(): boolean {
		return this.forStaff || this.isKiosk;
	}

	get optionsIcon() {
		return this.forStaff && !this.isKiosk ? './assets/Dots (Transparent).svg' : './assets/Delete (White).svg';
	}

	get line_position(): number {
		return this.wil.line_position;
	}

	get getUserName() {
		return this.wil.issuer.id == this.user.id ? 'Me' : this.wil.issuer.display_name;
	}

	get isKiosk() {
		return this.kioskService.isKioskMode();
	}

	get remainingAttemptSeconds(): number {
		return Math.floor((this.wil.start_attempt_end_time.getTime() - this.timeService.now()) / 1000);
	}

	ngOnChanges(): void {
		if (this.wil.line_position !== 0) {
			timer(0, 750)
				.pipe(
					filter(() => this.wil.line_position !== 0),
					takeUntil(this.destroy$),
					tap((tick: number) => {
						this.changeTitle(`${positionTransformer(this.wil.line_position)} in line` + `${'.'.repeat(tick % 4)}`);
						this.changeFavicon(WaitInLineFavicon.InLine);
					})
				)
				.subscribe();
		}
	}

	ngOnInit(): void {
		this.wilUiRefresh = this.featureFlagService.isFeatureEnabledV2(FLAGS.WaitInLineUIRefresh);

		if (this.dialogData) {
			if (this.dialogData.pass instanceof WaitingInLinePass) {
				// it's not enough for the JSON to have the data, it must be an instance of the class
				this.wil = this.dialogData.pass;
			}
			this.forStaff = this.dialogData.forStaff;
			this.calculateFormattedDuration();
			this.setBackDropColor();

			if (this.wil.isReadyToStart()) {
				this.wilService
					.listenForWilUpdate(this.wil.id)
					.pipe(
						takeUntil(this.destroy$),
						map<LiveUpdateEvent, WaitingInLinePass>((event) => WaitingInLinePass.fromJSON(event.data)),
						tap((wil) => {
							this.changeFavicon(WaitInLineFavicon.FrontOfLine);
							if (!wil.isReadyToStart() && wil.missed_start_attempts > 0) {
								// not ready to start and having missed attempts means the student did not start their pass in time
								// and this pass is moved to the back of the line
								this.closeDialog();
								return;
							}

							if (wil.isReadyToStart() && wil.missed_start_attempts > this.wil.missed_start_attempts) {
								// ready to start and more than one missed attempts mean that this is the only student waiting in line
								// and the student did not start their pass in time
								this.timeSpinner.reset();
							}
						})
					)
					.subscribe();
			}
		}
		const gradientArray = this.wil.color_profile.gradient_color.split(',');
		this.backgroundGradient = 'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ', ' + gradientArray[1] + ')';
		this.getOriginRoomIcon();

		this.wilService
			.listenForWilDeletion(this.wil.id)
			.pipe(
				takeUntil(this.destroy$),
				map((e) => e.data),
				filter(Boolean)
			)
			.subscribe({
				next: (wil: WaitingInLinePass) => {
					this.wil.hall_pass_id = wil.hall_pass_id;
					this.closeDialog();
				},
			});

		this.userService.userJSON$
			.pipe(
				map((user) => User.fromJSON(user)),
				takeUntil(this.destroy$)
			)
			.subscribe((user) => {
				this.user = user;
			});
		// Instead of having this.formService.getFrameMotionDirection() in multiple places all across the code,
		// this should be included inside the app-pager component and target the relevant ng-content
		// children using @ContentChildren directive: https://angular.io/api/core/ContentChildren
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		if (this.openedFromPassTile) {
			// pass has already been created, this dialog has been opened by clicking on its pass-tile from inside a
			// pass-collection
			// this.wil = this.dialogData.pass;
			this.forStaff = this.dialogData.forStaff;
		}
		this.gradient = `linear-gradient(0deg, rgba(16, 20, 24, 0.30) 0%, rgba(16, 20, 24, 0.30) 100%), radial-gradient(circle at 73% 71%, ${this.wil?.color_profile.gradient_color})`;
	}

	ngAfterViewInit(): void {
		let footerHeight = 0;
		if (this.openedFromPassTile) {
			footerHeight = METRICS_FOOTER_HEIGHT;
		}
		this.hallPassService.scaleMatDialog(this.dialogRef, this.viewContainerRef.element.nativeElement, footerHeight);
		this.cdr.detectChanges();
	}

	private getOriginRoomIcon(): void {
		// make sure pinnables are loaded in the store before getting room icon
		this.hallPassService.loadedPinnables$
			.pipe(
				take(1),
				switchMap((loaded) => {
					return iif(() => loaded, of(null), this.hallPassService.getPinnablesRequest());
				}),
				switchMap(() => {
					return this.hallPassService.getPinnable(this.wil.origin);
				}),
				filter((pin) => !!pin),
				take(1)
			)
			.subscribe((pin) => {
				this.originRoomIcon = pin.icon;
			});
	}

	readyToStartTick(remainingTime: number): void {
		this.acceptingPassTimeRemaining = remainingTime;
		if (remainingTime > 27) {
			this.changeTitle('Time to Start Your Pass');
			this.changeFavicon(WaitInLineFavicon.FrontOfLine);
			setTimeout(() => {
				this.changeFavicon(WaitInLineFavicon.Transparent);
			}, 400);
			return;
		}

		if (remainingTime === 0 && this.wil.missed_start_attempts > 0) {
			this.changeTitle(`${this.user.display_name} | SmartPass`);
			this.changeFavicon('./assets/icons/favicon.ico');
			return;
		}

		if (remainingTime % 2 === 0) {
			this.changeTitle(`${remainingTime} sec left...`);
			this.changeFavicon(WaitInLineFavicon.HourglassToggle1);
		} else {
			this.changeTitle(`${remainingTime} sec left...`);
			this.changeFavicon(WaitInLineFavicon.HourglassToggle2);
		}
	}

	showOptions(clickEvent: MouseEvent): void {
		const target = new ElementRef(clickEvent.currentTarget);
		const targetElement = target.nativeElement as HTMLElement;
		const targetCoords = targetElement.getBoundingClientRect();

		const options = [
			{
				display: 'Delete Pass',
				color: '#E32C66',
				action: WILHeaderOptions.Delete,
				icon: './assets/Delete (Red).svg',
			},
		];

		if (this.forStaff && !this.isKiosk) {
			options.unshift({
				display: 'Skip the Line',
				color: '#7083A0',
				action: WILHeaderOptions.Start,
				icon: './assets/Pause (Blue-Gray).svg',
			});
		}

		const cancelDialog = this.dialog.open(ConsentMenuComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { options: options, trigger: target },
			position: {
				top: `${targetCoords.bottom + Math.abs(document.scrollingElement.getClientRects()[0].top) + 20}px`,
				left: `${targetCoords.left}px`,
			},
		});

		cancelDialog
			.afterClosed()
			.pipe(
				filter(Boolean),
				concatMap((action: WILHeaderOptions) => {
					if (action === WILHeaderOptions.Delete) {
						return this.wilService.deleteWilPass(this.wil.id);
					}

					if (action === WILHeaderOptions.Start) {
						return from(this.startPass());
					}

					return throwError(new Error('Invalid option chosen!'));
				}),
				finalize(() => this.closeDialog())
			)
			.subscribe();
	}

	async startPass(): Promise<any> {
		await this.homepageService.startWILPass(this.wil, this.forStaff);
		this.changeFavicon('./assets/icons/favicon.ico');
	}

	private closeDialog(shouldShowActivePassModal = false): void {
		if (this.dialogRef) {
			let showActivePassModal = '';
			if (shouldShowActivePassModal) {
				showActivePassModal = 'showActivePassModal';
			}
			this.dialogRef.close(showActivePassModal);
		}

		this.changeTitle(`${this.user.display_name} | SmartPass`);
		this.changeFavicon('./assets/icons/favicon.ico');
	}

	private changeTitle(tabTitle: string): void {
		this.wilService.setTabTitleForWilp(tabTitle);
	}

	private clearBackdrop(): void {
		const activePass = this.homepageService.currentPass$.getValue();
		if (!activePass) {
			this.screenService.clearCustomBackdrop();
			return;
		}

		if (this.user.isStudent() && activePass?.id !== this.wil.hall_pass_id) {
			this.screenService.clearCustomBackdrop();
			return;
		}

		if (!this.user.isStudent()) {
			this.screenService.clearCustomBackdrop();
			return;
		}
	}

	ngOnDestroy(): void {
		this.clearBackdrop();
		this.destroy$.next();
		this.destroy$.complete();
	}

	calculateFormattedDuration(): void {
		if (this.wil && typeof this.wil.duration === 'number') {
			const minutes = Math.floor(this.wil.duration / 60);
			const seconds = this.wil.duration % 60;
			this.formattedDuration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
		} else {
			this.formattedDuration = '';
		}
	}

	private setBackDropColor(): void {
		try {
			const solidColor = Util.convertHex(this.wil.color_profile.solid_color, 70);
			// #101418 overlay at 50%
			this.screenService.customBackdropStyle$.next({
				background: `linear-gradient(0deg, ${solidColor} 100%, rgba(0, 0, 0, 0.3) 100%)`,
			});
			this.screenService.customBackdropEvent$.next(true);
		} catch (error) {
			console.warn(error);
		}
	}

	private changeFavicon(assetLink: string): void {
		this.wilService.changeFaviconForWilp(assetLink);
	}
}
