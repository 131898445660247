import { Component, ComponentRef, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

export type SelectOption<T> = {
	display: string;
	textColor: string;
	backgroundColor: string;
	value: T;
};

@Component({
	selector: 'sp-starlight-select',
	template: `
		<div #trigger class="dropdown-container">
			<div
				class="selected-option"
				[style.color]="selected?.textColor || placeholder.textColor"
				[style.background-color]="selected?.backgroundColor || placeholder.backgroundColor"
				(click)="toggleOptionsVisibility($event)">
				{{ selected?.display || placeholder.display }}
				<!-- Triangle icon -->
				<svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path [attr.fill]="selected ? selected.textColor : placeholder.textColor" fill-rule="evenodd" clip-rule="evenodd" d="M4 6.5L8 0.5H0" />
				</svg>
			</div>
		</div>
	`,
	styles: [
		`
			.attendance-container {
				position: relative;
				display: flex;
			}

			.selected-option {
				padding: 6px 13px;
				cursor: pointer;
				border-radius: 17px;
				background: #e5f7f1;
				flex-shrink: 0;
				margin-right: 2px;
			}
		`,
	],
})
export class StarlightSelect<T> {
	@Input()
	options: SelectOption<T>[];

	@Input()
	selected: SelectOption<T>;

	@Input()
	placeholder: SelectOption<void>;

	@Output()
	onSelect: EventEmitter<SelectOption<T>> = new EventEmitter();

	@ViewChild('trigger')
	trigger: ElementRef;

	constructor(private overlay: Overlay, private positionBuilder: OverlayPositionBuilder, @Inject(DOCUMENT) private document: Document) {}

	private overlayRef: OverlayRef;
	private documentClickSubscription: Subscription;

	toggleOptionsVisibility(event: Event): void {
		event.stopPropagation();
		if (this.overlayRef) {
			this.overlayRef.dispose();
			this.overlayRef = null;
		} else {
			const positionStrategy = this.positionBuilder.flexibleConnectedTo(this.trigger).withPositions([
				{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
				},
			]);

			this.overlayRef = this.overlay.create({
				positionStrategy,
				hasBackdrop: false,
			});

			const dropdownPortal: ComponentPortal<StarlightSelectDropdown<T>> = new ComponentPortal(StarlightSelectDropdown);
			const dropdownRef: ComponentRef<StarlightSelectDropdown<T>> = this.overlayRef.attach(dropdownPortal);

			// Set inputs
			dropdownRef.instance.options = this.options;

			// Subscribe to output events
			dropdownRef.instance.selectOption.subscribe((selectedOption: SelectOption<T>) => {
				this.selected = selectedOption;
				this.onSelect.emit(selectedOption);
				this.closeOverlay();
			});
		}

		if (this.overlayRef) {
			// Unsubscribe from previous subscription
			this.documentClickSubscription?.unsubscribe();

			// Create a new subscription
			this.documentClickSubscription = this.subscribeToDocumentClickEvents();
		}
	}

	private subscribeToDocumentClickEvents(): Subscription {
		return fromEvent(this.document, 'click')
			.pipe(
				filter((event: MouseEvent) => {
					const clickTarget = event.target as HTMLElement;
					// Check if the click was outside the overlay
					return !this.overlayRef.overlayElement.contains(clickTarget);
				})
			)
			.subscribe(() => {
				this.closeOverlay();
			});
	}

	private closeOverlay() {
		// Close the overlay when clicking outside
		this.overlayRef.detach();
		this.overlayRef = null;
		this.documentClickSubscription?.unsubscribe();
	}

	ngOnDestroy(): void {
		this.documentClickSubscription?.unsubscribe();
		this.overlayRef?.dispose();
	}
}

@Component({
	selector: 'sp-starlight-select-dropdown',
	template: `
		<div class="options">
			<div class="option" *ngFor="let option of options" (click)="selectOption.emit(option)">
				<div [style.color]="option.textColor" [style.background-color]="option.backgroundColor" class="pill">
					{{ option.display }}
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			.options {
				/*position: absolute;*/
				/*top: 100%;*/
				/*left: 0;*/
				z-index: 1;
				margin-top: 7px;

				border-radius: 9px;
				border: 1px solid #d4d9e2;
				background: #fff;
				box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.06);
				backdrop-filter: blur(7px);
				/*	Round the last item when hovering */
				overflow: hidden;
			}

			.option:first-child {
				padding-top: 11px;
			}

			.option {
				padding: 4px 10px;
				cursor: pointer;
				display: flex;
			}

			.option:last-child {
				padding-bottom: 12px;
			}

			.pill {
				border-radius: 17px;
				padding: 6px 13px;
				flex-shrink: 0;
			}

			.option:hover {
				background: #f0f2f5;
			}
		`,
	],
})
export class StarlightSelectDropdown<T> {
	@Input()
	options: SelectOption<T>[];

	@Output()
	selectOption = new EventEmitter<SelectOption<T>>();
}
