import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLoadedSchools } from 'app/ngrx/schools/states';
import { BehaviorSubject, combineLatest, interval, merge, Observable, of, race, Subject } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { isStringIndexable, pickTruthy } from 'Util';
import { ROLES, School, User } from '../models';
import { SentryErrorHandler, UserContext } from '../error-handler';
import { constructUrl, QueryParams } from '../live-data/helpers';
import { Paged } from '../models';
import { ProfilePicturesError } from '../models/ProfilePicturesError';
import { ProfilePicturesUploadGroup } from '../models/ProfilePicturesUploadGroup';
import { StudentList } from '../models/StudentList';
import { UserStats } from '../models/UserStats';
import { RepresentedUser } from '../navbar/navbar.component';
import {
	addUserToProfiles,
	bulkAddAccounts,
	clearCurrentUpdatedAccount,
	getAccounts,
	getMoreAccounts,
	postAccounts,
	removeAccount,
	sortAccounts,
	updateAccountActivity,
	updateAccountPermissions,
	updateAccountPicture,
} from '../ngrx/accounts/actions/accounts.actions';
import {
	getAddedAdmin,
	getAdminsAccountsEntities,
	getAdminsCollections,
	getAdminSort,
	getCountAdmins,
	getCurrentUpdatedAdmin,
	getLastAddedAdminsAccounts,
	getLoadedAdminsAccounts,
	getLoadingAdminsAccounts,
	getNextRequestAdminsAccounts,
} from '../ngrx/accounts/nested-states/admins/states/admins.getters.state';
import {
	getAllAccountsCollection,
	getAllAccountsEntities,
	getCountAllAccounts,
	getLastAddedAllAccounts,
	getLoadedAllAccounts,
	getLoadingAllAccounts,
	getNextRequestAllAccounts,
} from '../ngrx/accounts/nested-states/all-accounts/states/all-accounts-getters.state';
import { addRepresentedUserAction, removeRepresentedUserAction } from '../ngrx/accounts/nested-states/assistants/actions';
import {
	getAddedAssistant,
	getAssistantsAccountsCollection,
	getAssistantsAccountsEntities,
	getAssistantSort,
	getCountAssistants,
	getCurrentUpdatedAssistant,
	getLastAddedAssistants,
	getLoadedAssistants,
	getLoadingAssistants,
	getNextRequestAssistants,
} from '../ngrx/accounts/nested-states/assistants/states';
import {
	getAddedParent,
	getCountParents,
	getCurrentUpdatedParent,
	getLastAddedParents,
	getLoadedParents,
	getLoadingParents,
	getNextRequestParents,
	getParentsAccountsCollection,
	getParentsAccountsEntities,
	getParentSort,
} from '../ngrx/accounts/nested-states/parents/states';
import { getStudentStats } from '../ngrx/accounts/nested-states/students/actions';
import {
	getAddedStudent,
	getCountStudents,
	getCurrentUpdatedStudent,
	getLastAddedStudents,
	getLoadedStudents,
	getLoadingStudents,
	getNextRequestStudents,
	getStudentsAccountsCollection,
	getStudentsAccountsEntities,
	getStudentSort,
	getStudentsStats,
	getStudentsStatsLoaded,
	getStudentsStatsLoading,
} from '../ngrx/accounts/nested-states/students/states';
import { updateTeacherLocations } from '../ngrx/accounts/nested-states/teachers/actions';
import {
	getAddedTeacher,
	getCountTeachers,
	getCurrentUpdatedTeacher,
	getLastAddedTeachers,
	getLoadedTeachers,
	getLoadingTeachers,
	getNextRequestTeachers,
	getTeacherAccountsCollection,
	getTeachersAccountsEntities,
	getTeacherSort,
} from '../ngrx/accounts/nested-states/teachers/states/teachers-getters.state';
import { AppState } from '../ngrx/app-state/app-state';
import {
	getIntros,
	updateIntros,
	updateIntrosAdminPassLimitsMessage,
	updateIntrosAdminSideBarNux,
	updateIntrosSeenNotificationAlertsSettingsAction,
	updateIntrosShareSmartpass,
	updateIntrosStudentPassLimits,
	updateNewAdminHasSeenGetStarted,
} from '../ngrx/intros/actions';
import { getIntrosData, IntroData, IntroDeviceTypes, IntroType } from '../ngrx/intros/state';
import {
	clearProfilePicturesUploadErrors,
	clearUploadedData,
	createUploadGroup,
	deleteProfilePicture,
	getMissingProfilePictures,
	getProfilePicturesUploadedGroups,
	getUploadedErrors,
	postProfilePictures,
	putUploadErrors,
} from '../ngrx/profile-pictures/actions';
import {
	getCurrentUploadedGroup,
	getLastUploadedGroup,
	getMissingProfiles,
	getProfilePicturesLoaded,
	getProfilePicturesLoaderPercent,
	getProfilePicturesLoading,
	getProfiles,
	getUploadedGroups,
	getUploadErrors,
} from '../ngrx/profile-pictures/states';
import { clearRUsers, getRUsers, updateEffectiveUser } from '../ngrx/represented-users/actions';
import { getEffectiveUser, getEffectiveUserIsAdmin, getRepresentedUsersCollections } from '../ngrx/represented-users/states';
import { getSchoolsFailure } from '../ngrx/schools/actions';
import { getStudentGroups, postStudentGroup, removeStudentGroup, updateStudentGroup } from '../ngrx/student-groups/actions';
import {
	getCurrentStudentGroup,
	getLoadedGroups,
	getLoadingGroups,
	getStudentGroupsCollection,
} from '../ngrx/student-groups/states/groups-getters.state';
import { clearUser, getUser, updateUserAction } from '../ngrx/user/actions';
import { getCurrentUpdatedUser, getLoadedUser, getUserData } from '../ngrx/user/states/user-getters.state';
import { Config, HttpService } from './http-service';
import { Logger } from './logger.service';
import { ParentAccountService } from './parent-account.service';
import { LiveUpdateService } from './live-update.service';
import { StorageKeys, StorageService } from './storage.service';

export type ProfileStatus = 'disabled' | 'suspended' | 'active';

export interface SortNew {
	sort_pass_requests_by_newest: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class UserService implements OnDestroy {
	userData = new BehaviorSubject<User | null>(null);

	/**
	 * Used for acting on behalf of some teacher by his assistant
	 */
	effectiveUser$: Observable<User> = this.store.select(getEffectiveUser);
	representedUsers$: Observable<RepresentedUser[]> = this.store.select(getRepresentedUsersCollections);

	isAdmin$: Observable<boolean> = this.store.select(getEffectiveUserIsAdmin);

	/**
	 * Accounts from store
	 */
	accounts = {
		allAccounts: this.store.select(getAllAccountsCollection),
		adminAccounts: this.store.select(getAdminsCollections),
		teacherAccounts: this.store.select(getTeacherAccountsCollection),
		assistantAccounts: this.store.select(getAssistantsAccountsCollection),
		studentAccounts: this.store.select(getStudentsAccountsCollection),
		parentAccounts: this.store.select(getParentsAccountsCollection),
	};

	countAccounts$ = {
		_all: this.store.select(getCountAllAccounts),
		_profile_admin: this.store.select(getCountAdmins),
		_profile_student: this.store.select(getCountStudents),
		_profile_teacher: this.store.select(getCountTeachers),
		_profile_assistant: this.store.select(getCountAssistants),
		_profile_parent: this.store.select(getCountParents),
	};

	accountsEntities = {
		_all: this.store.select(getAllAccountsEntities),
		_profile_admin: this.store.select(getAdminsAccountsEntities),
		_profile_teacher: this.store.select(getTeachersAccountsEntities),
		_profile_student: this.store.select(getStudentsAccountsEntities),
		_profile_assistant: this.store.select(getAssistantsAccountsEntities),
		_profile_parent: this.store.select(getParentsAccountsEntities),
	};

	isLoadedAccounts$ = {
		all: this.store.select(getLoadedAllAccounts),
		admin: this.store.select(getLoadedAdminsAccounts),
		teacher: this.store.select(getLoadedTeachers),
		student: this.store.select(getLoadedStudents),
		assistant: this.store.select(getLoadedAssistants),
		parent: this.store.select(getLoadedParents),
	};

	isLoadingAccounts$ = {
		all: this.store.select(getLoadingAllAccounts),
		admin: this.store.select(getLoadingAdminsAccounts),
		teacher: this.store.select(getLoadingTeachers),
		student: this.store.select(getLoadingStudents),
		assistant: this.store.select(getLoadingAssistants),
		parent: this.store.select(getLoadingParents),
	};

	lastAddedAccounts$ = {
		_all: this.store.select(getLastAddedAllAccounts),
		_profile_student: this.store.select(getLastAddedStudents),
		_profile_teacher: this.store.select(getLastAddedTeachers),
		_profile_admin: this.store.select(getLastAddedAdminsAccounts),
		_profile_assistant: this.store.select(getLastAddedAssistants),
		_profile_parent: this.store.select(getLastAddedParents),
	};

	nextRequests$ = {
		_all: this.store.select(getNextRequestAllAccounts),
		_profile_student: this.store.select(getNextRequestStudents),
		_profile_teacher: this.store.select(getNextRequestTeachers),
		_profile_admin: this.store.select(getNextRequestAdminsAccounts),
		_profile_assistant: this.store.select(getNextRequestAssistants),
		_profile_parent: this.store.select(getNextRequestParents),
	};

	accountSort$ = {
		_profile_admin: this.store.select(getAdminSort),
		_profile_teacher: this.store.select(getTeacherSort),
		_profile_student: this.store.select(getStudentSort),
		_profile_assistant: this.store.select(getAssistantSort),
		_profile_parent: this.store.select(getParentSort),
	};

	addedAccount$ = {
		_profile_admin: this.store.select(getAddedAdmin),
		_profile_teacher: this.store.select(getAddedTeacher),
		_profile_student: this.store.select(getAddedStudent),
		_profile_assistant: this.store.select(getAddedAssistant),
		_profile_parent: this.store.select(getAddedParent),
	};

	currentUpdatedAccount$ = {
		_profile_admin: this.store.select(getCurrentUpdatedAdmin),
		_profile_teacher: this.store.select(getCurrentUpdatedTeacher),
		_profile_student: this.store.select(getCurrentUpdatedStudent),
		_profile_assistant: this.store.select(getCurrentUpdatedAssistant),
		_profile_parent: this.store.select(getCurrentUpdatedParent),
	};

	/**
	 * Current User
	 */
	userJSON$: Observable<User> = this.store.select(getUserData);
	user$: Observable<User> = this.userJSON$.pipe(
		filter(Boolean),
		map((data) => User.fromJSON(data))
	);
	loadedUser$: Observable<boolean> = this.store.select(getLoadedUser);
	currentUpdatedUser$: Observable<User> = this.store.select(getCurrentUpdatedUser).pipe(
		map((u) => {
			try {
				return User.fromJSON(u);
			} catch {
				return u;
			}
		})
	);

	/**
	 * Student Groups
	 */
	studentGroups$: Observable<StudentList[]> = this.store.select(getStudentGroupsCollection);
	currentStudentGroup$: Observable<StudentList | undefined> = this.store.select(getCurrentStudentGroup);
	isLoadingStudentGroups$: Observable<boolean> = this.store.select(getLoadingGroups);
	isLoadedStudentGroups$: Observable<boolean> = this.store.select(getLoadedGroups);
	blockUserPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	/**
	 * Profile Pictures
	 */
	profilePicturesLoading$: Observable<boolean> = this.store.select(getProfilePicturesLoading);
	profilePicturesLoaded$: Observable<boolean> = this.store.select(getProfilePicturesLoaded);
	profiles$: Observable<(User | Error)[]> = this.store.select(getProfiles);
	profilePictureLoaderPercent$: Observable<number> = this.store.select(getProfilePicturesLoaderPercent);
	profilePicturesErrors$: Subject<{ [id: string]: string; error: string }> = new Subject();

	// cancel operation
	profilePicturesErrorCancel$: Subject<{ error: string }> = new Subject();

	uploadedGroups$: Observable<ProfilePicturesUploadGroup[]> = this.store.select(getUploadedGroups);
	currentUploadedGroup$: Observable<ProfilePicturesUploadGroup> = this.store.select(getCurrentUploadedGroup);
	lastUploadedGroup$: Observable<ProfilePicturesUploadGroup> = this.store.select(getLastUploadedGroup);
	missingProfilePictures$: Observable<User[]> = this.store.select(getMissingProfiles);
	profilePicturesUploadErrors$: Observable<ProfilePicturesError[]> = this.store.select(getUploadErrors);

	/**
	 * Students Stats
	 */
	studentsStats$: Observable<{ [id: string]: UserStats }> = this.store.select(getStudentsStats);
	studentsStatsLoading$: Observable<boolean> = this.store.select(getStudentsStatsLoading);
	studentsStatsLoaded$: Observable<boolean> = this.store.select(getStudentsStatsLoaded);

	introsData$: Observable<IntroData> = this.store.select(getIntrosData);

	isEnableProfilePictures$: Observable<boolean>;

	schools$: Observable<School[]>;

	destroy$ = new Subject<void>();

	/**
	 * destroyGlobalReload is responsible for destroying HttpService.globalReload$ subscription.
	 * We destroy this when a parent account has logged in because globalReload$ is only triggered when
	 * a school has loaded.
	 * Therefore, when a parent account has logged in, a school will never load and the globalReload$ Subject
	 * will hang forever. This is why we destroy the observable
	 */
	destroyGlobalReload = new Subject<void>();

	/**
	 * inhibitParentRequest$ is responsible for not calling `/parent/@me when a user that's not a parent account
	 * has logged in.
	 * The BehaviorSubject has a value of true, which means we hold off on calling the parent user info route.
	 * Only when this value receives false, then we call `/parent/@me`.
	 */
	inhibitParentRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	showExpiredPasses = new BehaviorSubject<boolean>(true);

	constructor(
		private http: HttpService,
		private httpClient: HttpClient,
		private liveUpdateService: LiveUpdateService,
		private _logging: Logger,
		errorHandler: ErrorHandler,
		private store: Store<AppState>,
		private storageService: StorageService,
		parentAccountService: ParentAccountService
	) {
		/**
		 * /v1/schools is always called no matter the type of user that signs in.
		 * As long as the user is authenticated, /v1/schools is called.
		 *
		 * `getLoadedSchools` emits the NgRx state of the school request. It's true when the request
		 * succeeds, and is false otherwise. Whenever it's true, `http.schoolsCollection$` is the
		 * list of schools from the API request (as opposed to the default initial value).
		 */
		this.store
			.select(getLoadedSchools)
			.pipe(
				pickTruthy(),
				concatMap((_) => this.http.schoolsCollection$.pipe(take(1)))
			)
			.subscribe({
				next: (schools) => {
					if (schools.length === 0) {
						// logged in user has no associated schools, it must be a parent account.
						// destroy the global reload observable and allow the parent request to continue
						this.destroyGlobalReload.next();
						this.inhibitParentRequest$.next(false);
					} else {
						// logged in user has associated schools, do not call `/parent/@me`
						this.inhibitParentRequest$.next(true);
					}
				},
			});

		/**
		 * Inhibit requests emits an initial value of true, then emits whenever `http.schoolsLoaded$`
		 * emits.
		 */
		this.inhibitParentRequest$
			.pipe(
				filter((inhibitParents) => {
					return !inhibitParents;
				}),
				concatMap(() => parentAccountService.getParentInfo()),
				map((account) => {
					if (isStringIndexable(account)) {
						account['sync_types'] = [];
					}
					return User.fromJSON(account);
				})
			)
			.subscribe({
				next: (parentAccount) => {
					this.http.effectiveUserId.next(parentAccount.id);
					this.userData.next(parentAccount);
				},
			});

		this.schools$ = this.http.schools$;
		this.http.globalReload$
			.pipe(
				takeUntil(this.destroyGlobalReload),
				tap(() => {
					this.getUserRequest();
				}),
				exhaustMap(() => {
					return this.userJSON$.pipe(
						filter(Boolean),
						take(1),
						map((raw) => User.fromJSON(raw))
					);
				}),
				tap((user) => {
					this.showExpiredPasses.next(user.show_expired_passes);
					if (user.isAssistant()) {
						this.getUserRepresentedRequest();
					} else {
						this.updateEffectiveUser(user);
					}
				}),
				switchMap((user: User) => {
					this.blockUserPage$.next(false);
					if (user.isAssistant() && !window.location.href.includes('/kioskMode')) {
						return combineLatest([this.representedUsers$.pipe(filter((res) => !!res)), this.http.schoolsCollection$]).pipe(
							tap(([users, schools]) => {
								if (!users.length && schools.length === 1) {
									this.store.dispatch(getSchoolsFailure({ errorMessage: `Assistant doesn't have teachers` }));
								} else if (!users.length && schools.length > 1) {
									this.blockUserPage$.next(true);
								}
							}),
							filter(([users, schools]) => !!users.length || schools.length > 1),
							map(([users]) => {
								const chosen = this.chooseEffectiveUser(users);
								this.store.dispatch(updateEffectiveUser({ effectiveUser: chosen }));
								this.http.effectiveUserId.next(chosen.id);
								return chosen;
							})
						);
					} else {
						return of(user);
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((user) => {
				this.userData.next(user);
			});

		this.isEnableProfilePictures$ = merge(this.http.currentSchool$, this.getCurrentUpdatedSchool$().pipe(filter((s) => !!s))).pipe(
			filter((s) => !!s),
			map((school) => school.profile_pictures_enabled)
		);

		if (errorHandler instanceof SentryErrorHandler) {
			combineLatest([this.userData, this.http.currentSchool$])
				.pipe(takeUntil(this.destroy$))
				.subscribe(([user, school]) => {
					if (!user && !school) {
						return;
					}

					const ctx: UserContext = {};

					if (user) {
						ctx.id = `${user.id}`;
						ctx.email = user.primary_email;
						ctx.is_student = user.isStudent();
						ctx.is_teacher = user.isTeacher();
						ctx.is_admin = user.isAdmin();
						ctx.is_assistant = user.isAssistant();
						ctx.is_kiosk = user.isKiosk();
						ctx.is_parent = user.isParent();
					}

					if (school) {
						ctx.school_id = `${school.id}`;
						ctx.school_name = school.name;
					}

					errorHandler.setUserContext(ctx);
				});
		}

		this.liveUpdateService.listen().pipe(takeUntil(this.destroy$)).subscribe(this._logging.debug);
	}

	chooseEffectiveUser(representedUsers: RepresentedUser[]): User {
		const selectedEffectiveUserId = this.storageService.getItem(StorageKeys.selectedEffectiveUserId);
		let found: RepresentedUser | undefined;
		if (selectedEffectiveUserId) {
			found = representedUsers.find((r) => r.user.id == +selectedEffectiveUserId);
		}
		if (found) {
			return found.user;
		}
		const defaultUser = representedUsers[0].user;
		this.storageService.setItem(StorageKeys.selectedEffectiveUserId, defaultUser.id);
		return defaultUser;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	registerThirdPartyPlugins(user: User) {
		const school: School = this.http.getSchool();
		if (school) {
			this.handleThirdPartyPlugIns(user, school);
		} else {
			this.http.schools$
				.pipe(
					filter((schools) => !!schools),
					tap(() => {
						const school: School = this.http.getSchool();
						this.handleThirdPartyPlugIns(user, school);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe();
		}
	}

	private handleThirdPartyPlugIns(user: User, school: School): void {
		console.log('registering third party plugins');
		const now = new Date();
		let trialEndDate: Date | undefined;
		if (school.trial_end_date) {
			const d = new Date(school.trial_end_date);
			// Drop the time so that the date is the same when we call .toDateString()
			trialEndDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
		}
		const accountType = user.sync_types[0] === 'google' ? 'Google' : user.sync_types[0] === 'clever' ? 'Clever' : 'Standard';
		const trialing = !!trialEndDate && trialEndDate > now;
		const trialEndDateStr = trialEndDate ? trialEndDate.toDateString() : 'N/A';

		this.getSchoolInfo(school.id).subscribe((schoolInfoResponse: any) => {
			const company = {
				id: school.id,
				name: school.name,
				'Id Card Access': school.feature_flag_digital_id,
				'Plus Access': school.feature_flag_encounter_detection,
				Trialing: trialing,
				'Trial End Date': trialEndDateStr,
				'Data Concierge Access': school.dc_access === 'yes' ? true : false,
			};

			window['Intercom']('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: 'pieiphf4',
			});

			window['intercomSettings'] = {
				user_id: user.id,
				name: user.display_name,
				email: user.primary_email,
				created: new Date(user.created),
				type: this.getUserType(user),
				status: user.status,
				account_type: accountType,
				first_login_at: user.first_login,
				company: company,
				hide_default_launcher: true,
				custom_launcher_selector: '.open-intercom-btn',
				customer_tier: schoolInfoResponse?.district_customer_tier,
			};
			window['Intercom']('update', { hideDefaultLauncher: true });
		});

		window['posthog'].identify(user.id, {
			name: user.display_name,
			email: user.primary_email,
			created: new Date(user.created),
			type: this.getUserType(user),
			status: user.status,
			account_type: accountType,
			first_login_at: user.first_login,
			school_id: school.id,
			school_name: school.name,
			id_card_access: school.feature_flag_digital_id,
			encounter_detection_access: school.feature_flag_encounter_detection,
			trialing: trialing,
			trial_end_date: trialEndDateStr,
		});
	}

	private getUserType(user: User): string {
		if (user.isAdmin()) return 'Admin';
		if (user.isTeacher()) return 'Teacher';
		if (user.isAssistant()) return 'Assistant';
		if (user.isStudent()) return 'Student';
		return 'unknown user';
	}

	private getAccountsRole(role: string): Observable<User[]> {
		switch (role) {
			case '':
			case '_all':
				return this.accounts.allAccounts;
			case ROLES.Admin:
				return this.accounts.adminAccounts;
			case ROLES.Teacher:
				return this.accounts.teacherAccounts;
			case ROLES.Student:
				return this.accounts.studentAccounts;
			case ROLES.Assistant:
				return this.accounts.assistantAccounts;
			case ROLES.Parent:
				return this.accounts.parentAccounts;
			case ROLES.OverriderEncounter:
				return this.accounts.teacherAccounts.pipe(map((users) => users.filter((u) => u.roles.includes(ROLES.OverriderEncounter))));
			default:
				return of([]);
		}
	}

	getUserRequest() {
		this.store.dispatch(getUser());
	}

	getUserSchool(): School {
		return this.http.getSchool();
	}

	getFeatureFlagDigitalID(): boolean {
		return this.getUserSchool().feature_flag_digital_id;
	}

	getFeatureEncounterDetection(): boolean {
		return this.getUserSchool().feature_flag_encounter_detection;
	}

	getFeatureFlagParentAccount(): boolean {
		return this.getUserSchool().feature_flag_parent_accounts;
	}

	getFeatureFlagNewAbbreviation(): boolean {
		return this.getUserSchool().feature_flag_new_abbreviation;
	}

	getFeatureFlagAlertSounds(): boolean {
		return this.getUserSchool().feature_flag_alert_sounds;
	}

	getFeatureFlagReferralProgram(): boolean {
		return this.getUserSchool().feature_flag_referral_program;
	}

	getCurrentUpdatedSchool$(): Observable<School> {
		return this.http.currentUpdateSchool$;
	}

	clearUser() {
		this.store.dispatch(clearUser());
	}

	getUser() {
		return this.http.get<User>('v1/users/@me');
	}

	getUserById(userId: string | number, httpConfig?: Config): Observable<User> {
		return this.http.get<User>(`v1/users/${userId}`, httpConfig);
	}

	getUserPin(): Observable<string | number> {
		return this.http.get<{ pin: string | number }>('v1/users/@me/pin_info').pipe(map(({ pin }) => pin));
	}

	updateUserRequest(user: User, data) {
		this.store.dispatch(updateUserAction({ user, data }));
		return this.currentUpdatedUser$;
	}

	updateUser(userId: number, data) {
		return this.http.patch(`v1/users/${userId}`, data);
	}

	getIntrosRequest() {
		this.store.dispatch(getIntros());
	}

	getIntros() {
		return this.http.get('v1/intros');
	}

	updateIntrosRequest(intros: IntroData, device: IntroDeviceTypes, version: string) {
		this.store.dispatch(updateIntros({ intros, device, version }));
	}

	updateIntrosShareSmartpassRequest(intros: IntroData, device: IntroDeviceTypes, version: string) {
		this.store.dispatch(updateIntrosShareSmartpass({ intros, device, version }));
	}

	updateIntrosStudentPassLimitRequest(intros: IntroData, device: IntroDeviceTypes, version: string) {
		this.store.dispatch(updateIntrosStudentPassLimits({ intros, device, version }));
	}

	updateNewAdminHasSeenGetStartedRequest(intros: IntroData, device: IntroDeviceTypes, version: string) {
		this.store.dispatch(updateNewAdminHasSeenGetStarted({ intros, device, version }));
	}

	updateIntrosAdminPassLimitsMessageRequest(intros: IntroData, device: IntroDeviceTypes, version: string) {
		this.store.dispatch(updateIntrosAdminPassLimitsMessage({ intros, device, version }));
	}

	updateIntrosSeenNotificationAlertsSettingsRequest(intros: IntroData, device: IntroDeviceTypes, version: string) {
		this.store.dispatch(updateIntrosSeenNotificationAlertsSettingsAction({ intros, device, version }));
	}

	updateIntrosAdminSideBarNuxRequest(intros: IntroData, device: IntroDeviceTypes, version: string) {
		this.store.dispatch(updateIntrosAdminSideBarNux({ intros, device, version }));
	}

	updateIntros(intro: IntroType, device: IntroDeviceTypes, version: string) {
		return this.http.patch(`v1/intros/${intro}`, { device, version });
	}

	getUserRepresentedRequest() {
		this.store.dispatch(getRUsers());
	}

	getUserRepresented() {
		return this.http.get<RepresentedUser[]>('v1/users/@me/represented_users');
	}

	sendTestNotification(id: number) {
		return this.http.post(`v1/users/${id}/test_notification`, new Date());
	}

	searchProfile(
		role: ROLES,
		limit = 5,
		search = '',
		ignoreProfileWithStatuses: ProfileStatus[] = ['suspended'],
		includeClasses = false,
		atTimeStamp = ''
	) {
		let url = 'v1/users?';
		if (role) {
			url += `role=${encodeURIComponent(role)}&`;
		}
		if (limit) {
			url += `limit=${encodeURIComponent(limit)}&`;
		}
		if (search) {
			url += `search=${encodeURIComponent(search)}&`;
		}
		if (includeClasses) {
			url += `include_classes=${encodeURIComponent(includeClasses)}&`;
		}
		if (atTimeStamp) {
			url += `at_timestamp=${encodeURIComponent(atTimeStamp)}&`;
		}

		for (const hideStatus of ignoreProfileWithStatuses) {
			url += `hideStatus=${encodeURIComponent(hideStatus)}&`;
		}

		if (url[url.length - 1] === '&') {
			url = url.slice(0, -1);
		}

		return this.http.get<Paged<any>>(url);
	}

	possibleProfileById(id: string): Observable<User | null> {
		return this.http.get<User>(`v1/users/${id}`, { headers: { 'X-Ignore-Errors': 'true' } }).pipe(catchError(() => of(null)));
	}

	searchProfileAll(search, type = 'alternative', excludeProfile?: string, gSuiteRoles?: string[]) {
		switch (type) {
			case 'alternative':
				return this.http.get(constructUrl(`v1/users`, { search: search }));
			case 'G Suite':
				if (gSuiteRoles) {
					return this.http.get(
						constructUrl(`v1/schools/${this.http.getSchool().id}/gsuite_users`, {
							search: search,
							profile: gSuiteRoles,
						})
					);
				} else {
					return this.http.get(
						constructUrl(`v1/schools/${this.http.getSchool().id}/gsuite_users`, {
							search: search,
						})
					);
				}
			case 'GG4L':
				if (excludeProfile) {
					return this.http.get(
						constructUrl(`v1/schools/${this.http.getSchool().id}/gg4l_users`, {
							search: search,
							profile: excludeProfile,
						})
					);
				} else {
					return this.http.get(
						constructUrl(`v1/schools/${this.http.getSchool().id}/gg4l_users`, {
							search,
						})
					);
				}
		}
	}

	setUserActivityRequest(profile, active: boolean, role: string) {
		this.store.dispatch(updateAccountActivity({ profile, active, role }));
		return of(null);
	}

	setUserActivity(id, activity: boolean) {
		return this.http.patch(`v1/users/${id}/active`, { active: activity });
	}

	addAccountRequest(school_id, user, userType, roles: string[], role, behalf?: User[]) {
		this.store.dispatch(postAccounts({ school_id, user, userType, roles, role, behalf }));
		return of(null);
	}

	addAccountToSchool(id, user, userType: string, roles: Array<string>) {
		if (userType === 'gsuite') {
			return this.http.post(`v1/schools/${id}/add_user`, {
				type: 'gsuite',
				email: user.email,
				profiles: roles,
			});
		} else if (userType === 'email') {
			return this.http.post(`v1/schools/${id}/add_user`, {
				type: 'email',
				email: user.email,
				password: user.password,
				first_name: user.first_name,
				last_name: user.last_name,
				display_name: user.display_name,
				profiles: roles,
			});
		} else if (userType === 'username') {
			return this.http.post(`v1/schools/${id}/add_user`, {
				type: 'username',
				username: user.email,
				password: user.password,
				first_name: user.first_name,
				last_name: user.last_name,
				display_name: user.display_name,
				profiles: roles,
			});
		}
	}

	addUserToProfilesRequest(user: User, roles: string[]) {
		this.store.dispatch(addUserToProfiles({ user, roles }));
	}

	addUserToProfiles(id: string | number, roles: string[]): Observable<User> {
		return this.http.patch(`v1/users/${id}/profiles`, { profiles: roles });
	}

	addUserToProfile(id, role) {
		return this.http.put(`v1/users/${id}/profiles/${role}`);
	}

	createUserRolesRequest(profile: User, permissions, role: string) {
		this.store.dispatch(updateAccountPermissions({ profile, permissions, role }));
		return of(null);
	}

	createUserRoles(id, data) {
		return this.http.patch(`v1/users/${id}/roles`, data);
	}

	deleteUserRequest(user: User, role: string) {
		this.store.dispatch(removeAccount({ user, role }));
		return of(null);
	}

	deleteUser(id) {
		return this.http.delete(`v1/users/${id}`);
	}

	deleteUserFromProfile(id, role) {
		return this.http.delete(`v1/users/${id}/profiles/${role}`);
	}

	getRepresentedUsers(id) {
		return this.http.get(`v1/users/${id}/represented_users`);
	}

	addRepresentedUserRequest(profile, user: User) {
		this.store.dispatch(addRepresentedUserAction({ profile, user }));
		return of(null);
	}

	addRepresentedUser(id: number, repr_user: User) {
		return this.http.put(`v1/users/${id}/represented_users/${repr_user.id}`);
	}

	deleteRepresentedUserRequest(profile, user: User) {
		this.store.dispatch(removeRepresentedUserAction({ profile, user }));
		return of(null);
	}

	deleteRepresentedUser(id: number, repr_user: User) {
		return this.http.delete(`v1/users/${id}/represented_users/${repr_user.id}`);
	}

	getStudentGroupsHTTP() {
		this.store.dispatch(getStudentGroups());
		return this.studentGroups$;
	}

	getStudentGroups() {
		return this.http.get('v1/student_lists');
	}

	createStudentGroupRequest(group) {
		this.store.dispatch(postStudentGroup({ group }));
		return this.currentStudentGroup$;
	}

	createStudentGroup(data) {
		return this.http.post('v1/student_lists', data);
	}

	updateStudentGroupRequest(id, group) {
		this.store.dispatch(updateStudentGroup({ id, group }));
		return this.currentStudentGroup$.pipe(filter((sg) => !!sg));
	}

	updateStudentGroup(id, body) {
		return this.http.patch(`v1/student_lists/${id}`, body);
	}

	applyForReferral() {
		return this.http.post('v2/user/referral/apply', {}, undefined, false);
	}

	deleteStudentGroupRequest(id) {
		this.store.dispatch(removeStudentGroup({ id }));
		return this.currentStudentGroup$;
	}

	deleteStudentGroup(id) {
		return this.http.delete(`v1/student_lists/${id}`);
	}

	getUserWithTimeout(max = 10000): Observable<User | null> {
		return race<User | null>(this.userData, interval(max).pipe(map(() => null))).pipe(take(1));
	}

	getAccountsRoles(role = '', search = '', limit = 0): Observable<User[]> {
		if (role === ROLES.OverriderEncounter) {
			this.store.dispatch(getAccounts({ role: ROLES.Teacher, search, limit }));
		} else {
			this.store.dispatch(getAccounts({ role, search, limit }));
		}
		return this.getAccountsRole(role);
	}

	// warning, this function will be much slower than using searchProfile()
	getUsersList(role = '', search = '', limit = 0, include_numbers?: boolean) {
		const params: any = {};
		if (role !== '' && role !== '_all') {
			params.role = role;
		}

		if (search !== '') {
			params.search = search;
		}
		if (limit) {
			params.limit = limit;
		}
		if (include_numbers) {
			params.include_numbers = true;
		}

		return this.http.get<any>(constructUrl('v1/users', params));
	}

	getMoreUserListRequest(role: string): Observable<User[]> {
		this.store.dispatch(getMoreAccounts({ role }));
		return this.lastAddedAccounts$[role];
	}

	exportUserData(id) {
		return this.http.get(`v1/users/${id}/export_data`);
	}

	checkUserEmail(email) {
		return this.http.post('v1/check-email', { email });
	}

	addBulkAccountsRequest(accounts) {
		this.store.dispatch(bulkAddAccounts({ accounts }));
		return of(null);
	}

	addBulkAccounts(accounts) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
		};
		return this.http.post('v1/users/bulk-add?should_commit=true', accounts, httpOptions, false);
	}

	sortTableHeaderRequest(role, queryParams) {
		this.store.dispatch(sortAccounts({ role, queryParams }));
	}

	sortTableHeader(queryParams) {
		return this.http.get(constructUrl('v1/users', queryParams));
	}

	updateEffectiveUser(effectiveUser) {
		this.store.dispatch(updateEffectiveUser({ effectiveUser }));
	}

	clearRepresentedUsers() {
		this.store.dispatch(clearRUsers());
	}

	createUploadGroup() {
		return this.http.post(`v1/file_upload_groups`);
	}

	postProfilePicturesRequest(userIds: string[] | number[], pictures: File[]) {
		this.store.dispatch(postProfilePictures({ pictures, userIds }));
		return this.profiles$;
	}

	uploadProfilePictures(image_files, user_ids, group_id?) {
		const data = group_id ? { image_files, user_ids, group_id, commit: true } : { image_files, user_ids, commit: true };
		return this.http.post(`v1/schools/${this.http.getSchool().id}/attach_profile_pictures`, data);
	}

	bulkAddProfilePictures(files: File[]) {
		const file_names = files.map((file) => file.name);
		const content_types = files.map((file) => (file.type ? file.type : 'image/jpeg'));
		return this.http.post('v1/file_uploads/bulk_create_url', { file_names, content_types });
	}

	setProfilePictureToGoogle(url: string, file: File, content_type: string) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': content_type,
			}),
		};
		return this.httpClient.put(url, file, httpOptions);
	}

	addProfilePictureRequest(profile: User, role: string, file: File) {
		this.store.dispatch(updateAccountPicture({ profile, role, file }));
	}

	addProfilePicture(userId, file: File) {
		return this.http.patch(`v1/users/${userId}/profile-picture`, { profile_picture: file });
	}

	updateTeacherLocations(teacher, locations, newLocations) {
		this.store.dispatch(updateTeacherLocations({ teacher, locations, newLocations }));
	}

	putProfilePicturesErrorsRequest(errors) {
		this.store.dispatch(putUploadErrors({ errors }));
	}

	putProfilePicturesErrors(uploadedGroupId: number, levels: string[], messages: string[]) {
		return this.http.put(`v1/file_upload_groups/${uploadedGroupId}/events`, { levels, messages });
	}

	getUploadedGroupsRequest() {
		this.store.dispatch(getProfilePicturesUploadedGroups());
	}

	createPPicturesUploadGroup() {
		this.store.dispatch(createUploadGroup());
		return this.currentUploadedGroup$;
	}

	getUploadedGroups() {
		return this.http.get(`v1/file_upload_groups`);
	}

	getMissingProfilePicturesRequest() {
		this.store.dispatch(getMissingProfilePictures());
	}

	getMissingProfilePictures() {
		return this.http.get(`v1/users?role=_profile_student&has_picture=false`);
	}

	getUploadedErrorsRequest(group_id: string | number) {
		this.store.dispatch(getUploadedErrors({ group_id }));
		return this.profilePicturesUploadErrors$;
	}

	getUploadedErrors(group_id: string | number) {
		return this.http.get(`v1/file_upload_groups/${group_id}/events`);
	}

	clearProfilePicturesErrors() {
		this.store.dispatch(clearProfilePicturesUploadErrors());
	}

	clearCurrentUpdatedAccounts() {
		this.store.dispatch(clearCurrentUpdatedAccount());
	}

	deleteProfilePicture(user: User, role: string) {
		this.store.dispatch(deleteProfilePicture({ user, role }));
		return this.currentUpdatedAccount$[role];
	}

	clearUploadedData() {
		this.store.dispatch(clearUploadedData());
	}

	getUserStatsRequest(userId: number, queryParams?: { created_after: string; end_time_before: string }) {
		return this.store.dispatch(getStudentStats({ userId, queryParams }));
	}

	getUserStats(userId: string | number, queryParams: Partial<QueryParams>) {
		return this.http.get(constructUrl(`v1/users/${userId}/stats`, queryParams));
	}

	getStatusOfIDNumber() {
		return this.http.get<{ results: { setup: boolean } }>(`v1/integrations/upload/custom_ids/setup`);
	}

	uploadIDNumbers(body: { csv_file: File }) {
		return this.http.post('v1/integrations/upload/custom_ids', body);
	}

	getMissingIDNumbers() {
		return this.http.get(`v1/users?has_custom_id=false`);
	}

	getStatusOfGradeLevel() {
		return this.http.get(`v1/integrations/upload/grade_levels/setup`);
	}

	uploadGradeLevels(body: { csv_file: File }) {
		return this.http.post('v1/integrations/upload/grade_levels', body);
	}

	getMissingGradeLevels() {
		return this.http.get(`v1/users?role=_profile_student&has_grade_level=false`);
	}

	possibleProfileByCustomId(
		id: string,
		ignoreProfileWithStatuses: ProfileStatus[] = ['suspended']
	): Observable<{ results: { user: Array<User | null> } }> {
		let url = `v1/users/custom_id/${id}?`;

		for (const hideStatus of ignoreProfileWithStatuses) {
			url += `hideStatus=${hideStatus}&`;
		}
		if (url[url.length - 1] === '&') {
			url = url.slice(0, -1);
		}

		return this.http.get(url, { headers: { 'X-Ignore-Errors': 'true' } }).pipe(catchError(() => of(null)));
	}

	getGradeLevelsByIds(ids: string[]) {
		const q = ids.map((x) => x.trim()).join(',');
		const opt = q ? { params: new HttpParams().set('student_id', q) } : {};
		return this.http.get('v1/users/grade_level', opt);
	}

	listOf(params: { email: string[] }) {
		const headers = {
			'Content-Type': 'application/json',
		};
		return this.http.post('v1/users/listof', { params }, { headers }, false);
	}

	getSortPreference(userId: string): Observable<SortNew> {
		return this.http.get<SortNew>(`v1/users/${userId}/sort-preference`);
	}

	setSortPreference(userId: string, value: boolean): Observable<void> {
		return this.http.put<void>(`v1/users/${userId}/sort-preference`, { sort_pass_requests_by_newest: value });
	}

	getSchoolInfo(schoolId: number) {
		return this.http.post<object>('v2/school/info', schoolId, undefined, false);
	}
}
