import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import { AppState } from '../../app-state/app-state';
import { schoolActivityInstanceAdapter, SchoolActivityInstancesState } from '../reducers/school-activities-instances.reducer';

export const getActivityInstancesState = (state: AppState) => state.schoolActivityInstances;
export const getSchoolActivityInstanceById = (id: number) => (state: AppState) => state.schoolActivityInstances.entities[id];

export const getPastSchoolActivityInstances = (date: Date) => (state: AppState) =>
	Object.values(state.schoolActivityInstances.entities).filter((instance) => instance && new Date(instance.start_time) < date);

export const getUpcomingSchoolActivityInstances = (date: Date) => (state: AppState) =>
	Object.values(state.schoolActivityInstances.entities).filter((instance) => instance && new Date(instance.start_time) >= date);
export const getSchoolActivityInstancesForDate = (date: Date) => (state: AppState) =>
	Object.values(state.schoolActivityInstances.entities).filter((instance) => {
		return instance && moment(instance.start_time).isSame(moment(date), 'day');
	});

export const selectSchoolActivitiesState = createFeatureSelector<SchoolActivityInstancesState>('schoolActivityInstances');

export const getActivityInstancesCollection = schoolActivityInstanceAdapter.getSelectors(getActivityInstancesState).selectAll;

export const getSelectedActivityInstanceId = createSelector(selectSchoolActivitiesState, (state) => state.selectedActivityInstanceId);

export const getSelectedActivityInstance = createSelector(selectSchoolActivitiesState, (state) => state.entities[state.selectedActivityInstanceId!]);
