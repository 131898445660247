import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { captureException } from '@sentry/angular';
import { filter as _filter } from 'lodash';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { BehaviorSubject, combineLatest, fromEvent, iif, merge, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	distinctUntilChanged,
	distinctUntilKeyChanged,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { APPLY_ANIMATED_CONTAINER, ConsentMenuOverlay } from './consent-menu-overlay';
import { DarkThemeSwitch } from './dark-theme-switch';
import { DeviceDetection } from './device-detection.helper';
import { HallPass, MonetizationInfo, School, User } from './models';
import { ToastObj } from './ngrx/toast/states';
import { PassCardComponent } from './pass-card/pass-card.component';
import { deregisterRefiner, registerRefiner } from './refiner';
import { BootstrapService } from './services/bootstrap.service';
import { ENTITLEMENTS, EntitlementsService } from './services/entitlements.service';
import { FeatureFlagService, FLAGS } from './services/feature-flag.service';
import { HallPassesService, MODAL_MAX_HEIGHT } from './services/hall-passes.service';
import { HelpCenterService } from './services/help-center.service';
import { HttpService } from './services/http-service';
import { KeyboardShortcutsService } from './services/keyboard-shortcuts.service';
import { MonetizationService } from './services/monetization.service';
import { NoFlyTimeService } from './services/no-fly-time.service';
import { NotificationService } from './services/notification-service';
import { OldLoginService } from './services/old-login.service';
import { ParentAccountService } from './services/parent-account.service';
import { LiveUpdateService } from './services/live-update.service';
import { ScheduleService } from './services/schedule.service';
import { ScreenService } from './services/screen.service';
import { StorageService } from './services/storage.service';
import { TimeService } from './services/time.service';
import { ToastIdEnum, ToastService } from './services/toast.service';
import { UserService } from './services/user.service';
import { CallDialogComponent } from './shared/shared-components/call-dialog/call-dialog.component';

export const INITIAL_LOCATION_PATHNAME = new ReplaySubject<string>(1);

export interface SentryList {
	schoolId: number;
	urlMatch: string;
}

const urlBlockList = ['/forms', '/kioskMode'];
export const SCHOOL_TOGGLEBAR_HEIGHT = 50;
export const TRIAL_BAR_HEIGHT = 76;

/**
 * @title Autocomplete overview
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
	shortcuts: ShortcutInput[] = [];
	currentRoute = '';

	helpCentreURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.smartpass.app/help-center');

	private dialogContainer: HTMLElement | null = null;

	@ViewChild('dialogContainer', { static: true }) set content(content: ElementRef) {
		if (content) {
			this.dialogContainer = content.nativeElement;
		}
	}

	@ViewChild('helpCenterDiv') helpCenterDiv!: ElementRef;

	monetizationInfo: MonetizationInfo | null = null;
	displayTrialBanner = false;
	isAuthenticated: boolean | null = null;
	isAdmin = false;
	isTeacher = false;
	isStudent = true;
	isAssistant = false;
	hideScroll = true;
	hideSchoolToggleBar = false;
	showUISubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showUI: Observable<boolean> = this.showUISubject.asObservable();
	schools: School[] = [];
	darkThemeEnabled = false;
	isKioskMode: boolean;
	toasts$: Observable<ToastObj[]>;
	hasCustomBackdrop: boolean;
	customStyle: Record<string, string>;
	hasCustomBackdrop$: Observable<boolean>;
	user$: Observable<User>;
	user: User;
	private effectiveUser$: Observable<User>;
	private intercomObserver: MutationObserver;

	private subscriber$ = new Subject();

	mainContentWidth = '100%';
	mainContentMarginTop = '0px';

	hasPhoneSupport$: Observable<boolean>;
	hasPhoneSupport: boolean;
	teacherHomeScreen = false;
	isAdminPage: boolean;

	@HostListener('window:popstate', ['$event'])
	back() {
		if (DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile()) {
			window.history.pushState({}, '');
		}
	}

	@HostListener('document:scroll', ['$event'])
	scroll(event: Event): void {
		// adjust the height of the help center wrapping div if window is scrolled.
		if (this.helpCenterDiv === null || document.scrollingElement === null) {
			return;
		}
		if (this.helpCenterDiv && this.helpCenterDiv.nativeElement.offsetHeight < document.scrollingElement.getClientRects()[0].height) {
			this.renderer.setStyle(
				this.helpCenterDiv.nativeElement,
				'height',
				`${this.helpCenterDiv.nativeElement.offsetHeight + document.scrollingElement.scrollTop}px`
			);
		}
	}

	@HostListener('window:beforeunload') onBeforeUnload() {
		this.subscriber$.next(null);
		this.subscriber$.complete();
	}

	@HostListener('window:unload') onunload() {
		document.documentElement.innerHTML = '';
	}

	constructor(
		private bootstrapService: BootstrapService,
		public monetizationService: MonetizationService,
		public darkTheme: DarkThemeSwitch,
		private oldLoginService: OldLoginService,
		private userService: UserService,
		private http: HttpService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog,
		private overlayContainer: OverlayContainer,
		private storageService: StorageService,
		private notifService: NotificationService,
		private shortcutsService: KeyboardShortcutsService,
		private screen: ScreenService,
		private toastService: ToastService,
		private hallPassesService: HallPassesService,
		public helpCenter: HelpCenterService,
		private sanitizer: DomSanitizer,
		public featureFlags: FeatureFlagService,
		private titleService: Title,
		private renderer: Renderer2,
		private parentService: ParentAccountService,
		private liveUpdateService: LiveUpdateService,
		private cdr: ChangeDetectorRef,
		private noFlyTimeService: NoFlyTimeService,
		private scheduleService: ScheduleService,
		private entitlementsService: EntitlementsService
	) {}

	get isMobile(): boolean {
		return DeviceDetection.isMobile();
	}

	private loadScheduleData(user: User): Observable<void> {
		if (this.featureFlags.isFeatureEnabledV2(FLAGS.Schedules)) {
			return this.scheduleService.schedulesList$.pipe(
				switchMap((schedules) => {
					return iif(
						() => schedules.length > 0,
						iif(() => user.isStaff(), this.bootstrapService.loadDataForStaff(user.id), this.bootstrapService.loadDataForStudent(user.id)),
						of(null)
					);
				})
			);
		}
	}

	ngOnInit(): void {
		this.toasts$ = this.toastService.toasts$;
		this.user$ = this.userService.userJSON$.pipe(map((user) => User.fromJSON(user)));
		this.effectiveUser$ = this.userService.effectiveUser$.pipe(
			filter((u) => !!u),
			tap((u) => {
				this.user = User.fromJSON(u);
			})
		);

		this.effectiveUser$
			.pipe(
				switchMap((user) => {
					return this.loadScheduleData(user).pipe(map((_) => user));
				}),
				// Sentry logging to determine user's device time being incorrect
				switchMap((user) => {
					return TimeService.latestDriftEstimate$.pipe(map((time) => [user, time] as const));
				}),
				filter(([_u, time]) => !!time && (time > 60000 || time < -60000)),
				take(1),
				tap(([user, time]) => {
					captureException(new Error('Clock Drift Logging'), {
						level: 'warning',
						tags: {
							drift: time,
							schoolId: this.http.currentSchoolSubject.getValue().id,
							client_in_future: time < 0,
							client_in_past: time > 0,
						},
						extra: {
							drift: time,
							id: user.id,
						},
					});
				})
			)
			.subscribe();

		this.screen.customBackdropEvent$.asObservable().subscribe((hasBackdrop) => {
			this.hasCustomBackdrop = hasBackdrop;
			this.cdr.detectChanges();
		});
		this.screen.customBackdropStyle$.asObservable().subscribe({
			next: (customStyle: Record<string, string>) => (this.customStyle = customStyle),
		});
		this.hasCustomBackdrop$ = this.screen.customBackdropEvent$.asObservable();

		this.router.events
			.pipe(
				// tap here before the filter for device detection to add sentry integrations for specific schools and urls
				tap(async (event) => {
					if (event instanceof NavigationEnd) {
						this.isAdminPage = event.url.startsWith('/admin/');
						if (this.user?.isStaff() && event.url === '/main/passes') {
							this.teacherHomeScreen = true;
						} else {
							this.teacherHomeScreen = false;
						}
					}
				}),
				filter(() => DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile())
			)
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					window.history.pushState({}, '');
				}
			});

		this.helpCenter.open$.subscribe((open) => {
			if (open) {
				this.openHelpCenter(open);
			}
		});

		this.userService.loadedUser$
			.pipe(
				filter((l) => l),
				switchMap(() => this.userService.userJSON$.pipe(take(1))),
				filter((user) => !!user),
				map((user) => User.fromJSON(user)),
				tap((user) => {
					if (user.roles.length === 0) {
						this.oldLoginService.clearInternal(true);
						this.toastService.openToast({
							title: 'Oh no! Something went wrong',
							subtitle: `An error occurred while retrieving your user data. If the issue keeps occurring, contact us at support@smartpass.app. (Error: user has no roles)`,
							type: 'error',
						});
					}
				}),
				// Wait for schools to load so that we can register intercom and refiner correctly.
				mergeMap((user) => this.http.schools$.pipe(map(() => user))),
				map((user: User) => {
					// create a websocket connection when user logs in
					this.liveUpdateService.refreshHeartbeatTimer();

					this.currentRoute = window.location.pathname;
					this.isStudent = user.isStudent();
					this.isAssistant = user.isAssistant();
					const isAllowed = urlBlockList.every((route) => !this.currentRoute.includes(route));
					if (!user.isStudent() && this.currentRoute.includes('/main/passes')) {
						this.teacherHomeScreen = true;
						this.cdr.detectChanges();
					}

					if (isAllowed && !this.isMobile) {
						this.userService.registerThirdPartyPlugins(user);
					}

					// Ask for notification permission if the user hasn't granted or denied it yet
					if (NotificationService.canRequestPermission) {
						this.notifService.requestNotificationPermission();
					}

					return user;
				})
			)
			.subscribe();

		// Automatically open active passes in modal if user is a student
		this.userService.effectiveUser$
			.pipe(
				filter((u) => u?.isStudent()),
				take(1),
				switchMap((user) => {
					return iif(
						() => user.isStudent(),
						merge(
							this.hallPassesService.watchPassBecomingActiveForStudent(user.id),
							this.screen.showPassInModal$,
							this.hallPassesService.getActivePasses().pipe(
								filter((passes) => !!passes),
								map((data) => {
									if (Array.isArray(data)) {
										return data.map((hp) => HallPass.fromJSON(hp));
									}
									return [];
								}),
								filter((hp) => {
									return hp.filter((p) => p.student.id === user.id && !p.activity_instance_id).length > 0;
								}),
								catchError((e, originalObs) => {
									console.log('Error in getActivePasses in app component', e);
									return originalObs;
								})
							)
						),
						of(null)
					);
				}),
				filter((activePass) => !!activePass),
				tap((activePass) => {
					if (Array.isArray(activePass)) {
						activePass = activePass[0];
					}
					if (activePass instanceof HallPass) {
						// only automatically open pass modal for active passes (not overtime)
						const passStatus = this.hallPassesService.getPassStatus(
							activePass.start_time,
							activePass.end_time,
							activePass.expiration_time,
							false,
							false,
							false
						);
						if (passStatus === 'active') {
							const { fromPast, forFuture } = this.hallPassesService.calculatePassStatus(activePass); // test whether this is redundant or not, we're already inside an active block
							const data = {
								pass: activePass,
								fromPast,
								forFuture,
								isActive: passStatus === 'active' || passStatus === 'overtime',
								forStaff: false,
								kioskMode: false,
								showStudentInfoBlock: false,
							};
							const modalHeight = this.hallPassesService.getModalHeight(activePass, false, false);
							const modalWidth = this.hallPassesService.getModalWidth(DeviceDetection.isMobile());
							const config: MatDialogConfig = {
								panelClass: 'student-pass-card-dialog-container',
								width: modalWidth,
								height: modalHeight,
								maxHeight: `${MODAL_MAX_HEIGHT}px`,
								data: data,
							};
							const dialogRef = this.dialog.open(PassCardComponent, config);
							dialogRef.afterClosed().subscribe(() => {
								this.screen.clearCustomBackdrop();
							});
						}
					}
				})
			)
			.subscribe();
		this.shortcutsService.initialize();
		this.shortcuts = this.shortcutsService.shortcuts;

		INITIAL_LOCATION_PATHNAME.next(window.location.pathname);

		this.darkTheme.isEnabled$.subscribe((val) => {
			this.darkThemeEnabled = !!val;
			document.documentElement.style.background = val ? '#0F171E' : '#FBFEFF';
			document.body.style.boxShadow = `0px 0px 100px 100px ${val ? '#0F171E' : '#FBFEFF'}`;
		});

		if (!DeviceDetection.isIOSTablet() && !DeviceDetection.isMacOS()) {
			const link = document.createElement('link');
			link.setAttribute('rel', 'stylesheet');
			link.setAttribute('href', './assets/css/custom_scrollbar.css');
			document.head.appendChild(link);
		}

		this.showUISubject.next(true);

		this.oldLoginService.isAuthenticated$
			.pipe(
				filter(Boolean),
				tap(() => {
					this.oldLoginService.startCookieVerificationTimer();
					// remove session logout toast in case it's open
					this.toastService.closeToast([ToastIdEnum.SESSION_EXPIRED]);
				})
			)
			.subscribe();

		combineLatest([
			this.oldLoginService.continueAuthFlow$.asObservable(),
			this.oldLoginService.checkIfAuthStored(),
			this.oldLoginService.isAuthenticated$.asObservable(),
		])
			.pipe(
				map(([, authOnLoad, authStateChanged]) => authOnLoad || authStateChanged),
				distinctUntilChanged(),
				tap((isAuth) => {
					this.isAuthenticated = isAuth;
					const path = window.location.pathname;
					if (!isAuth) {
						if (path.includes('main/student')) {
							this.storageService.setItem('initialUrl', path);
						}
					}
				}),
				filter(Boolean),
				mergeMap(() => {
					/**
					 * We need to determine if the user is a parent or not.
					 * Since the login data is tied to an account, and we're already authenticated at this point, we can send
					 * a parent info request. If the request returns a 403 error, that means the account is not a parent
					 * account, and we can move forward to getting the schools and then the user data.
					 * If the request completes successfully, that means the account is a parent and there's no need to get
					 * schools data since schools are not tied to a parent account.
					 *
					 * We use a different endpoint instead of the regular v1/users/@me endpoint since those users are tied to
					 * schools and parents aren't.
					 *
					 * We should make raw requests here instead of the NgRx since the store hasn't been populated yet
					 */
					return this.parentService.getParentInfo().pipe(
						catchError((err) => {
							if (err instanceof HttpErrorResponse && err.status === 403) {
								// logged-in user is not a parent
								return of(null);
							}

							return throwError(err);
						}),
						concatMap((parentAccount) => {
							if (!parentAccount) {
								return this.http.schools$.pipe(
									concatMap(() => {
										this.userService.getUserRequest();
										return this.userService.userJSON$.pipe(
											takeUntil(this.subscriber$),
											filter<User>(Boolean),
											map((u) => User.fromJSON(u))
										);
									})
								);
							}

							return of(User.fromJSON(parentAccount));
						})
					);
				}),
				distinctUntilKeyChanged('id'),
				tap((user) => {
					this.titleService.setTitle('SmartPass');

					this.userService.getIntrosRequest();
					const sch = this.http.getSchool();
					if (sch) {
						if (this.noFlyTimeService.noFlyTimeEnabled) {
							this.noFlyTimeService.loadNoFlyTimes(sch);
						}
					}
					this.showUISubject.next(true);
					this.isAuthenticated = true;
					user = User.fromJSON(user);
					this.isAdmin = user.isAdmin();
					this.isTeacher = user.isTeacher();
					if (NotificationService.hasPermission) {
						this.notifService.initNotifications(true);
					}
					const href = window.location.href;
					if (href.includes('/admin') || href.includes('/main') || href.includes('id-card')) {
						return;
					}
					if (user.isParent()) {
						this.router.navigate(['/parent']);
						return;
					}
					if (!href.includes('dev-playground')) {
						// For third party auth users, we don't want their code and scope query string stored in the router's history,
						// because if they navigate back to the login page, the code will be invalid and they will receive an error.
						// This will remove it.
						if (href.includes('state')) {
							const decodedState = this.getStateValueFromUrl(href);
							this.router.navigate([`${decodedState}`]);
						} else if (href.includes('code') || href.includes('scope') || href.includes('instant_login')) {
							this.router.navigate(['/home'], { replaceUrl: true });
						} else {
							this.router.navigate(['/home']);
						}
					}
				})
			)
			.subscribe();

		const params = new URL(window.location.href).searchParams;
		const [instant_login, code, scope] = [params.get('instant_login'), params.get('code'), params.get('scope')];
		const isThirdPartyLogin = !!(instant_login || code || scope);
		if (!isThirdPartyLogin) {
			this.oldLoginService.continueAuthFlow$.next(true);
		}

		this.http.schoolsCollection$
			.pipe(
				map((schools) => _filter(schools, (school) => school.my_roles.length > 0)),
				withLatestFrom(this.http.currentSchool$),
				takeUntil(this.subscriber$)
			)
			.subscribe(([schools, currentSchool]) => {
				this.schools = schools;
				if (currentSchool) {
					const isCurrentSchoolInList = schools.find((s) => s.id === currentSchool.id);
					if (!isCurrentSchoolInList) {
						this.http.setSchool(schools[0]);
					}
					this.hasPhoneSupport$ = this.entitlementsService.isEntitlementEnabled$(ENTITLEMENTS.HallPassPro);
					this.hasPhoneSupport$.pipe(takeUntil(this.subscriber$)).subscribe((support) => {
						this.hasPhoneSupport = support;
					});
				}
			});

		this.http.currentSchool$.pipe(takeUntil(this.subscriber$)).subscribe((value) => {
			if (!value) {
				this.schools = [];
			}
		});

		this.router.events
			.pipe(
				takeUntil(this.subscriber$),
				filter((event) => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map((route) => {
					if (this.isMobile) {
						window.Intercom('update', { hide_default_launcher: true });
					}
					this.isKioskMode = this.router.url.includes('kioskMode');
					this.isAdminPage = this.router.url.includes('admin');
					if (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				mergeMap((route) => route.data),
				switchMap((data) => {
					this.hideSchoolToggleBar = data.hideSchoolToggleBar;
					this.hideScroll = data.hideScroll;
					return this.effectiveUser$;
				}),
				switchMap((user) => {
					return combineLatest([user.isStudent() ? of(null) : this.monetizationService.monetizationInfo$, this.http.schoolsLength$]);
				})
			)
			.subscribe(([monetizationInfo, numberOfSchools]) => {
				if (this.isKioskMode) {
					this.mainContentMarginTop = '0px';
					return;
				}
				let topMargin = 0;
				if (monetizationInfo) {
					this.monetizationInfo = monetizationInfo;
					if (monetizationInfo.monetization_pipeline) {
						this.displayTrialBanner = this.evaluateBannerDisplay(
							this.isAdmin,
							this.isTeacher,
							this.isAdminPage,
							monetizationInfo.monetization_pipeline,
							monetizationInfo.daysRemaining,
							monetizationInfo.customerSubscriptionSecondWarning
						);
						if (this.displayTrialBanner) {
							topMargin = TRIAL_BAR_HEIGHT;
						}
					}
				} else {
					this.displayTrialBanner = false;
				}

				if (numberOfSchools > 1 && !this.hideSchoolToggleBar) {
					topMargin += SCHOOL_TOGGLEBAR_HEIGHT;
				}
				this.mainContentMarginTop = `${topMargin}px`;
			});

		this.router.events
			.pipe(
				takeUntil(this.subscriber$),
				filter((event) => event instanceof NavigationEnd),
				map(({ url }: NavigationEnd) => ({
					url,
					isInBlockList: urlBlockList.includes(url),
				})),
				distinctUntilKeyChanged('isInBlockList'),
				mergeMap((urlInfo) => {
					if (urlInfo.isInBlockList) {
						return of({ ...urlInfo, user: null as User | null, school: null as School | null });
					} else {
						return combineLatest([this.userService.user$, this.http.currentSchool$]).pipe(
							filter(([u, s]) => !!u && !!s),
							map(([user, school]) => ({ ...urlInfo, user, school }))
						);
					}
				})
			)
			.subscribe(({ isInBlockList, user, school }: { isInBlockList: boolean; user: User; school: School }) => {
				// calls to refiner are idempotent, so it's safe to call these functions repleated
				// and even if no registration previously happened
				if (isInBlockList || user.isStudent() || user.isKiosk()) {
					deregisterRefiner();
				} else {
					registerRefiner(user, school);
				}
			});

		combineLatest([this.featureFlags.isFeatureEnabledV2$(FLAGS.Schedules), this.effectiveUser$, this.scheduleService.listenForBellScheduleUpdate()])
			.pipe(
				filter(([isEnabled, _u, event]) => event?.data && isEnabled),
				switchMap(([_en, user, _ev]) => this.loadScheduleData(user))
			)
			.subscribe();
	}

	ngAfterViewInit(): void {
		if (this.isMobile) {
			window.Intercom('update', { hide_default_launcher: true });
		}
		APPLY_ANIMATED_CONTAINER.subscribe((v: boolean) => {
			if (!this.dialogContainer) {
				return;
			}
			if (v) {
				const zIndexForContainer = (this.dialog.openDialogs.length + 1) * 1000;
				this.dialogContainer.classList.add('unanimated-dialog-container');
				this.dialogContainer.style.zIndex = `${zIndexForContainer}`;
				(this.overlayContainer as ConsentMenuOverlay).setContainer(this.dialogContainer);
			} else {
				this.dialogContainer.style.zIndex = '-1';
				this.dialogContainer.classList.remove('unanimated-dialog-container');
				(this.overlayContainer as ConsentMenuOverlay).restoreContainer();
			}
		});

		// listen for existence of Intercom wrapper
		// stop listening when the Intercom wrapper is found
		const targetNode = document.body;
		const listenerConfig = { childList: true, subtree: true };
		this.intercomObserver = new MutationObserver((mutationList) => {
			for (const m of mutationList) {
				if ((m.target as HTMLElement).tagName !== 'BODY') {
					return;
				}
				m.addedNodes.forEach((node) => {
					if (node.nodeType === node.COMMENT_NODE) {
						return;
					}
					if ((node as HTMLElement).classList?.contains('intercom-lightweight-app')) {
						if (node) {
							(node as HTMLDivElement).style.display = 'none';
						}
						this.intercomObserver.disconnect();
					}
				});
			}
		});
		this.intercomObserver.observe(targetNode, listenerConfig);
	}

	getStateValueFromUrl(url: string): string | null {
		// Create a URL object from the given string
		const urlObj = new URL(url);

		// Get query parameters using URLSearchParams
		const state = urlObj.searchParams.get('state');

		// Decode the state parameter if it exists
		return state ? decodeURIComponent(state) : null;
	}

	openHelpCenter(event: boolean): void {
		this.helpCenter.isHelpCenterOpen.next(event);
		setTimeout(() => {
			const BORDER_SIZE = 8;
			const panel = document.querySelector<HTMLElement>('#help-center-content');
			const dragDivider = document.querySelector<HTMLElement>('.drag-divider');

			setTimeout(() => {
				const mainRouter = document.querySelector<HTMLElement>('.router-outlet');
				if (mainRouter !== null) {
					mainRouter.style.transition = 'none';
				}
			}, 1000);
			if (panel !== null) {
				panel.style.transition = 'none';
				this.fixHelpCenterHeight(panel);
			}

			let m_pos: number;

			function resize(e: MouseEvent) {
				const dx = m_pos - e.x;
				m_pos = e.x;

				if (panel !== null) {
					panel.style.width = parseInt(getComputedStyle(panel, '').width) + dx + 'px';
				}
			}

			const iframe = document.querySelector<HTMLIFrameElement>('.help-center-unsubscribe');

			const mouseDown$ = fromEvent<MouseEvent>(panel, 'mousedown');
			const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
			const mouseUp$ = merge(
				fromEvent<MouseEvent>(dragDivider, 'mouseup'),
				fromEvent<MouseEvent>(iframe, 'mouseup'),
				fromEvent<MouseEvent>(panel, 'mouseup'),
				fromEvent<MouseEvent>(document, 'mouseup')
			);

			mouseDown$
				.pipe(
					switchMap((event) =>
						mouseMove$.pipe(
							tap((ev) => {
								if (event.offsetX < BORDER_SIZE) {
									resize(ev);
									iframe.style.display = 'block';
									document.body.style.cursor = 'col-resize';
									dragDivider.style.setProperty('--drag-after-color', '#00B476');
									dragDivider.style.setProperty('--drag-after-shadow', '1px');
									dragDivider.style.setProperty('--drag-after-left', '2px');
								}
							}),
							takeUntil(mouseUp$),
							finalize(() => {
								document.body.style.cursor = 'default';
								dragDivider.style.setProperty('--drag-after-color', '#B7C1CF');
								dragDivider.style.setProperty('--drag-after-shadow', '0px');
								dragDivider.style.setProperty('--drag-after-left', '0px');
								iframe.style.display = 'none';
							})
						)
					)
				)
				.subscribe();

			const myEl = document.querySelector('#help-center-content');

			if (myEl !== null) {
				// Create observer
				const observer = new ResizeObserver(() => {
					if (!this.helpCenter.isHelpCenterOpen.getValue()) {
						this.mainContentWidth = '100%';
						return;
					}

					const helpCenterContent = document.getElementById('help-center-content');
					if (helpCenterContent !== null) {
						this.mainContentWidth = `calc(100% - ${helpCenterContent.offsetWidth}px)`;
					}
				});

				// Add element (observe)
				observer.observe(myEl);
			}
		}, 100);
	}

	private fixHelpCenterHeight(panel: HTMLElement): void {
		if (this.schools && this.schools.length > 1 && !this.hideSchoolToggleBar) {
			panel.style.height = 'calc(100vh - 36px)';
			panel.style.top = '36px';
		}
	}

	closeHelpCenter(): void {
		this.helpCenter.isHelpCenterOpen.next(false);
		window.Intercom('update', { hide_default_launcher: true });
	}

	openCallDialog(event: MouseEvent): void {
		const target = new ElementRef(event.currentTarget);
		const CDC: MatDialogRef<CallDialogComponent> = this.dialog.open(CallDialogComponent, {
			panelClass: 'consent-dialog-container-helpcenter',
			backdropClass: 'invis-backdrop-helpcenter',
			data: {
				trigger: target,
			},
		});
		const helpCenter = window.document.querySelector<HTMLDivElement>('.invis-backdrop-helpcenter');
		if (helpCenter !== null) {
			const parentNode = helpCenter.parentNode as HTMLElement;
			if (parentNode !== null) {
				parentNode.style.zIndex = '1009';
			}
		}

		CDC.afterClosed().subscribe(() => {
			const cdkOverlayContainer = window.document.querySelector<HTMLDivElement>('.cdk-overlay-container');
			if (cdkOverlayContainer !== null) {
				cdkOverlayContainer.style.zIndex = '1005';
			}
		});
	}

	private evaluateBannerDisplay(
		isAdmin: boolean,
		isTeacher: boolean,
		isAdminPage: boolean,
		bannerType: 'free_trial' | 'early_access' | 'customer' | 'payment_overdue' | 'subscription_expired' | 'churned',
		daysRemaining: number,
		customerSubscriptionSecondWarning: number
	): boolean {
		const userType = isAdmin ? 'admin' : isTeacher ? 'teacher' : 'student';
		switch (userType) {
			case 'admin':
				if (isAdminPage) {
					return (
						bannerType === 'early_access' ||
						(bannerType === 'customer' && daysRemaining <= customerSubscriptionSecondWarning) ||
						bannerType === 'payment_overdue' ||
						bannerType === 'free_trial' ||
						bannerType === 'churned'
					);
				} else {
					return bannerType === 'free_trial';
				}
				break;
			case 'teacher':
				return bannerType === 'free_trial';
				break;
		}
		return false;
	}
}
