import { Component, Input, OnInit } from '@angular/core';
import _refiner from 'refiner-js';
import { BannerType, MonetizationInfo } from '../models/School';

@Component({
	selector: 'sp-trial-bar',
	templateUrl: './trial-bar.component.html',
	styleUrls: ['./trial-bar.component.scss'],
})
export class TrialBarComponent implements OnInit {
	@Input() monetizationInfo: MonetizationInfo | null = null;
	@Input() isAdmin = false;
	@Input() isTeacher = false;

	today = new Date();
	bannerType: any; // component is displayed when this is set
	dayString: 'day' | 'days';
	tenseString: 'do' | 'did';

	daysRemaining: number;
	duration: number;
	progressBar: number;
	activeAccount: boolean;
	suspensionDaysRemaining: number;
	suspendProgressBar: number;
	suspendedAccount: boolean;
	freeTrialEarlyAccessProgressBar: number;
	externalLink: string;
	customerSubscriptionSecondWarning: number;

	/**
	 * bannerClass is set in the format `banner-<bannerType>`. For example: `banner-free_trial` or
	 * `banner-payment_overdue`. The bannerType is set on the server set and is available on the school object.
	 * Keep this in mind if this file is being edited and the styles don't quite match up.
	 */
	bannerClass: string;

	getDayString(dayCount: number): string {
		return dayCount === 1 ? 'day' : 'days';
	}

	externalPricingLink(): string {
		if (this.externalLink === '') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return window.Intercom('showNewMessage');
		} else {
			window.open(this.externalLink, '_blank');
		}
	}

	constructor() {}

	ngOnInit(): void {
		if (this.monetizationInfo) {
			this.bannerType = this.monetizationInfo.monetization_pipeline;
			this.daysRemaining = this.monetizationInfo.daysRemaining;
			this.duration = this.monetizationInfo.duration;
			this.progressBar = this.monetizationInfo.progressBar;
			this.activeAccount = this.monetizationInfo.activeAccount;
			this.suspensionDaysRemaining = this.monetizationInfo.suspensionDaysRemaining;
			this.suspendProgressBar = this.monetizationInfo.suspendProgressBar;
			this.suspendedAccount = this.monetizationInfo.suspendedAccount;
			this.freeTrialEarlyAccessProgressBar = this.monetizationInfo.freeTrialEarlyAccessProgressBar;
			this.externalLink = this.monetizationInfo.cta_link;
			this.customerSubscriptionSecondWarning = this.monetizationInfo.customerSubscriptionSecondWarning;

			this.bannerClass = this.getBannerClass();
			this.dayString = this.daysRemaining === 1 ? 'day' : 'days';
			this.tenseString = this.suspensionDaysRemaining < 1 ? 'did' : 'do';
		}
	}

	private getBannerClass() {
		return `banner-${this.bannerType}`;
	}

	// .helpContainer > .earlyAccessHelp is used for the Early Access Banner's "Need Help" button
	// .helpContainer > .help is used for the Subscription Expired Banner's "Need Help" button
	// .helpContainer > .overdueContact is used for the Overdue Payment Banner's "Contact Us" button
	// .helpContainer > .suspendedContact is used for the Suspended Banner's "Contact Us" button
	// These are important so that clicking on these buttons opens intercom on the right page.
	// Opening intercom on a specific page is set by CS team, not by eng.
	openIntercom() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.Intercom('showNewMessage');
	}

	protected readonly BannerType = BannerType;

	getBannerStyle() {
		if (this.suspendedAccount) {
			if (this.bannerType === 'customer' || this.bannerType === 'churned') {
				return { 'background-color': '#E32C66' };
			}
			if (this.bannerType === 'early_access' || this.bannerType === 'free_trial') {
				return { 'background-color': '#E48C15' };
			}
		} else if (this.bannerType === 'customer' && !this.activeAccount) {
			return { 'background-color': '#E48C15' };
		}
		return null; // Default case: no special styling
	}

	onFeedbackClick(): void {
		_refiner('showForm', 'c1641910-eaca-11ee-9d10-0b59fd390628', true);
	}
}
