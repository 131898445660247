import { Injectable } from '@angular/core';
import { Location } from '../../models/Location';
import { User } from '../../models/User';

@Injectable({
	providedIn: 'root',
})
export class LocationVisibilityService {
	getIdOrThrow(s: User): string {
		if (s?.id) {
			return '' + s.id;
		}
		throw new Error('no appropriate id');
	}

	// mimic server visibility byid  calculation
	calculateSkipped(users: User[], location: Location): string[] {
		const rule = location.visibility_type;
		if (rule === 'visible_all_students') {
			return [];
		}

		const studentIds = users.map((s: User) => this.getIdOrThrow(s));
		const ruleStudents: string[] =
			location.visibility_students_ids && location.visibility_students_ids.length > 0
				? location.visibility_students_ids.map((id) => '' + id)
				: (location.visibility_students as User[]).map((s: User) => this.getIdOrThrow(s));

		let byId: string[] = [];

		// when we have just grades this visibility_students is empty
		if (ruleStudents.length > 0) {
			// filter by ids
			byId = studentIds.filter((id) => ruleStudents.includes(id));
		}

		// filter by grade
		const ruleGrades = location?.visibility_grade ?? [];
		const _byGrade: User[] = users.filter((s: User) => {
			// without grade don't put it in
			if (!s?.grade_level) {
				return false;
			}
			return ruleGrades.includes(s.grade_level);
		});
		const byGrade: string[] = _byGrade.map((s: User) => '' + s.id);

		const accepted: string[] = [...byId, ...byGrade]
			// keep only unique values
			.filter((uid: string, i: number, arr: string[]) => arr.indexOf(uid) === i);
		const skipped = users.filter((u: User) => !accepted.includes('' + u.id)).map((u: User) => this.getIdOrThrow(u));

		return rule === 'visible_certain_students' ? skipped : accepted;
	}

	filterByVisibility(location: Location, students: User[]): boolean {
		const skipped = this.calculateSkipped(students, location);
		return skipped.length === 0;
	}
}
