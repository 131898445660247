<div class="report-form-wrapper">
	<div class="divider">
		<img
			rel="back-button"
			[ngClass]="isIOSTablet ? 'ios-back-button' : 'back-button'"
			[src]="'./assets/Back Button (White).svg' | resolveAsset"
			(click)="back()" />

		<div *ngIf="showOptions" [@NextStep]="{ value: '', params: frameMotion$ | async }">
			<div class="divider-text-students ds-dialog-title-white" [attr.data-motion-translate]="true">Report Student</div>
		</div>
		<div *ngIf="!showOptions" [@NextStep]="{ value: '', params: frameMotion$ | async }">
			<div class="divider-text-message ds-dialog-title-white" [attr.data-motion-translate]="true">Report Student</div>
		</div>
		<ng-container *ngIf="!selectedStudents.length; else submitButton"></ng-container>
	</div>

	<ng-template #submitButton>
		<app-white-button
			[style.float]="'right'"
			[style.margin-right]="'20px'"
			[textstyle]="{ 'font-weight': '500', color: '#E32C66', padding: '10px' }"
			(buttonClick)="sendReport()"
			>Submit</app-white-button
		>
	</ng-template>

	<div class="wrap-groups">
		<div class="group">
			<label for="student-select">Student</label>
			<div id="student-select" class="student-select" [attr.data-motion-translate]="true">
				<div class="report-content">
					<app-sp-search
						*ngIf="!useChipInsteadSearch"
						[focused]="showOptions"
						[chipsMode]="useChipMode"
						[selectedOptions]="selectedStudents"
						[showOptions]="showOptions"
						(onUpdate)="selectedStudents = $event"
						(click)="showOptions = true">
					</app-sp-search>

					<div notranslate class="chip" *ngIf="useChipInsteadSearch">{{ data?.report?.display_name }}</div>
				</div>
			</div>
		</div>

		<div class="group">
			<label for="message-entry">Message</label>
			<div id="message-entry" class="message-entry" [attr.data-motion-translate]="true">
				<div data-grammarly-issue-container class="message-wrapper">
					<textarea notranslate class="message-box" #messageBox placeholder="Ex. Student did not return to class" [(ngModel)]="reportMessage">{{
						reportMessage
					}}</textarea>
				</div>
			</div>
		</div>

		<ng-container *ngIf="!showOptions && data['pass'] && !!data?.isHallPass">
			<div class="group">
				<label for="reported-pass">Pass</label>
				<div id="reported-pass" class="tile">
					<app-pass-tile
						[presentational]="true"
						[pass]="pass"
						[forStaff]="data['forStaff']"
						[fromPast]="data['fromPast']"
						[forFuture]="data['forFuture']"
						[isActive]="data['isActive']"
						[allowPopup]="false"
						[profileImage]="data['showProfilePictures']"></app-pass-tile>
				</div>
			</div>
		</ng-container>
	</div>
</div>
