import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, ReplaySubject, Subject, of, zip } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Location, Pinnable } from '../models';
import { HallPassesService } from '../services/hall-passes.service';
import { LocationsService } from '../services/locations.service';
import { ScreenService } from '../services/screen.service';
import { ConfirmationDialogComponent, ConfirmationTemplates } from '../shared/shared-components/confirmation-dialog/confirmation-dialog.component';
import { LocationVisibilityService } from './main-hallpass--form/location-visibility.service';
import { Navigation } from './main-hallpass--form/main-hall-pass-form.component';

export type FrameMotionTransition = {
	to: number;
	halfTo: number;
	from: number;
	halfFrom: number;
	direction: 'forward' | 'back' | 'disable';
	frameSpeed: string;
	subFrameSpeed: string;
};

@Injectable({
	providedIn: 'root',
})
export class CreateFormService {
	private transition: FrameMotionTransition;
	private frameMotionDirection$: BehaviorSubject<FrameMotionTransition>;

	scalableBoxController = new ReplaySubject<boolean>(1);
	compressableBoxController = new ReplaySubject<boolean>(1);
	isSeen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	// send modified Location to child component to update its choices
	private updatedChoice$: Subject<Location> = new Subject<Location>();

	constructor(
		private hallPassService: HallPassesService,
		private locService: LocationsService,
		private screenService: ScreenService,
		private visibilityService: LocationVisibilityService,
		private dialog: MatDialog
	) {
		this.transition = {
			to: -100,
			halfTo: -50,
			from: 100,
			halfFrom: 50,
			direction: 'disable',
			frameSpeed: this.screenService.isDeviceLargeExtra ? '.23s' : '.27s',
			subFrameSpeed: this.screenService.isDeviceLargeExtra ? '.10s' : '.15s',
		};
		this.frameMotionDirection$ = new BehaviorSubject(this.transition);
	}

	getUpdatedChoice(): Observable<Location> {
		return this.updatedChoice$ as Observable<Location>;
	}

	setUpdatedChoice(loc: Location): void {
		this.updatedChoice$.next(loc);
	}

	updatedByWS$ = new BehaviorSubject<boolean>(false);

	getPinnable(filter?: boolean) {
		return this.hallPassService.pinnables$.pipe(
			map((pins: Pinnable[]) => {
				if (filter) {
					return pins.filter((p: Pinnable) => {
						return p.type === 'location' || p.type === 'category';
					});
				} else {
					return pins;
				}
			}),
			switchMap((pinnables) => {
				if (filter) {
					return zip(
						...pinnables.map((pin: any) => {
							if (pin.type === 'category') {
								return this.locService.getLocationsWithCategory(pin.category).pipe(
									map((locations) => {
										pin.myLocations = locations;
										return pin;
									})
								);
							} else {
								return of(pin);
							}
						})
					);
				} else {
					return of(pinnables);
				}
			})
		);
	}

	setFrameMotionDirection(direction: 'forward' | 'back' | 'disable' = 'forward') {
		switch (direction) {
			case 'disable':
				this.transition.direction = 'disable';
				this.transition.to = 0;
				this.transition.halfTo = 0;
				this.transition.from = 0;
				this.transition.halfFrom = 0;
				this.frameMotionDirection$.next(this.transition);
				break;

			case 'forward':
				this.transition.direction = 'forward';
				this.transition.to = -100;
				this.transition.halfTo = -50;
				this.transition.from = 100;
				this.transition.halfFrom = 50;
				this.frameMotionDirection$.next(this.transition);
				break;

			case 'back':
				this.transition.direction = 'back';
				this.transition.to = 100;
				this.transition.halfTo = 50;
				this.transition.from = -100;
				this.transition.halfFrom = -50;
				this.frameMotionDirection$.next(this.transition);
				break;
		}
	}

	getFrameMotionDirection() {
		return this.frameMotionDirection$;
	}

	// Resets the frame motion direction to 'disable' after closeObs observable fires
	// We take 1 here to ensure the subscription closes automatically.
	// This prevents the frame motion direction from latching on to a previous value,
	// which would have caused other modals relying on the frame motion direction
	// to open with the previous slide animation.
	cleanupDirectionOnClose(closeObs: Observable<unknown>): void {
		closeObs.pipe(take(1)).subscribe(() => {
			this.setFrameMotionDirection('disable');
		});
	}

	nextStep(formState: Navigation, selectingOrigin: boolean, emit: () => void) {
		this.setFrameMotionDirection('forward');
		this.compressableBoxController.next(false);

		setTimeout(() => {
			if (selectingOrigin) {
				formState.previousState = 1;
			}

			emit();
		}, 100);
	}

	checkRoomVisibility(formState: Navigation, selectingOrigin: boolean, location: Location, emitEvent: () => void) {
		const selectedStudents = formState.data.roomStudents ?? formState.data.selectedStudents;
		const skipped = this.visibilityService.calculateSkipped(selectedStudents, location);

		if (skipped.length === 0) {
			this.nextStep(formState, selectingOrigin, emitEvent);
			return of(true);
		}

		let text = 'This room is only available to certain students';
		const names = selectedStudents.filter((s) => skipped.includes('' + s.id)).map((s) => s.display_name);
		let title = 'Student does not have permission to go to this room';
		let denyText = 'Skip';
		if (names.length > 1) {
			text = names?.join(', ') ?? 'This room is only available to certain students';
			title = `These students do not have permission to go ${selectingOrigin ? 'from' : 'to'} this room:`;
			denyText = 'Skip These Students';
		} else {
			title = `${names?.join(', ') ?? 'Student'} does not have permission to go ${selectingOrigin ? 'from' : 'to'} this room`;
		}

		const roomStudents = selectedStudents.filter((s) => !skipped.includes('' + s.id));
		const noStudentsCase = roomStudents.length === 0;
		if (noStudentsCase) {
			denyText = 'Cancel';
		}
		const visibilityDialogConfig = {
			panelClass: 'overlay-dialog',
			backdropClass: 'custom-backdrop',
			closeOnNavigation: true,
			width: '450px',
			data: {
				headerText: title,
				buttons: {
					confirmText: 'Override',
					denyText,
				},
				templateData: {
					detailText: text,
				},
				icon: {
					name: 'Eye (Green-White).svg',
					background: '',
					spacing: '5px',
				},
			} as ConfirmationTemplates,
		};

		return this.dialog
			.open(ConfirmationDialogComponent, visibilityDialogConfig)
			.afterClosed()
			.pipe(
				tap((overrideSkippedStudents: boolean) => {
					formState.data.roomOverride = !!overrideSkippedStudents;
					if (overrideSkippedStudents === undefined) {
						return;
					}
					// override case
					if (overrideSkippedStudents) {
						if (selectingOrigin) {
							formState.data.roomStudents = [...formState.data.selectedStudents];
							formState.data.roomStudentsAfterFromStep = [...formState.data.selectedStudents];
						}
						this.nextStep(formState, selectingOrigin, emitEvent);
						return;
					}

					if (selectedStudents.length === 1) {
						return;
					}

					if (noStudentsCase) {
						return;
					}

					formState.data.roomStudents = roomStudents;
					if (selectingOrigin) {
						formState.data.roomStudentsAfterFromStep = [...roomStudents];
					}
					this.nextStep(formState, selectingOrigin, emitEvent);
				})
			);
	}
}
