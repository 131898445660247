<div class="wrapper">
	<app-round-input
		*ngIf="!searchDisabled"
		[style.width]="'100%'"
		[minWidth]="'20px auto 5px auto'"
		[width]="'100%'"
		[type]="'text'"
		[placeholder]="placeholder"
		[fieldIconPosition]="'left'"
		[closeIcon]="true"
		[focused]="true"
		[boxShadow]="false"
		[backgroundColor]="!screenService.isDeviceLargeExtra ? '#FFFFFF' : '#F4F4F4'"
		(ontextupdate)="onSearch($event)"></app-round-input>

	<div class="main-content" *ngIf="mainContentVisibility" [style.margin-top]="!screenService.isDesktopWidth ? '20px' : '0px'">
		<div *ngIf="loading$ | async; else mainRoomSelection" class="loading-theme_blue-gray spinner ds-w-100 ds-h-100 ds-flex-center-center">
			<mat-spinner [diameter]="35" [color]="'accent'"></mat-spinner>
		</div>

		<ng-template #mainRoomSelection>
			<div class="suggested-rooms" *ngIf="!searchActive">
				<ng-content select="[pin-collection]"></ng-content>
			</div>

			<div *ngIf="showFavorites && !searchActive && starredChoices.length > 0" class="table-left">
				<div class="fav-header" *ngIf="!forKioskMode">Favorites</div>
				<!-- Create Pass Form -->
				<div *ngIf="favoritesLoaded && !forKioskMode && locationChoicesLoaded && !favAsPinnables; else favPinnableView" class="choices">
					<div *ngFor="let choice of starredChoices">
						<app-location-cell
							*ngIf="choice.passLimit"
							[borderRadius]="'12px'"
							(onSelect)="choiceSelected($event)"
							(onStar)="star($event)"
							[value]="choice"
							[currentPage]="currentPage"
							[passLimit]="choice.passLimit"
							[hasLocks]="hasLocks"
							[forLater]="forLater"
							[forStaff]="forStaff"
							[starred]="true"
							[showStar]="!!showStars"
							[showPicture]="showPicture"
							[pinnableIcon]="choice.roomIcon"
							[pinnableColorProfile]="choice.colorProfile"
							[showDivider]="false"
							[allowOnStar]="allowOnStar"
							[valid]="invalidLocation ? +choice.id !== +invalidLocation && choice.isValidLocation : true"
							[isSameRoom]="+choice.id === +invalidLocation">
						</app-location-cell>
					</div>
				</div>

				<ng-template #favPinnableView>
					<div
						class="pin-grid"
						*ngIf="favoritesLoaded && !forKioskMode && locationChoicesLoaded"
						[ngClass]="pinnableDimensions.isLarge ? 'grid-lg' : 'grid-sm'">
						<ng-container *ngFor="let pinnable of favoritePinnables">
							<ng-container *ngIf="checkPinnable(false, pinnable)">
								<app-pinnable
									[height]="pinnableDimensions.height"
									[width]="pinnableDimensions.width"
									[valid]="
										invalidLocation ? +pinnable?.location?.id !== +this.invalidLocation && isValidPinnable(pinnable) : isValidPinnable(pinnable)
									"
									[forStaff]="forStaff && !forKioskMode"
									[showActivePassesCounter]="forStaff && !forKioskMode"
									[passLimit]="getPassLimit(pinnable.location)"
									[disabledRoom]="pinnable.location ? !pinnable.location.enable : false"
									[forLater]="forLater"
									[pinnable]="pinnable"
									[currentPage]="'to'"
									[isSameRoom]="pinnable.location && originLocation ? +pinnable.location.id === +originLocation.id : false"
									(onSelectEvent)="pinnableSelected($event)"></app-pinnable>
							</ng-container>
						</ng-container>
					</div>
				</ng-template>
			</div>

			<div class="folder-back-button" *ngIf="folderSearch && !isFolderSearch" (click)="folderBackClicked()">
				<img class="folder-back-img" [src]="'./assets/Back-Button.svg'" alt="back button" /> All Rooms
			</div>
			<div class="all-rooms" [ngClass]="{ 'ds-mt-20': forKioskMode }">
				<div class="fav-header" *ngIf="!searchActive && !folderSearch && closestRoom2">
					{{ suggestedPinnables?.length > 0 && currentPage == 'to' ? 'Other Rooms' : 'All Rooms' }}
				</div>
				<div class="fav-header" *ngIf="!searchActive && folderSearch && closestRooms?.length == 0 && closestRoom2">{{ override?.headerText }}</div>
				<div class="fav-header" *ngIf="!forKioskMode && !searchActive && !closestRoom2">
					{{ override?.headerText || 'All Rooms' }}
				</div>

				<div class="fav-header" *ngIf="(!showAsPinnables || searchActive) && closestRoom2 && closestRooms?.length > 0 && currentPage == 'to'">
					Closest {{ folderSearch ? override?.headerText : 'Rooms' }}
				</div>
				<div class="choices" *ngIf="closestRooms?.length > 0 && currentPage == 'to'">
					<div *ngIf="!showAsPinnables || searchActive">
						<div class="choice-wrapper" *ngFor="let choice of closestRooms" #item>
							<app-location-cell
								*ngIf="choice.passLimit && choice.roomIsHidden"
								[hasLocks]="hasLocks"
								[forLater]="forLater"
								[forStaff]="forStaff"
								[borderRadius]="'12px'"
								[showStar]="showStars && !noRightStar"
								[showPicture]="showPicture"
								[pinnableIcon]="choice.roomIcon"
								[pinnableColorProfile]="choice.colorProfile"
								[starred]="choice.isSelected"
								[currentPage]="currentPage"
								[passLimit]="choice.passLimit"
								(onSelect)="choiceSelected($event)"
								[disabledRoom]="!choice.enable"
								(onStar)="star($event)"
								[allowOnStar]="allowOnStar"
								[value]="choice.normalizedLocation"
								[valid]="invalidLocation ? +choice.id !== +invalidLocation && choice.isValidLocation : true"
								[isSameRoom]="+choice.id === +invalidLocation"
								[kioskMode]="forKioskMode">
							</app-location-cell>
						</div>
					</div>
				</div>
				<div
					class="fav-header"
					*ngIf="
						currentPage == 'to' &&
						((folderSearch &&
							!this.isStudent &&
							!forKioskMode &&
							(!showAsPinnables || searchActive) &&
							closestRoom2 &&
							choices?.length > 0 &&
							closestRooms?.length > 0) ||
							(folderSearch &&
								forKioskMode &&
								isAllClosestRoomsClosed &&
								(!showAsPinnables || searchActive) &&
								closestRoom2 &&
								choices?.length > 0 &&
								closestRooms?.length > 0) ||
							(folderSearch &&
								this.isStudent &&
								isAllClosestRoomsClosed &&
								(!showAsPinnables || searchActive) &&
								closestRoom2 &&
								choices?.length > 0 &&
								closestRooms?.length > 0) ||
							(!folderSearch && (!showAsPinnables || searchActive) && closestRoom2 && choices?.length > 0 && closestRooms?.length > 0))
					">
					Other {{ folderSearch ? override?.headerText : 'Rooms' }}
				</div>
				<div
					class="choices"
					*ngIf="
						(folderSearch && !this.isStudent && !forKioskMode && choices?.length > 0) ||
						(folderSearch && !this.isStudent && forKioskMode && isAllClosestRoomsClosed && choices?.length > 0) ||
						(folderSearch && this.isStudent && isAllClosestRoomsClosed && choices?.length > 0) ||
						(folderSearch && !closestRoom2 && choices?.length > 0)
					">
					<div>
						<div class="choice-wrapper" *ngFor="let choice of choices" #item>
							<app-location-cell
								*ngIf="choice.passLimit && choice.roomIsHidden"
								[hasLocks]="hasLocks"
								[forLater]="forLater"
								[forStaff]="forStaff"
								[borderRadius]="'12px'"
								[showStar]="showStars && !noRightStar"
								[showPicture]="showPicture"
								[pinnableIcon]="choice.roomIcon"
								[pinnableColorProfile]="choice.colorProfile"
								[starred]="choice.isSelected"
								[currentPage]="currentPage"
								[passLimit]="choice.passLimit"
								(onSelect)="choiceSelected($event)"
								[disabledRoom]="!choice.enable"
								(onStar)="star($event)"
								[allowOnStar]="allowOnStar"
								[value]="choice.normalizedLocation"
								[valid]="invalidLocation ? +choice.id !== +invalidLocation && choice.isValidLocation : true"
								[isSameRoom]="+choice.id === +invalidLocation"
								[kioskMode]="forKioskMode">
							</app-location-cell>
						</div>
					</div>
				</div>
				<div class="choices" *ngIf="!folderSearch && choices?.length > 0">
					<div *ngIf="!showAsPinnables || searchActive; else pinnableView">
						<div class="choice-wrapper" *ngFor="let choice of choices" #item>
							<app-location-cell
								*ngIf="choice.passLimit && choice.roomIsHidden"
								[hasLocks]="hasLocks"
								[forLater]="forLater"
								[forStaff]="forStaff"
								[borderRadius]="'12px'"
								[showStar]="showStars && !noRightStar"
								[showPicture]="showPicture"
								[pinnableIcon]="choice.roomIcon"
								[pinnableColorProfile]="choice.colorProfile"
								[starred]="choice.isSelected"
								[currentPage]="currentPage"
								[passLimit]="choice.passLimit"
								(onSelect)="choiceSelected($event)"
								[disabledRoom]="!choice.enable"
								(onStar)="star($event)"
								[allowOnStar]="allowOnStar"
								[value]="choice.normalizedLocation"
								[valid]="invalidLocation ? +choice.id !== +invalidLocation && choice.isValidLocation : true"
								[isSameRoom]="+choice.id === +invalidLocation"
								[kioskMode]="forKioskMode">
							</app-location-cell>
						</div>
					</div>
				</div>
				<ng-template #pinnableView>
					<div class="pin-grid" *ngIf="locationChoicesLoaded && !searchActive" [ngClass]="pinnableDimensions.isLarge ? 'grid-lg' : 'grid-sm'">
						<ng-container *ngFor="let pinnable of orderedPinnables">
							<ng-container *ngIf="checkPinnable(false, pinnable)">
								<app-pinnable
									[height]="pinnableDimensions.height"
									[width]="pinnableDimensions.width"
									[valid]="
										invalidLocation ? +pinnable?.location?.id !== +this.invalidLocation && isValidPinnable(pinnable) : isValidPinnable(pinnable)
									"
									[forStaff]="forStaff && !forKioskMode"
									[passLimit]="getPassLimit(pinnable.location)"
									[disabledRoom]="pinnable.location ? !pinnable.location.enable : false"
									[forLater]="forLater"
									[pinnable]="pinnable"
									[currentPage]="'to'"
									[showActivePassesCounter]="forStaff && !forKioskMode"
									[isSameRoom]="pinnable.location && originLocation ? +pinnable.location.id === +originLocation.id : false"
									(onSelectEvent)="pinnableSelected($event)"></app-pinnable>
							</ng-container>
						</ng-container>
					</div>
				</ng-template>
			</div>
		</ng-template>

		<div class="no-choices" *ngIf="(loaded$ | async) && noChoices">
			<span class="no-results-text"> No Results Found </span>
			<span class="no-results-subtext">{{ dummyString }}</span>
		</div>
	</div>

	<div class="searched-content"></div>
</div>
