import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PassLimit } from '../../../models/PassLimit';

import * as passLimitActions from '../actions';
import { IPassLimitState } from '../states';

export const adapter: EntityAdapter<PassLimit> = createEntityAdapter<PassLimit>();

export const passLimitsInitialState: IPassLimitState = adapter.getInitialState({
	loading: false,
	loaded: false,
});

const reducer = createReducer(
	passLimitsInitialState,
	on(passLimitActions.getPassLimits, (state) => ({ ...state, loading: true, loaded: false })),
	on(passLimitActions.getPassLimitsSuccess, (state, { pass_limits }) => {
		if (!pass_limits) {
			pass_limits = [];
		}
		return adapter.setAll(pass_limits, { ...state, loading: false, loaded: true });
	}),
	on(passLimitActions.updatePassLimitSuccess, (state, { pass_limit }) => {
		let currentItem = { ...state.entities[pass_limit.location_id] };
		currentItem = {
			id: currentItem.id || Math.floor(Math.random()),
			...currentItem,
			from_count: pass_limit.passes_from,
			to_count: pass_limit.passes_to,
		};
		return adapter.upsertOne(currentItem, { ...state, loading: false, loaded: true });
	})
);

export function passLimitsReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
