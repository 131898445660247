import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { NavbarElementsRefsService } from '../../../services/navbar-elements-refs.service';

@Component({
	selector: 'app-help-center',
	templateUrl: './help-center.component.html',
	styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent {
	@Output() onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private navbarService: NavbarElementsRefsService) {}

	openHelpCenter() {
		this.onClick.emit(true);
	}
}
