import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ColorProfile } from '../../../models/ColorProfile';
import * as colorsActions from '../actions';
import { ColorsState } from '../states';

export const colorAdapter: EntityAdapter<ColorProfile> = createEntityAdapter<ColorProfile>();

export const colorProfileInitialState: ColorsState = {
	entities: {},
	ids: [],
	loading: false,
	loaded: false,
};

const reducer = createReducer(
	colorProfileInitialState,
	on(colorsActions.getColorProfiles, (state) => ({ ...state, loading: true, loaded: false })),
	on(colorsActions.getColorProfilesSuccess, (state, { colors }) => {
		return colorAdapter.setAll(colors, { ...state, loading: false, loaded: true });
	}),
	on(colorsActions.getColorProfilesFailure, (state) => ({ ...state, loading: false, loaded: true }))
);

export function colorsReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
