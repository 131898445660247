import { Injectable } from '@angular/core';

import { HttpService } from './http-service';
import { NoFlyTime, NoFlyTimeBroadcastMessage, NoFlyTimeResponse } from '../models/NoFlyTime';
import { BehaviorSubject, Observable, Subject, concat, timer, of, defer } from 'rxjs';
import * as moment from 'moment';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { School } from '../models/School';
import { LiveUpdateService } from './live-update.service';
import { captureException } from '@sentry/angular';

const NO_FLY_TIME_ENDPOINT = 'v2/no_fly_time';
@Injectable({
	providedIn: 'root',
})
export class NoFlyTimeService {
	private noFlyTimesSubject = new BehaviorSubject<NoFlyTime[]>([]);
	private destroy$: Subject<void> = new Subject<void>();
	noFlyTimes$ = this.noFlyTimesSubject.asObservable();
	currentSchool: School;
	noFlyTimeEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private http: HttpService, private liveUpdateService: LiveUpdateService) {
		this.startNoFlyTimeInterval();

		liveUpdateService
			.listen('no_fly_time.update')
			.pipe(
				map((msg) => msg.data as NoFlyTimeBroadcastMessage),
				tap((msg) => {
					console.log(msg);
					this.noFlyTimesSubject.next(msg.no_fly_times);
					this.noFlyTimeEnabled.next(msg.no_fly_time_enabled);
				}),
				catchError((err, caught) => {
					captureException(err);
					console.log('failed to handle no fly time broadcast message' + err);
					return caught;
				})
			)
			.subscribe();
	}

	loadNoFlyTimes(school: School) {
		this.currentSchool = school;
		this.noFlyTimeEnabled.next(school.no_fly_time_enabled);
		this.getNoFlyTimes().subscribe({
			next: (noFlyTimes) => {
				this.noFlyTimesSubject.next(noFlyTimes);
			},
		});
	}

	getNoFlyTime(nftId: number): Observable<NoFlyTimeResponse[]> {
		return this.http.post<NoFlyTimeResponse[]>(`${NO_FLY_TIME_ENDPOINT}/get`, { id: nftId }, undefined, false);
	}

	getNoFlyTimes(): Observable<NoFlyTimeResponse[]> {
		return this.http.post<NoFlyTimeResponse[]>(`${NO_FLY_TIME_ENDPOINT}/list`, { school_id: this.currentSchool.id }, undefined, false);
	}

	addNoFlyTime(startTime: string, endTime: string) {
		const noFlyTimeReqBody = {
			school_id: this.currentSchool.id,
			start_time: startTime,
			end_time: endTime,
		};
		return this.http.post(`${NO_FLY_TIME_ENDPOINT}/add`, noFlyTimeReqBody, undefined, false);
	}

	deleteNoFlyTime(id: number) {
		return this.http.post(`${NO_FLY_TIME_ENDPOINT}/delete`, { id }, undefined, false);
	}

	updateNoFlyTime(id: number, startTime: string, endTime: string) {
		const noFlyTimeReqBody = {
			id: id,
			start_time: startTime,
			end_time: endTime,
		};
		return this.http.post(`${NO_FLY_TIME_ENDPOINT}/update`, noFlyTimeReqBody, undefined, false);
	}

	broadcastNoFlyTimeChanges(): Observable<void> {
		return this.http.post(`${NO_FLY_TIME_ENDPOINT}/broadcast`, {}, undefined, false);
	}

	//checks if no fly time is active and returns the index of the active no fly time
	checkNoFlyTimes(times: NoFlyTime[]): number {
		const currentTime = moment().format('HH:mm');

		for (let i = 0; i < times.length; i++) {
			const noFlyTime = times[i];
			const startTime = this.momentize(noFlyTime.start_time);
			const endTime = this.momentize(noFlyTime.end_time);

			if (this.isTimeInRange(currentTime, startTime, endTime)) {
				return i;
			}
		}
		return -1;
	}

	formatTimeString(timeStr: string): string {
		const [hours, minutes] = timeStr.split(':').map(Number);
		const hour12: string = hours > 12 ? (hours - 12).toString() : hours === 0 ? '12' : hours.toString();
		const minutes12: string = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
		const amPm: string = hours >= 12 ? 'PM' : 'AM';
		return `${hour12}:${minutes12} ${amPm}`;
	}

	isTimeInRange(currentTime: string, startTime: string, endTime: string): boolean {
		return startTime <= currentTime && currentTime < endTime;
	}

	momentize(timeStr: string): string {
		return moment.utc(timeStr).format('HH:mm');
	}

	private startNoFlyTimeInterval(): void {
		function timeToNextMinute() {
			const now = new Date();
			const nextMinute = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 1, 0, 0);
			return nextMinute.getTime() - now.getTime();
		}

		const everyMinute$ = defer(() => timer(timeToNextMinute(), 60000)).pipe(
			switchMap(() => {
				const now = new Date();
				return of(now.toLocaleTimeString()); // Format the time as a string
			})
		);

		// Remit the no fly times to trigger checks for an active no fly time
		everyMinute$.pipe(takeUntil(this.destroy$)).subscribe(
			() => {
				const current = this.noFlyTimesSubject.value;
				this.noFlyTimesSubject.next(current);
			},
			(error) => {
				console.error('Error fetching no fly times during interval:', error);
			}
		);
	}
}
