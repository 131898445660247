import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FeatureFlagService, FLAGS } from 'app/services/feature-flag.service';
import { filter as _filter, cloneDeep, sortBy, unionBy } from 'lodash';
import { combineLatest, Observable, of, Subject, zip } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LocationVisibilityService } from '../create-hallpass-forms/main-hallpass--form/location-visibility.service';
import { DeviceDetection } from '../device-detection.helper';
import { Visibility } from '../location-table/location-table.component';
import { ColorProfile } from '../models/ColorProfile';
import { PassLimitInfo } from '../models/HallPassLimits';
import { Choice, Location } from '../models/Location';
import { PassLimit } from '../models/PassLimit';
import { Pinnable } from '../models/Pinnable';
import { User } from '../models/User';
import { HallPassesService } from '../services/hall-passes.service';
import { KeyboardShortcutsService } from '../services/keyboard-shortcuts.service';
import { LocationsService } from '../services/locations.service';
import { ScreenService } from '../services/screen.service';
import { UserService } from '../services/user.service';

export type LocationOverrideConfig = {
	location$: Observable<Location[]>;
	headerText: string;
	searchConfig: Record<string, string | number | boolean>;
};

/**
 * Responsible for displaying locations for selection. The following are some considerations for this component:
 *
 * - It should display a list of individual rooms for selection
 * - It should display a list of rooms + folders as pinnables
 * - It should accept additional locations to display in the desired format (single rooms or pinnables)
 * - Search queries should be made against the accepted search config
 * 	- While the search is active, the main view is inactive
 */
@Component({
	selector: 'app-location-table-v2',
	templateUrl: './location-table-v2.component.html',
	styleUrls: ['./location-table-v2.component.scss'],
})
export class LocationTableV2Component implements OnInit, OnDestroy, OnChanges {
	@Input() forKioskMode = false;
	@Input() placeholder: string;
	@Input() showStars: boolean;
	@Input() showPicture: boolean;
	@Input() showFavorites: boolean;
	@Input() showSuggested = false;
	@Input() forStaff: boolean;
	@Input() forLater: boolean;
	@Input() hasLocks: boolean;
	@Input() invalidLocation: string | number;
	@Input() noRightStar: boolean;
	@Input() inputWidth = '200px';
	@Input() isEdit = false;
	@Input() rightHeaderText = false;
	@Input() mergedAllRooms: boolean;
	@Input() dummyString = '';
	@Input() withMergedStars = true;
	@Input() searchExceptFavourites = false;
	@Input() allowOnStar = false;
	@Input() originLocation: Location;
	@Input() searchByTeacherName: boolean;
	@Input() currentPage: 'from' | 'to';
	@Input() updatedLocation$?: Observable<Location> | undefined;
	@Input() selectedStudents?: User[] = [];
	@Input() searchDisabled = false;
	@Input() set passLimitInfo(info: PassLimitInfo) {
		this._passLimitInfo = info;
		this.recalculateRestrictions();
	}
	@Input() showAsPinnables: boolean;
	@Input() favAsPinnables: boolean;
	@Input() loadPinnables = false;
	@Input() override: LocationOverrideConfig;
	@Input() hasSelectedPinnable: boolean;
	@Input() mainContentVisibility = false;
	@Input() folderSearch = false;
	@Input() suggestedPinnables: Pinnable[] = [];
	@Input() isFolderSearch = false;

	@Output() onSelect: EventEmitter<Location> = new EventEmitter();

	// for selecting an actual Pinnable (showAsPinnables is true)
	// this is for selecting an actual pinnable (location or folder)
	@Output() onSelectPinnable: EventEmitter<Pinnable> = new EventEmitter<Pinnable>();
	@Output() onStar: EventEmitter<Location> = new EventEmitter();
	@Output() onUpdate: EventEmitter<Location[]> = new EventEmitter<Location[]>();
	@Output() onLoaded: EventEmitter<boolean> = new EventEmitter();

	//For new pass creation folder select
	@Output() folderBack: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('item') currentItem: ElementRef;

	@Visibility()
	choices: Choice[] = [];
	allRooms: Choice[] = [];
	noChoices = false;

	@Visibility()
	starredChoices: Choice[] = [];
	favoritesLoaded: boolean;
	hideFavorites: boolean;
	pinnables: Record<string | number, Pinnable>;
	orderedPinnables: Pinnable[];
	favoritePinnables: Pinnable[] = [];
	locationChoicesLoaded: boolean;
	searchActive = false;
	pinnableDimensions = this.screenService.getPinnableDimensions();

	passLimits: PassLimit[] = [];

	private user: User;
	isStudent: boolean;
	private _passLimitInfo: PassLimitInfo;
	private showSpinner$: Observable<boolean>;
	loaded$: Observable<boolean>;
	loading$: Observable<boolean>;
	private destroy$: Subject<void> = new Subject<void>();

	closestRoom2 = false;
	closestRooms: Choice[];
	private categoriesToRemove = new Set<string>();
	private fullyDisabledCategories = new Set<string>();
	isAllClosestRoomsClosed: boolean;

	@HostListener('window:resize')
	private refreshPinnableDimensions() {
		this.pinnableDimensions = this.screenService.getPinnableDimensions();
	}

	constructor(
		private locationService: LocationsService,
		private pinnableService: HallPassesService,
		private shortcutsService: KeyboardShortcutsService,
		public screenService: ScreenService,
		private userService: UserService,
		private visibilityService: LocationVisibilityService,
		private cdr: ChangeDetectorRef,
		private featureFlagService: FeatureFlagService
	) {}

	get isMobile(): boolean {
		return DeviceDetection.isMobile();
	}

	private recalculateRestrictions() {
		if (!this.pinnables || !this.orderedPinnables?.length) {
			return;
		}

		if (this.showAsPinnables || this.loadPinnables) {
			this.orderedPinnables = this.orderedPinnables.map((pin) => {
				if (pin.location) {
					const pinFromMem = cloneDeep(this.pinnables[pin.location.id]);
					const newPin = cloneDeep(pin);
					newPin.location.restricted = this.isLocationRestricted(pinFromMem.location, pinFromMem.ignore_students_pass_limit);
					return newPin;
				} else {
					return pin;
				}
			});
			this.favoritePinnables = this.favoritePinnables.map((pin) => {
				if (pin.location) {
					const newPin = cloneDeep(pin);
					newPin.location.restricted = this.isLocationRestricted(pin.location, pin.ignore_students_pass_limit);
					return newPin;
				} else {
					return pin;
				}
			});
		}
		this.cdr.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!!changes.currentPage && changes.currentPage.currentValue !== changes.currentPage.previousValue && !!changes.currentPage.previousValue) {
			this.locationService
				.getFavoriteLocationsRequest()
				.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
				.subscribe((stars) => {
					this.processFavorites(stars);
				});
		}
	}

	ngOnInit(): void {
		if (this.isFolderSearch) {
			this.folderSearch = true;
		}
		if (this.featureFlagService.isFeatureEnabledV2(FLAGS.ClosestRoom2)) {
			this.closestRoom2 = true;
		}
		this.userService.userJSON$.pipe(takeUntil(this.destroy$), filter(Boolean), take(1)).subscribe((u: User) => {
			this.user = u;
			this.isStudent = User.fromJSON(this.user).isStudent();
		});

		const url = this.urlBuilder();

		let request$: Observable<Location[]>;
		if (this.override) {
			request$ = this.override.location$;
		} else {
			if (this.mergedAllRooms) {
				request$ = this.mergeLocations(url, this.withMergedStars);
			} else if (this.forKioskMode) {
				request$ = this.locationService.getLocationsWithConfigRequest(url);
			} else {
				request$ = this.locationService.getLocationsWithConfigRequest(url).pipe(filter((res) => !!res.length));
			}
		}

		this.pinnableService.loadedPinnables$
			.pipe(
				filter(Boolean),
				switchMap(() => {
					return this.pinnableService.pinnables$;
				}),
				switchMap((pins) => {
					this.pinnables = pins.reduce((acc, pinnable) => {
						if (pinnable.category) {
							return { ...acc, [pinnable.category]: pinnable };
						} else if (pinnable.location) {
							return { ...acc, [pinnable.location.id]: pinnable };
						}
					}, {});

					if (this.showAsPinnables || this.loadPinnables) {
						const filteredPins = pins
							.filter((p) => {
								if (!p.location) {
									return true;
								}
								if (!this.forKioskMode && this.forStaff) {
									// staff should always see every pin
									return true;
								}

								return this.visibilityService.filterByVisibility(p.location, this.getStudents());
							})
							.map((p) => {
								if (p.location) {
									p.location.restricted = this.isLocationRestricted(p.location, p.ignore_students_pass_limit);
								}
								return p;
							});
						this.orderedPinnables = filteredPins;
					}

					this.locationChoicesLoaded = true;
					this.onLoaded.emit(true);
					return this.locationService.pass_limits$;
				}),
				filter((passLimits) => passLimits.length > 0 && !!Object.keys(passLimits)),
				take(1),
				tap((passLimits) => {
					this.passLimits = Object.values(passLimits);
				}),
				switchMap(() => {
					return this.locationService.getFavoriteLocationsRequest();
				}),
				tap((stars) => {
					this.processFavorites(stars);
				}),
				switchMap(() => {
					return request$;
				}),
				filter((res: Location[]) => !!res.length),
				tap((locs: Location[]) => {
					// TODO Weird bug here when admin edits a room, it'll show in these results twice.
					// Super edge case, but we'll remove it so that it doesn't appear
					// twice in the list of rooms.
					locs = locs.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

					if (this.forKioskMode || !this.forStaff) {
						// Filter rooms that are not visible to students.
						locs = this.filterChoicesForLocationVisibility(locs, this.getStudents());
					}

					if (this.mergedAllRooms) {
						locs = this.filterChoicesForShowAsOrigin(locs);
					} else {
						locs = this.modifyLocationRestrictionForPassLimit(locs);
						if (this.currentPage === 'from') {
							locs = this.filterChoicesForShowAsOrigin(locs);
						}
					}
					const choices = this.parseLocations(locs);
					let AllChoices = choices;

					this.allRooms = choices;

					AllChoices = AllChoices.map((c) => {
						const pin = this.pinnables[c.id] || this.pinnables[c.category];
						c.restricted = this.isLocationRestricted(c, pin?.ignore_students_pass_limit);
						return c;
					});

					if (this.closestRoom2) {
						this.closestRooms = AllChoices.filter((choice) => {
							const cr = this.suggestedPinnables?.some((pinnable) => pinnable.location.id === choice.id);
							return cr;
						});

						AllChoices = AllChoices.filter((choice) => {
							const c = this.suggestedPinnables?.some((pinnable) => pinnable.location.id === choice.id);
							return !c;
						});
						if (this.isStudent || this.forKioskMode) {
							for (const room of this.closestRooms) {
								if (room.category) {
									this.categoriesToRemove.add(room.category);
								}
							}

							// Group closestRooms by category
							const categoryGroups: { [key: string]: Choice[] } = {};
							for (const room of this.closestRooms) {
								if (room.category) {
									if (!categoryGroups[room.category]) {
										categoryGroups[room.category] = [];
									}
									categoryGroups[room.category].push(room);
								}
							}

							// Check if all rooms in each category are disabled and add to fullyDisabledCategories
							for (const category in categoryGroups) {
								if (categoryGroups[category].every((room) => room.enable === false)) {
									this.fullyDisabledCategories.add(category);
								}
							}

							// Remove fullyDisabledCategories from categoriesToRemove
							for (const category of this.fullyDisabledCategories) {
								this.categoriesToRemove.delete(category);
							}

							this.choices = AllChoices.filter((choice) => !this.categoriesToRemove.has(choice.category));
						} else {
							this.choices = AllChoices;
						}

						this.closestRooms.sort((a, b) => a.title.localeCompare(b.title));

						this.isAllClosestRoomsClosed = this.closestRooms.every((room) => room.enable === false);

						this.cdr.detectChanges();
					} else {
						this.choices = AllChoices;
					}

					this.favoritePinnables = [];
					this.starredChoices = this.starredChoices
						.filter((c) => !this.categoriesToRemove.has(c.category))
						.map((c) => {
							const pin = cloneDeep(this.pinnables[c.id]) || cloneDeep(this.pinnables[c.category]);
							pin.location = c.normalizedLocation;
							pin.type = 'location';
							pin.title = c.title;
							pin.location.restricted = this.isLocationRestricted(pin.location, pin?.ignore_students_pass_limit);
							this.favoritePinnables.push(pin);
							c.restricted = this.isLocationRestricted(c, pin?.ignore_students_pass_limit);
							return c;
						});
					this.noChoices = !this.choices.length && !this.closestRooms.length;
					this.mainContentVisibility = true;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this.showSpinner$ = combineLatest(
			this.locationService.loadingLocations$,
			this.locationService.loadingFavoriteLocations$,
			(loc, fav) => loc && fav
		);
		this.loaded$ = combineLatest(this.locationService.loadedLocations$, this.locationService.loadedFavoriteLocations$, (loc, fav) => loc && fav);
		this.loaded$.subscribe({ next: (isLoaded) => this.onLoaded.emit(isLoaded) });
		this.loading$ = this.locationService.loadingLocations$;

		if (!this.locationService.focused.value) {
			this.locationService.focused.next(true);
		}

		this.shortcutsService.onPressKeyEvent$
			.pipe(
				filter(() => this.isMobile),
				pluck('key'),
				takeUntil(this.destroy$)
			)
			.subscribe((key) => {
				if (key[0] === 'enter') {
					if (this.choices.length === 1) {
						const wrap = this.currentItem.nativeElement.querySelector('.wrapper');
						(wrap as HTMLElement).click();
					}
					const element = document.activeElement;
					(element as HTMLElement).click();
				}
			});

		// this observable is triggered whenever a location is modified by an admin or teacher
		this.updatedLocation$
			?.pipe(
				tap((res: Location) => {
					let loc: Location = res;
					if (!(res instanceof Location)) {
						loc = Location.fromJSON(res);
					}
					const choice = this.parseLocations([loc]);
					this.updateOrAddChoices(choice[0]);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	private isLocationRestricted(loc: Location, ignoreLimits: boolean) {
		return loc.restricted || (!ignoreLimits && this._passLimitInfo?.showPasses && this._passLimitInfo.current === 0);
	}

	private modifyLocationRestrictionForPassLimit(choices: Location[]): Location[] {
		return choices.map((loc) => {
			let pinnable: Pinnable;
			if (this.pinnables && this.pinnables[loc.id]) {
				pinnable = this.pinnables[loc.id];
			} else if (this.pinnables && this.pinnables[loc.category]) {
				pinnable = this.pinnables[loc.category];
			}

			const ignoreStudentsPassLimit = pinnable?.ignore_students_pass_limit ?? false;
			loc.restricted = this.isLocationRestricted(loc, pinnable?.ignore_students_pass_limit);
			return loc;
		});
	}

	private filterChoicesForLocationVisibility(choices: Location[], students: User[]): Location[] {
		return choices.filter((loc) => {
			return this.visibilityService.filterByVisibility(loc, students);
		});
	}

	getStudents(): User[] {
		return this.selectedStudents.length ? this.selectedStudents : [this.user];
	}

	private filterChoicesForShowAsOrigin(choices: Location[]): Location[] {
		if (this.currentPage === 'to') {
			return choices;
		}
		return choices.filter((loc) => {
			if (this.pinnables && this.pinnables[loc.id]) {
				const pinnable = this.pinnables[loc.id];
				if (pinnable.show_as_origin_room) {
					return loc;
				}
			}
			// choice with a category is within a pinnable folder
			else if (this.pinnables && loc.category !== null) {
				const pinnable = this.pinnables[loc.category];
				if (!pinnable || pinnable.show_as_origin_room) {
					return loc;
				}
			}
		});
	}

	private parseLocations(choices: Location[]): Choice[] {
		return choices.map((choice: Location) => {
			const choiceData: Partial<Choice> = {
				id: choice.id,
				passLimit: this.getPassLimit(choice),
				disabledToolTip: this.getDisabledTooltip(choice),
				isValidLocation: this.isValidLocation(choice.id),
				normalizedLocation: this.normalizeLocations(choice),
				roomIsHidden: this.checkRoomIsHidden(choice),
				isSelected: this.isSelected(choice),
			};
			const pin = this.pinnables[choice.id] || this.pinnables[choice.category];
			choice.restricted = this.isLocationRestricted(choice, pin?.ignore_students_pass_limit);
			const icon = this.getPinnableIconFromChoice(choice);
			const colorProfile = this.getPinnableColorProfileFromChoice(choice);
			if (icon) {
				choiceData.roomIcon = icon;
			}
			if (colorProfile) {
				choiceData.colorProfile = colorProfile;
			}
			return Object.assign(choiceData, choice) as Choice;
		});
	}

	private updateOrAddChoices(choice: Choice): void {
		const choiceIndex: number = this.choices.findIndex((c) => c.id.toString() === choice.id.toString());
		if (choiceIndex !== -1) {
			this.choices[choiceIndex] = choice;
		} else {
			this.choices.push(choice);
		}

		if (!choice.starred) {
			return;
		}
		const starredChoiceIndex: number = this.choices.findIndex((c) => c.id.toString() === choice.id.toString());
		if (starredChoiceIndex !== -1) {
			this.starredChoices[starredChoiceIndex] = choice;
		} else {
			this.starredChoices.push(choice);
		}
	}

	normalizeLocations(loc: Location): Location {
		if (this.pinnables && this.currentPage !== 'from') {
			if (loc.category) {
				if (!this.pinnables[loc.category] || !this.pinnables[loc.category].gradient_color) {
					loc.gradient = '#7f879d, #7f879d';
				} else {
					loc.gradient = this.pinnables[loc.category].gradient_color;
				}
			} else {
				if (!this.pinnables[loc.id] || !this.pinnables[loc.id].gradient_color) {
					loc.gradient = '#7f879d, #7f879d';
				} else {
					loc.gradient = this.pinnables[loc.id].gradient_color;
				}
			}
		}

		return loc;
	}

	getPinnableIconFromChoice(choice: Location): string {
		let pinnable: Pinnable;
		if (this.pinnables && this.pinnables[choice.id]) {
			pinnable = this.pinnables[choice.id];
		} else if (this.pinnables && this.pinnables[choice.category]) {
			pinnable = this.pinnables[choice.category];
		}
		return pinnable?.icon;
	}

	getPinnableColorProfileFromChoice(choice: Location): ColorProfile {
		let pinnable: Pinnable;
		if (this.pinnables && this.pinnables[choice.id]) {
			pinnable = this.pinnables[choice.id];
		} else if (this.pinnables && this.pinnables[choice.category]) {
			pinnable = this.pinnables[choice.category];
		}
		return pinnable?.color_profile;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	onSearch(search: string): void {
		search = search.toLowerCase();
		if (search !== '') {
			const url = this.urlBuilder(search);
			const searchedLocsByRoomName = this.override
				? this.locationService.getLocationsWithConfigV2({ ...this.override.searchConfig, search })
				: this.locationService.getLocationsWithConfig(url).pipe(map((pagedLocs) => pagedLocs.results));
			const searchedLocsByTeacherName = this.searchByTeacherName
				? this.locationService.locations$.pipe(
						take(1),
						map((locations) => {
							return locations.filter((location: Location) => {
								return (location.teachers as User[]).find((teacher) => teacher.display_name.toLowerCase().includes(search));
							});
						})
				  )
				: of<Location[]>([]);
			zip(searchedLocsByRoomName, searchedLocsByTeacherName)
				.pipe(map(([searchedByRoom, searchedByTeacher]) => unionBy<Location>(searchedByRoom, searchedByTeacher, 'id')))
				.subscribe((p) => {
					const choices: Location[] = this.filterChoicesForShowAsOrigin(p);
					const parsedLocations: Choice[] = this.parseLocations(choices);
					this.hideFavorites = true;
					const filtFevLoc = _filter(this.starredChoices, (item) => {
						return item.title.toLowerCase().includes(search);
					});

					let AllChoices =
						this.searchExceptFavourites && !this.forKioskMode
							? [...this.filterResults(parsedLocations)]
							: [...filtFevLoc, ...this.filterResults(parsedLocations)];

					if (this.folderSearch) {
						AllChoices = AllChoices.filter((choice) => choice.category == this.override.searchConfig.category);
					}

					this.searchActive = true;
					this.choices = [];
					this.closestRooms = [];

					this.searchedRooms(AllChoices);

					this.noChoices = !this.choices.length && !this.closestRooms.length;
				});
		} else {
			const AllChoices = this.parseLocations(this.allRooms);
			this.searchActive = true;
			this.choices = [];
			this.closestRooms = [];

			this.searchedRooms(AllChoices);

			this.hideFavorites = false;
			this.noChoices = !this.choices.length && !this.closestRooms.length;
			this.searchActive = false;
		}
	}

	private searchedRooms(AllChoices: Choice[]): void {
		// Closest rooms filtering with suggestedPinnables
		this.closestRooms = AllChoices.filter((choice) => {
			const match = this.suggestedPinnables?.some((pinnable) => pinnable.location.id === choice.id);
			return match;
		});

		this.cdr.detectChanges();

		if ((this.isStudent || this.forKioskMode) && this.closestRoom2) {
			AllChoices = AllChoices.filter((choice) => !this.categoriesToRemove.has(choice.category));
		}

		// Choices filtering with suggestedPinnables
		this.choices = AllChoices.filter((choice) => {
			const c = this.suggestedPinnables?.some((pinnable) => pinnable.location.id === choice.id);
			return !c;
		});
	}

	private isValidLocation(locationId: number): boolean {
		if (+locationId === +this.invalidLocation) {
			return false;
		}

		if (this.forStaff && !this.forKioskMode) {
			return true;
		}

		return !!this.passLimits.find((pl) => pl.id === locationId);
	}

	private mergeLocations(url: string, withStars: boolean): Observable<Location[]> {
		return zip(this.locationService.getLocationsWithConfigRequest(url), this.locationService.getFavoriteLocationsRequest()).pipe(
			takeUntil(this.destroy$),
			map(([rooms, favorites]: [Location[], Location[]]) => {
				if (withStars) {
					const locs: Location[] = sortBy([...rooms, ...favorites], (item) => {
						return item.title.toLowerCase();
					});
					return locs;
				} else {
					return rooms;
				}
			})
		);
	}

	private filterResults(results: Choice[]): Choice[] {
		return results.filter((felement) => {
			return (
				this.starredChoices.findIndex((ielement) => {
					return ielement.id === felement.id;
				}) < 0
			);
		});
	}

	choiceSelected(choice: Choice): void {
		const passLimit = this.passLimits.find((pl) => pl.id === choice.id);
		if (passLimit) {
			choice['numberOfStudentsInRoom'] = passLimit.to_count;
		}
		this.locationService.focused.next(false);
		this.onSelect.emit(choice);
	}

	private checkRoomIsHidden(loc: Location): boolean {
		if (this.forKioskMode) {
			return this.isValidLocation(loc.id);
		}
		return true;
	}

	private isSelected(loc: Location): boolean {
		return !!this.starredChoices.find((item) => item.id === loc.id);
	}

	star(event: Choice): void {
		if (!this.isEdit) {
			return this.choiceSelected(event);
		}
		if (event.starred) {
			this.addLoc(event, this.starredChoices);
		} else {
			this.removeLoc(event, this.starredChoices);
		}
		this.onSearch('');
		this.onStar.emit(event as Location);
	}

	private urlBuilder(search?: string) {
		let url = 'v1/locations?limit=500&visibility_ids_only=true' + (this.showFavorites ? '&starred=false' : '');

		if (search) {
			url += '&search=' + search;
		}

		return url;
	}

	private addLoc(choice: Choice, array: Location[]): void {
		if (!array.includes(choice)) {
			array.push(choice);
		}
	}

	private removeLoc(loc: Choice, array: Choice[]): void {
		const index = array.findIndex((element) => element.id === loc.id);
		if (index > -1) {
			array.splice(index, 1);
		}
	}

	private getDisabledTooltip(loc: Location): boolean {
		return this.invalidLocation === loc.id;
	}

	private getPassLimit(loc: Location): PassLimit {
		if (!loc) {
			return null;
		}

		return this.passLimits ? this.passLimits.find((pl) => pl.id === loc.id) : null;
	}

	isValidPinnable(pinnable: Pinnable) {
		// === and ids are dangerous as ids are numeric strings or numbers
		// using == will pose its own dangers
		// if (pinnable.location.id == this.location.id)
		// as we know ids are numbers we cast them to pure numbers

		if (!pinnable.location) {
			return true;
		}

		if (+pinnable?.location?.id === +this.invalidLocation) {
			return false;
		}

		if (this.forStaff && !this.forKioskMode) return true;

		const forNowCondition =
			!this.forLater &&
			pinnable.location.restricted &&
			pinnable.location.request_mode === 'all_teachers_in_room' &&
			pinnable.location.request_send_origin_teachers &&
			!this.originLocation.teachers.length;

		const forLaterCondition =
			this.forLater &&
			pinnable.location.scheduling_restricted &&
			pinnable.location.scheduling_request_mode === 'all_teachers_in_room' &&
			pinnable.location.scheduling_request_send_origin_teachers &&
			!this.originLocation.teachers.length;

		return !(forNowCondition || forLaterCondition);
	}

	checkPinnable(forTeacherRooms: boolean, pinnable: Pinnable): boolean {
		// hide kiosk mode room
		if (!forTeacherRooms) {
			if (this.forKioskMode) {
				return pinnable.location && this.originLocation ? this.isValidPinnable(pinnable) : true;
			} else {
				return true;
			}
		} else {
			if (this.forKioskMode) {
				return pinnable.location && this.originLocation ? pinnable.location.id != this.originLocation.id : true;
			} else {
				return true;
			}
		}
	}

	private pinnableSelected(pin: Pinnable): void {
		if (!this.hasSelectedPinnable) {
			this.onSelectPinnable.emit(pin);
		}
	}

	folderBackClicked() {
		this.folderBack.emit();
	}

	processFavorites(stars: Location[]) {
		if (stars?.length) {
			const starredChoices: Location[] = stars.map((val) => Location.fromJSON(val));
			this.starredChoices = this.parseLocations(starredChoices);
			// don't filter for show as origin if this is the favorites form
			if (this.showFavorites && this.currentPage === 'from') {
				this.starredChoices = this.parseLocations(this.filterChoicesForShowAsOrigin(starredChoices));
				this.choices = [...this.starredChoices, ...this.choices].sort((a, b) => Number(a.id) - Number(b.id));
			}
			this.favoritesLoaded = true;
			this.mainContentVisibility = true;
		}
	}
}
