import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Invitation } from '../../../../../models/Invitation';
import * as invitationsActions from '../actions';
import { IInvitationsState } from '../states';

export const adapter: EntityAdapter<Invitation> = createEntityAdapter<Invitation>();

const invitationsInitialStates: IInvitationsState = adapter.getInitialState({
	loading: false,
	loaded: false,
});

const reducer = createReducer(
	invitationsInitialStates,
	on(invitationsActions.getInvitations, (state) => ({ ...state, loading: true, loaded: false })),
	on(invitationsActions.getInvitationsSuccess, (state, { invitations }) => {
		return adapter.setAll(invitations, { ...state, loading: false, loaded: true });
	})
);

export function invitationsReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
