import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { HallPass } from '../../../../../models/HallPass';
import * as fromLocationActions from '../actions';
import { IFromLocationState } from '../states';

export const adapter: EntityAdapter<HallPass> = createEntityAdapter<HallPass>();

export const fromLocationPassesInitialState: IFromLocationState = adapter.getInitialState({
	loading: false,
	loaded: false,
});

const reducer = createReducer(
	fromLocationPassesInitialState,
	on(fromLocationActions.getFromLocationPasses, (state) => ({ ...state, loading: true, loaded: false })),
	on(fromLocationActions.getFromLocationPassesSuccess, (state, { fromLocationPasses }) => {
		return adapter.setAll(fromLocationPasses, { ...state, loading: false, loaded: true });
	})
);

export function fromLocationPassesReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
