import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SchoolActivityAttendee } from '../../../services/school-activity.service';
import {
	BulkRemoveAttendeeAndSignUpAction,
	BulkSignOutForActivitySuccessAction,
	BulkSignUpForActivitySuccessAction,
	GetAttendeesForInstanceSuccessAction,
	RemoveAttendeeAndSignUpAction,
	RemoveAttendeesAction,
	SignUpForActivitySuccessAction,
} from '../actions/school-activity-attendees.actions';

export const schoolActivityAttendeesInitialState: EntityState<SchoolActivityAttendee> = {
	ids: [],
	entities: {},
};

export const schoolActivityAttendeesAdapter: EntityAdapter<SchoolActivityAttendee> = createEntityAdapter<SchoolActivityAttendee>();

const reducer = createReducer(
	schoolActivityAttendeesInitialState,
	on(GetAttendeesForInstanceSuccessAction, (state, { attendees }) => schoolActivityAttendeesAdapter.setAll(attendees, state)),
	on(SignUpForActivitySuccessAction, (state, { attendee }) => schoolActivityAttendeesAdapter.upsertOne(attendee, state)),
	on(BulkSignUpForActivitySuccessAction, (state, { attendees }) => schoolActivityAttendeesAdapter.upsertMany(attendees, state)),
	on(BulkSignOutForActivitySuccessAction, (state, { attendeeIds }) => schoolActivityAttendeesAdapter.removeMany(attendeeIds, state)),
	on(RemoveAttendeesAction, (state) => schoolActivityAttendeesAdapter.removeAll(state)),
	on(RemoveAttendeeAndSignUpAction, (state, { attendeeId, req, instance }) => schoolActivityAttendeesAdapter.removeOne(attendeeId, state)),
	on(BulkRemoveAttendeeAndSignUpAction, (state, { attendeeIds, req, instance }) => schoolActivityAttendeesAdapter.removeMany(attendeeIds, state))
);

export function schoolActivityAttendeesReducer(state: EntityState<SchoolActivityAttendee>, action: Action) {
	return reducer(state, action);
}
