import { Injectable, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolTipDataType } from 'app/backpack/tooltip/tooltip.component';
import * as moment from 'moment';

import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CalendarEventResponse } from '../models/CalendarEvent';
import { HallPass } from '../models/HallPass';
import { Invitation } from '../models/Invitation';
import { Request } from '../models/Request';
import { HttpService } from './http-service';
import { LiveUpdateService } from './live-update.service';
import { AttendeeData, SchoolActivityService } from './school-activity.service';

export type SchoolTermType = 'SCHOOL_YEAR' | 'TERM';

export type TooltipDateInfo = {
	date: string; // in the format 'YYYY-MM-DD'
	disabled: boolean;
	tooltipContent?: ToolTipDataType;
	// this will be used to exclude dates in calendar picker
	exclusionId?: string;
};

export type TooltipDateCreationParams = {
	firstDate: string;
	lastDate: string;
	disabledDates: DisabledDates[];
};
export type DisabledDates = {
	term_name: string;
	exclusionId: string;
	start_date: string;
	end_date: string;
};
@Injectable({
	providedIn: 'root',
})
export class CalendarService implements OnDestroy {
	// this map stores pending declineable scheduled passes sent by teachers to students so we can
	// update them when they've been missed by the student
	pendingScheduledInvitiations = new Map<number, Invitation>();

	// this map stores pending schedules passes sent by students to teachers so we can
	// update them when they've been missed by the teacher
	pendingPassRequests = new Map<number, Request>();

	// this map stores today's pending recurring schedules passes for teachers because
	// so we can update them to active or overtime
	pendingRecurringPassesForToday = new Map<number, HallPass>();

	// this map stores active schedules passes for teachers because
	// they should show on their calendar when they've gone overtime
	activeScheduledHallPasses = new Map<number, HallPass>();

	timeLeftForActiveScheduledHallPasses = new Map<number, string>();

	calendarEventIds = new Map<number, null>();

	private destroy$ = new Subject();
	weekDateChanged$ = this.activatedRoute.queryParams.pipe(
		filter((params) => params['start_date'] !== undefined), // Check if 'start_date' exists in params
		distinctUntilChanged((prev, curr) => prev['start_date'] === curr['start_date']), // Check if start_date has changed
		takeUntil(this.destroy$)
	);
	dateChanged$ = this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$));

	showLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	tooltipDates$: BehaviorSubject<TooltipDateInfo[]> = new BehaviorSubject<TooltipDateInfo[]>([]);

	constructor(
		private http: HttpService,
		private liveUpdateService: LiveUpdateService,
		private activatedRoute: ActivatedRoute,
		private schoolActivityService: SchoolActivityService,
		private router: Router,
		private domSanitizer: DomSanitizer
	) {}

	getCalendarEventsForStudent(userId: number, startDate: string, endDate: string): Observable<CalendarEventResponse[]> {
		return this.http.post<CalendarEventResponse[]>(
			`v2/calendarv2/GetCalendarEventsByStudentV2`,
			{
				student_id: userId,
				start_time: startDate,
				end_time: endDate,
			},
			undefined,
			false
		);
	}

	getCalendarEventsForTeacher(userId: number, startDate: string, endDate: string): Observable<CalendarEventResponse[]> {
		return this.http.post<CalendarEventResponse[]>(
			`v2/calendarv2/GetCalendarEventsByTeacherV2`,
			{
				teacher_id: userId,
				start_time: startDate,
				end_time: endDate,
			},
			undefined,
			false
		);
	}

	isForNowPass(hallPass: HallPass): boolean {
		// if pass has same start and created time, it's a for now pass
		if (
			hallPass.start_time.getDay() === hallPass.created.getDay() &&
			hallPass.start_time.getMonth() === hallPass.created.getMonth() &&
			hallPass.start_time.getFullYear() === hallPass.created.getFullYear() &&
			hallPass.start_time.getHours() === hallPass.created.getHours() &&
			hallPass.start_time.getMinutes() === hallPass.created.getMinutes()
		) {
			return true;
		}
		return false;
	}

	private createRequestPipeForTeacher(userId: number, locationIds: number[]) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				map((data) => {
					if (Array.isArray(data)) {
						return data.map((pr) => {
							if (!pr.teachers) {
								pr.teachers = [];
							}
							return Request.fromJSON(pr);
						});
					} else {
						if (!data.teachers) {
							data.teachers = [];
						}
						return Request.fromJSON(data);
					}
				}),
				filter((pr) => {
					console.log('filter pr', pr);
					if (Array.isArray(pr)) {
						return (
							pr.filter((p) => locationIds.includes(p.origin.id) || locationIds.includes(p.destination.id) || p.teachers.some((t) => t.id === userId))
								.length > 0
						);
					} else {
						return locationIds.includes(pr.origin.id) || locationIds.includes(pr.destination.id) || pr.teachers.some((t) => t.id === userId);
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForTeacher', e);
					return originalObs;
				})
			);
	}

	private createHallPassPipeForTeacher(userId: number, locationIds: number[]) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				map((data) => {
					if (Array.isArray(data)) {
						return data.map((hp) => HallPass.fromJSON(hp));
					} else {
						return HallPass.fromJSON(data);
					}
				}),
				filter((hp) => {
					if (Array.isArray(hp)) {
						return (
							hp.filter(
								(p) =>
									(locationIds.includes(p.origin.id) || locationIds.includes(p.destination.id) || p.issuer.id === userId) &&
									// remove "for now" passes
									!this.isForNowPass(p) &&
									// remove flex activity hall passes
									!p.activity_instance_id
							).length > 0
						);
					} else {
						return (
							(locationIds.includes(hp.origin.id) || locationIds.includes(hp.destination.id) || hp.issuer.id === userId) &&
							// remove "for now" passes
							!this.isForNowPass(hp) &&
							// remove flex activity hall passes
							!hp.activity_instance_id
						);
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForTeacher', e);
					return originalObs;
				})
			);
	}

	private createPassInvitationPipeForTeacher(userId: number, locationIds: number[]) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				map((data) => {
					if (Array.isArray(data)) {
						return data.map((inv) => {
							return Invitation.fromJSON(inv);
						});
					} else {
						return Invitation.fromJSON(data);
					}
				}),
				filter((inv) => {
					console.log('filter inv', inv);
					if (Array.isArray(inv)) {
						return inv.filter((p) => locationIds.includes(p.destination.id) || p.issuer.id === userId).length > 0;
					} else {
						return locationIds.includes(inv.destination.id) || inv.issuer.id === userId;
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForTeacher', e);
					return originalObs;
				})
			);
	}

	private createActivityPipeForTeacher(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				filter((activity) => {
					console.log('filter activity', activity);
					if (Array.isArray(activity)) {
						return activity.filter((p) => p.user_id === userId).length > 0;
					} else {
						return activity.user_id === userId;
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForTeacher', e);
					return originalObs;
				})
			);
	}

	private createActivityInstancePipeForTeacher(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				switchMap((instance) => {
					const activityId = Array.isArray(instance) ? instance[0].activity_id : instance.activity_id;
					return this.schoolActivityService.activity$(activityId).pipe(
						filter((activity) => !!activity),
						take(1),
						map((activity) => {
							if (activity!.managers?.some((m) => m.id === userId)) {
								return instance;
							}
							return null;
						})
					);
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForTeacher', e);
					return originalObs;
				})
			);
	}
	watchCalendarEventsForTeacher(userId: number, locationIds: number[]): Observable<any> {
		const activityPipe = this.createActivityPipeForTeacher(userId);
		const instancePipe = this.createActivityInstancePipeForTeacher(userId);
		const hallPassPipe = this.createHallPassPipeForTeacher(userId, locationIds);
		const requestPipe = this.createRequestPipeForTeacher(userId, locationIds);
		const invitationPipe = this.createPassInvitationPipeForTeacher(userId, locationIds);
		return this.liveUpdateService.isConnected$.pipe(
			filter(Boolean),
			distinctUntilChanged(),
			switchMap(() => {
				return merge(
					this.liveUpdateService.listen('hall_pass.create').pipe(hallPassPipe),
					this.liveUpdateService.listen('hall_pass.delete').pipe(hallPassPipe),
					this.liveUpdateService.listen('hall_pass.start').pipe(hallPassPipe),
					this.liveUpdateService.listen('hall_pass.end').pipe(hallPassPipe),
					this.liveUpdateService.listen('hall_pass.cancel').pipe(hallPassPipe),

					this.liveUpdateService.listen('pass_request.create').pipe(requestPipe),
					this.liveUpdateService.listen('pass_request.delete').pipe(requestPipe),
					this.liveUpdateService.listen('pass_request.accept').pipe(requestPipe),
					this.liveUpdateService.listen('pass_request.deny').pipe(requestPipe),
					this.liveUpdateService.listen('pass_request.cancel').pipe(requestPipe),

					this.liveUpdateService.listen('pass_invitation.create').pipe(invitationPipe),
					this.liveUpdateService.listen('pass_invitation.delete').pipe(invitationPipe),
					this.liveUpdateService.listen('pass_invitation.accept').pipe(hallPassPipe), // backend returns a hall pass for this event
					this.liveUpdateService.listen('pass_invitation.deny').pipe(invitationPipe),
					this.liveUpdateService.listen('pass_invitation.cancel').pipe(invitationPipe),

					this.liveUpdateService.listen('flex_period.update').pipe(map((pe) => pe.data)),
					this.liveUpdateService.listen('school_activity.create').pipe(activityPipe),
					this.liveUpdateService.listen('school_activity.update').pipe(map((pe) => pe.data)),
					this.liveUpdateService.listen('school_activity.delete').pipe(activityPipe),
					this.liveUpdateService.listen('school_activity_instances.create').pipe(instancePipe),
					this.liveUpdateService.listen('school_activity_instances.update').pipe(instancePipe),
					this.liveUpdateService.listen('school_activity_instances.delete').pipe(instancePipe)
				);
			})
		);
	}

	watchCalendarEventsForStudent(userId: number): Observable<any> {
		const attendeeCreationPipe = this.createAttendeeCreationPipeForStudent(userId);
		const attendeeDeletionPipe = this.createAttendeeDeletionPipeForStudent(userId);
		const hallPassPipe = this.createHallPassPipeForStudent(userId);
		const requestPipe = this.createRequestPipeForStudent(userId);
		const invitationPipe = this.createPassInvitationPipeForStudent(userId);
		return this.liveUpdateService.isConnected$.pipe(
			filter(Boolean),
			distinctUntilChanged(),
			switchMap(() => {
				return merge(
					this.liveUpdateService.listen('hall_pass.create').pipe(hallPassPipe),
					this.liveUpdateService.listen('hall_pass.delete').pipe(hallPassPipe),
					this.liveUpdateService.listen('hall_pass.start').pipe(hallPassPipe),
					this.liveUpdateService.listen('hall_pass.end').pipe(hallPassPipe),
					this.liveUpdateService.listen('hall_pass.cancel').pipe(hallPassPipe),

					this.liveUpdateService.listen('pass_request.create').pipe(requestPipe),
					this.liveUpdateService.listen('pass_request.delete').pipe(requestPipe),
					this.liveUpdateService.listen('pass_request.accept').pipe(requestPipe),
					this.liveUpdateService.listen('pass_request.deny').pipe(requestPipe),
					this.liveUpdateService.listen('pass_request.cancel').pipe(requestPipe),

					this.liveUpdateService.listen('pass_invitation.create').pipe(invitationPipe),
					this.liveUpdateService.listen('pass_invitation.delete').pipe(invitationPipe),
					this.liveUpdateService.listen('pass_invitation.accept').pipe(hallPassPipe), // backend returns a hall pass for this event
					this.liveUpdateService.listen('pass_invitation.deny').pipe(invitationPipe),
					this.liveUpdateService.listen('pass_invitation.cancel').pipe(invitationPipe),

					this.liveUpdateService.listen('school_activity.create'),
					this.liveUpdateService.listen('school_activity.update'),
					this.liveUpdateService.listen('school_activity.delete'),
					this.liveUpdateService.listen('school_activity_attendees.create').pipe(attendeeCreationPipe),
					this.liveUpdateService.listen('school_activity_attendees.delete').pipe(attendeeDeletionPipe),
					this.liveUpdateService.listen('school_activity_instances.delete'),

					this.liveUpdateService.listen('flex_period.update')
				);
			})
		);
	}

	private createHallPassPipeForStudent(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				map((data) => {
					if (Array.isArray(data)) {
						return data.map((hp) => HallPass.fromJSON(hp));
					} else {
						return HallPass.fromJSON(data);
					}
				}),
				filter((hp) => {
					console.log('filter hp', hp);
					if (Array.isArray(hp)) {
						return hp.filter((p) => p.student.id === userId && !p.activity_instance_id).length > 0;
					} else {
						return hp.student.id === userId && !hp.activity_instance_id;
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForStudent', e);
					return originalObs;
				})
			);
	}

	private createRequestPipeForStudent(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				map((data) => {
					if (Array.isArray(data)) {
						return data.map((pr) => {
							// todo sometimes the backend sends the event without teachers
							// figure out if this workaround is ok or if backend should be updated
							if (!pr.teachers) {
								pr.teachers = [];
							}
							return Request.fromJSON(pr);
						});
					} else {
						// todo sometimes the backend sends the event without teachers
						// figure out if this workaround is ok or if backend should be updated
						if (!data.teachers) {
							data.teachers = [];
						}
						return Request.fromJSON(data);
					}
				}),
				filter((pr) => {
					console.log('filter pr', pr);
					if (Array.isArray(pr)) {
						return pr.filter((p) => p.student.id === userId).length > 0;
					} else {
						return pr.student.id === userId;
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForStudent', e);
					return originalObs;
				})
			);
	}

	private createPassInvitationPipeForStudent(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				map((pe) => pe.data),
				map((data) => {
					if (Array.isArray(data)) {
						return data.map((inv) => {
							return Invitation.fromJSON(inv);
						});
					} else {
						return Invitation.fromJSON(data);
					}
				}),
				filter((inv) => {
					console.log('filter inv', inv);
					if (Array.isArray(inv)) {
						return inv.filter((p) => p.student.id === userId).length > 0;
					} else {
						return inv.student.id === userId;
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForStudent', e);
					return originalObs;
				})
			);
	}

	private createAttendeeCreationPipeForStudent(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				filter((pe) => {
					if (Array.isArray(pe.data)) {
						return pe.data.filter((p: AttendeeData) => p.user_ids?.includes(userId)).length > 0;
					} else {
						return pe.data.user_ids?.includes(userId);
					}
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForStudent', e);
					return originalObs;
				})
			);
	}
	private createAttendeeDeletionPipeForStudent(userId: number) {
		return (source: Observable<any>) =>
			source.pipe(
				filter((pe) => !!pe),
				filter((pe, data) => {
					return pe.data.user_id === userId;
				}),
				catchError((e, originalObs) => {
					console.log('Error in watchCalendarEventsForStudent', e);
					return originalObs;
				})
			);
	}

	// This compares arrays of objects and determines if they all contain the same IDs
	// Used in distinctUntilChanged() RXJS operator for calendar.
	same(prev: any[], next: any[]): boolean {
		if (prev?.length != next?.length) return false;
		for (let i = 0; i < prev.length; i++) {
			if (!prev.some((x) => x.id == next[i].id)) return false;
			if (!next.some((x) => x.id == prev[i].id)) return false;
		}
		return true;
	}

	adjustDateForDaylightSavings(dateToAdjust: Date): Date {
		// daylight savings sets dates one hour ahead

		const now = moment();
		const dateToEvaluate = moment(dateToAdjust);

		if (now.isDST()) {
			// if we are in daylight savings
			if (dateToEvaluate.isDST()) {
				// date to evaluate is in daylight savings - do no adjustments
				return dateToAdjust;
			} else {
				// date to evaluate is in standard time - adjust it one hour ahead
				dateToAdjust.setHours(dateToAdjust.getHours() + 1);
			}
		} else {
			// we are not in standard time
			if (dateToEvaluate.isDST()) {
				// date to evaluate is in daylight savings time - adjust it one hour back
				dateToAdjust.setHours(dateToAdjust.getHours() - 1);
			} else {
				// date to evaluate is standard time - do no adjustments
				return dateToAdjust;
			}
		}
		return dateToAdjust;
	}

	clearData(): void {
		this.activeScheduledHallPasses.clear();
		this.timeLeftForActiveScheduledHallPasses.clear();
		this.pendingPassRequests.clear();
		this.pendingScheduledInvitiations.clear();
		this.calendarEventIds.clear();
	}

	addCalendarEventId(id: number): void {
		this.calendarEventIds.set(id, null);
	}

	removeActiveHallPassById(id: number): void {
		this.activeScheduledHallPasses.delete(id);
	}

	addActiveHallPassById(id: number, hallPass: HallPass): void {
		this.activeScheduledHallPasses.set(id, hallPass);
	}

	getActiveHallPassById(id: number): HallPass | undefined {
		return this.activeScheduledHallPasses.get(id);
	}

	removePendingRecurringPassForTodayById(id: number): void {
		this.pendingRecurringPassesForToday.delete(id);
	}

	addPendingRecurringPassForTodayById(id: number, hallPass: HallPass): void {
		this.pendingRecurringPassesForToday.set(id, hallPass);
	}

	getPendingRecurringPassForTodayById(id: number): HallPass | undefined {
		return this.pendingRecurringPassesForToday.get(id);
	}

	removeTimeLeftForActiveScheduledHallPasses(id: number): void {
		this.timeLeftForActiveScheduledHallPasses.delete(id);
	}

	addTimeLeftForActiveScheduledHallPasses(id: number, timeLeft: string): void {
		this.timeLeftForActiveScheduledHallPasses.set(id, timeLeft);
	}

	removePendingPassInvitationById(id: number): void {
		this.pendingScheduledInvitiations.delete(id);
	}

	addPendingPassInvitationById(id: number, passInvitation: Invitation): void {
		this.pendingScheduledInvitiations.set(id, passInvitation);
	}

	removePendingPassRequestById(id: number): void {
		this.pendingPassRequests.delete(id);
	}

	addPendingPassRequestById(id: number, passRequest: Request): void {
		this.pendingPassRequests.set(id, passRequest);
	}

	applyBackground(event: MouseEvent, color: string): void {
		(event.target as HTMLElement).style.background = color;
	}

	removeBackground(event: MouseEvent): void {
		(event.target as HTMLElement).style.background = '';
	}

	removeQueryParam(paramToRemove: string) {
		// Use snapshot to get the current query params
		const currentParams = this.activatedRoute.snapshot.queryParams;
		if (!Object.prototype.hasOwnProperty.call(currentParams, paramToRemove)) {
			return;
		}

		const newParams = { ...currentParams };

		// Delete the parameter you want to remove
		delete newParams[paramToRemove];

		// Update the URL with the new set of query params
		this.updateQueryParams(newParams);
	}

	private updateQueryParams(params: any) {
		const url = this.router
			.createUrlTree(['main', 'passes-calendar'], {
				queryParams: params,
			})
			.toString();
		this.router.navigateByUrl(url);
	}

	private getTooltipDate(date: string, disabled: boolean, exclusionId: string, tooltipContent?: ToolTipDataType): TooltipDateInfo {
		return {
			date: date,
			disabled: disabled,
			exclusionId: exclusionId,
			tooltipContent: tooltipContent,
		};
	}

	private getWithinExistingTermTooltipContent(termName: string, startDate: string, endDate: string): ToolTipDataType {
		const sanitizedTermName = this.domSanitizer.sanitize(SecurityContext.HTML, termName);
		const sanitizedStartDate = this.domSanitizer.sanitize(SecurityContext.HTML, startDate);
		const sanitizedEndDate = this.domSanitizer.sanitize(SecurityContext.HTML, endDate);
		return {
			text: 'This date overlaps with:',
			link: 'https://articles.smartpass.app/en/articles/9764050-editing-school-year-and-terms',
			linkText: '',
			dataDetails: this.domSanitizer.bypassSecurityTrustHtml(
				`<div class="tw-py-3"><p class="tw-m-0">${sanitizedTermName}</p>
				<p class="tw-text-white tw-text-opacity-50 tw-m-0">${sanitizedStartDate} - ${sanitizedEndDate}</p></div>`
			),
		};
	}

	// this will be inclusive of the start and end date
	createToolTipDates(params: TooltipDateCreationParams): void {
		const date = moment.utc(params.firstDate);
		const end = moment.utc(params.lastDate);
		const tooltipDates: TooltipDateInfo[] = [];
		while (date.isSameOrBefore(end)) {
			if (date.day() !== 6 && date.day() !== 0) {
				if (params.disabledDates.length) {
					const disabledDate = params.disabledDates.find((disabledDate) => {
						return date.isBetween(moment.utc(disabledDate.start_date), moment.utc(disabledDate.end_date), 'day', '[]');
					});
					if (disabledDate) {
						const tooltipContent = this.getWithinExistingTermTooltipContent(
							disabledDate.term_name,
							moment.utc(disabledDate.start_date).format('MM/DD/YYYY'),
							moment.utc(disabledDate.end_date).format('MM/DD/YYYY')
						);
						const tooltipDate: TooltipDateInfo = this.getTooltipDate(date.format('YYYY-MM-DD'), true, disabledDate.exclusionId, tooltipContent);
						tooltipDates.push(tooltipDate);
					} else {
						const tooltipDate: TooltipDateInfo = this.getTooltipDate(date.format('YYYY-MM-DD'), false, null, null);
						tooltipDates.push(tooltipDate);
					}
				} else {
					const tooltipDate: TooltipDateInfo = this.getTooltipDate(date.format('YYYY-MM-DD'), false, null, null);
					tooltipDates.push(tooltipDate);
				}
			}
			date.add(1, 'day').toDate();
		}
		this.tooltipDates$.next(tooltipDates);
	}

	static getFirstWeekdayOfWeekFormatted(date: Date): string {
		const day = date.getDay(); // Get current day of the week (0-6, where Sunday is 0 and Monday is 1)
		const firstDay = new Date(date);

		// Calculate the difference to Monday
		// If it's Sunday (0), set to -6; otherwise, subtract the day number by 1
		const difference = day === 0 ? -6 : 1 - day;

		firstDay.setDate(date.getDate() + difference); // Adjust to Monday of that week

		// Format the date
		const dd = String(firstDay.getDate()).padStart(2, '0');
		const mm = String(firstDay.getMonth() + 1).padStart(2, '0'); //January is 0!
		const yyyy = firstDay.getFullYear();

		return `${yyyy}-${mm}-${dd}`;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
