import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { OldLoginService } from './services/old-login.service';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { StorageKeys, StorageService } from './services/storage.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private excludedCredUrls = ['email_info', 'discovery', 'storage.googleapis.com'];
	private urlsToLogOutIfUnauthorizedError = ['v1/users/@me', 'v1/schools', 'v1/locations', 'v1/pinnables/arranged'];

	constructor(
		private storageService: StorageService,
		private cookieService: CookieService,
		private oldLoginService: OldLoginService,
		private router: Router
	) {}

	private addCredentials(request: HttpRequest<unknown>): HttpRequest<unknown> {
		return request.clone({
			withCredentials: true,
		});
	}

	private urlMatchesExceptions(url: string): boolean {
		for (const exception of this.excludedCredUrls) {
			if (url.includes(exception)) {
				return true;
			}
		}

		return false;
	}

	private logOutIfError(url: string): boolean {
		for (const path of this.urlsToLogOutIfUnauthorizedError) {
			if (url.includes(path)) {
				return true;
			}
		}
		return false;
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const newRequest = this.urlMatchesExceptions(request.url) ? request : this.addCredentials(request);
		const requestUrl = newRequest.url;
		return next.handle(newRequest).pipe(
			catchError((error) => {
				// we only want to log the user out if the error is from a specific route
				// that are part of the app bootup sequence (user, school)
				if (error instanceof HttpErrorResponse && this.logOutIfError(requestUrl) && error.status === 401) {
					this.oldLoginService.stopCookieVerificationTimer$.next();
					this.oldLoginService.logout();
					return throwError(error);
				}
				return throwError(error);
			}),
			tap((response) => {
				if (response?.type === HttpEventType.Response) {
					const kioskLocation = response.headers.get('X-Kiosk-Mode-Location-Id');
					if (kioskLocation) {
						this.storageService.setItem(StorageKeys.kioskModeLocationId, kioskLocation);
					}
				}
			})
		);
	}
}
