import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EncountersState } from '../encounter-prevention-dialog.component';
import { EncounterPreventionService, ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';

@Component({
	selector: 'app-encounter-group-description',
	templateUrl: './encounter-group-description.component.html',
	styleUrls: ['./encounter-group-description.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterGroupDescriptionComponent implements OnInit {
	@Input() state!: EncountersState;
	@Input() group!: ExclusionGroupWithOverrides;
	@Input() collapseGroups = false;

	displayGroup!: ExclusionGroupWithOverrides;
	loading = false;

	constructor(private ePService: EncounterPreventionService, private cdRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		if (this.group.prevented_encounters.length === 0 && this.group.id) {
			this.loading = true;
			this.ePService.getExclusionGroup(this.group.id).subscribe((group) => {
				this.displayGroup = group;
				this.reverseOrder();
				this.loading = false;
				this.cdRef.markForCheck();
			});
		} else {
			this.displayGroup = this.group;
			this.reverseOrder();
		}
	}

	reverseOrder(): void {
		this.displayGroup.prevented_encounters = [...this.displayGroup.prevented_encounters].reverse();
	}
}
