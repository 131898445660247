<div
	[ngClass]="{
		'from-wrapper-to-date': formState.forLater,
		'from-wrapper': !formState.forLater,
		'no-fly-time-style': formState.noFlyTimeActive && !formState.forLater
	}">
	<div [ngClass]="headerTransition" class="header" #header [attr.data-motion-opacity]="(frameMotion$ | async).direction">
		<div *ngIf="isStaff || (!isStaff && formState.forLater)" class="heading">
			<div class="back-button back-button-grey" (click)="back()" *ngIf="!data?.fromAdmin && !this.formState.kioskMode">
				<img [src]="'./assets/Back-Button.svg' | resolveAsset" alt="Back to Student Select" />
			</div>
			<div *ngIf="isStaff && numSelectedStudents" style="margin-right: 16px" class="selected-students">
				<div class="student-tag" *ngIf="!groupName && numSelectedStudents == 1">
					<div
						*ngIf="studentSelected.profile_picture; else d"
						[ngStyle]="{ background: 'url(' + studentSelected.profile_picture + ') no-repeat left center/cover' }"
						class="profile-picture"></div>
					<ng-template #d>
						<img class="profile-picture" src="../../../../../assets/Avatar Default.svg" alt="Avatar" />
					</ng-template>
					<span class="text">{{ studentSelected.display_name }}</span>
				</div>
				<div class="student-tag" *ngIf="groupName || numSelectedStudents > 1">
					<img class="group-icon" [src]="'./assets/Group (Blue-Gray).svg' | resolveAsset" />
					<div class="text">{{ groupName || numSelectedStudentsText }}</div>
				</div>
			</div>
		</div>

		<div class="heading-content" [style.padding-top]="!isStaff ? '12px' : ''">
			<div class="location-icons">
				<img
					*ngIf="!liveOrigin || liveOrigin?.id !== originLocation?.id"
					class="origin-icon"
					[src]="'./assets/Open Ellipse (Navy-Blue).svg' | resolveAsset" />
				<span *ngIf="liveOrigin && liveOrigin?.id === originLocation?.id" class="pulse-origin-icon"></span>
				<img class="twinkie-icon" [src]="'./assets/Twinkie (Light-Gray).svg' | resolveAsset" />
				<img *ngIf="!destinationFocused" class="destination-icon" [src]="'./assets/Location (Blue-Gray).svg' | resolveAsset" />
				<img *ngIf="destinationFocused" class="destination-icon" [src]="'./assets/Location (Navy-Blue).svg' | resolveAsset" />
			</div>

			<div class="location-searches">
				<div
					class="search"
					[ngClass]="{ 'search-hovered': !originFocused }"
					[style.background-color]="originFocused && !lockOriginLocation ? '#FFFFFF' : '#F5F6F8'"
					[style.box-shadow]="originFocused && !lockOriginLocation ? '0px 0px 0px 4px #C7D2F066' : ''"
					(click)="!lockOriginLocation ? (originFocused = true) : null">
					<div *ngIf="lockOriginLocation || (originLocation && !originFocused)" class="selected-location">
						<div class="pinnable" [style.background]="originPinnableBackground">
							<img draggable="false" [style.width]="'13px'" [style.height]="'13px'" [src]="originPinnableIcon | resolveAsset" alt="ICON" />
						</div>
						<div class="text">
							{{ originLocation?.title }}
						</div>
						<div
							class="origin-count"
							*ngIf="
								isOriginWaitInLineEnabled && passLimits[originLocation.id]?.max_passes_from_active && passLimits[originLocation.id]?.from_count > 0
							"
							[style.background]="originPinnableBackground"
							customToolTip
							[contentTemplate]="originCountTooltip">
							{{ passLimits[originLocation.id]?.from_count }}
						</div>
						<ng-template #originCountTooltip>
							<app-pass-limit-tooltip
								[description]="
									passLimits[originLocation.id]?.from_count +
									'/' +
									passLimits[originLocation.id]?.max_passes_from +
									' students have passes from this room.'
								"
								[isHand]="true"></app-pass-limit-tooltip>
						</ng-template>
					</div>
					<app-round-input
						[ngClass]="{ 'origin-input': !originFocused }"
						*ngIf="!lockOriginLocation && (!originLocation || originFocused)"
						data-cy="search-inp-students"
						[value]="originSearchValue"
						[width]="'320px'"
						[padding]="'0px 5px 0px 12px'"
						[fieldIcon]="''"
						[backgroundColor]="originFocused ? '#FFFFFF' : '#F5F6F8'"
						[color]="'#1F195E'"
						[height]="'40px'"
						[border]="originFocused ? '1px solid #1F195E' : ''"
						[borderRadius]="'8px'"
						[focused]="originFocused"
						[noFocus]="true"
						[boxShadow]="false"
						[customBoxShadow]="originFocused ? '0px 0px 0px 4px #C7D2F066' : ''"
						(ontextupdate)="onSearch($event)"
						[placeholder]="'Coming from?'"
						[placeholderColor]="'#B7C1CF'"
						(multipleSearchFocusEvent)="onOriginFocus($event)"
						[multipleSearchType]="'origin'"
						#originInput>
					</app-round-input>
				</div>

				<div
					class="search"
					[ngClass]="{ 'search-hovered': !destinationFocused }"
					[style.background-color]="destinationFocused && !lockDestinationLocation ? '#FFFFFF' : '#F5F6F8'"
					[style.box-shadow]="destinationFocused && !lockDestinationLocation ? '0px 0px 0px 4px #C7D2F066' : ''"
					(click)="!lockDestinationLocation ? (destinationFocused = true) : null">
					<div *ngIf="lockDestinationLocation || (destinationLocation && !destinationFocused)" class="selected-location">
						<div class="pinnable" [style.background]="destinationPinnableBackground">
							<img draggable="false" [style.width]="'13px'" [style.height]="'13px'" [src]="destinationPinnableIcon | resolveAsset" alt="ICON" />
						</div>
						<div class="text">
							{{ destinationLocation.title }}
						</div>
					</div>
					<app-round-input
						[ngClass]="{ 'destination-input': !destinationFocused }"
						*ngIf="!lockDestinationLocation && (!destinationLocation || destinationFocused)"
						data-cy="search-inp-students"
						[value]="destinationSearchValue"
						[width]="'320px'"
						[padding]="'0px 5px 0px 12px'"
						[fieldIcon]="''"
						[backgroundColor]="destinationFocused ? '#FFFFFF' : '#F5F6F8'"
						[color]="'#1F195E'"
						[height]="'40px'"
						[border]="destinationFocused ? '1px solid #1F195E' : ''"
						[borderRadius]="'8px'"
						[focused]="destinationFocused"
						[noFocus]="true"
						[boxShadow]="false"
						[customBoxShadow]="destinationFocused ? '0px 0px 0px 4px #C7D2F066' : ''"
						(ontextupdate)="onSearch($event)"
						[placeholder]="'Going to?'"
						[placeholderColor]="'#B7C1CF'"
						(multipleSearchFocusEvent)="onDestinationFocus($event)"
						[multipleSearchType]="'destination'"
						#destinationInput>
					</app-round-input>
				</div>
			</div>
		</div>
	</div>

	<div class="from-content" #fromContent [attr.data-motion-translate]="(frameMotion$ | async).direction" (scroll)="tableScroll($event)" #rc>
		<div class="location-tables">
			<ng-container *ngIf="suggestedPinnables | async as sp">
				<app-location-table-v2
					#locationTable
					*ngIf="!categoryConfig"
					class="locations"
					[forStaff]="isStaff"
					[selectedStudents]="formState.data.roomStudents ? formState.data.roomStudents : formState.data.selectedStudents"
					[rightHeaderText]="true"
					[inputWidth]="'240px'"
					[forLater]="destinationFocused ? formState.forLater : true"
					[showStars]="false"
					[noRightStar]="true"
					[showPicture]="true"
					[showFavorites]="true"
					[hasLocks]="originFocused ? false : true"
					[invalidLocation]="
						destinationFocused && originLocation?.id ? originLocation?.id : originFocused && destinationLocation?.id ? destinationLocation?.id : ''
					"
					[originLocation]="destinationFocused ? originLocation : ''"
					[searchByTeacherName]="true"
					[placeholder]="placeholder"
					[dummyString]="'Check your spelling and try again.'"
					[currentPage]="originFocused ? 'from' : 'to'"
					[searchDisabled]="true"
					[updatedLocation$]="updatedLocation$"
					(onSelect)="originFocused ? originLocationSelected($event) : destLocationSelected($event); scrollToTop()"
					(onSelectPinnable)="destinationFocused ? pinnableSelected($event) : ''; scrollToTop()"
					[mergedAllRooms]="destinationFocused ? true : ''"
					[forKioskMode]="destinationFocused ? formState.kioskMode : ''"
					[passLimitInfo]="destinationFocused ? formState.passLimitInfo : ''"
					[hasSelectedPinnable]="hasSelectedPinnableValue"
					[showAsPinnables]="destinationFocused && !categoryConfig ? true : false"
					[favAsPinnables]="destinationFocused && !categoryConfig ? true : false"
					[loadPinnables]="true"
					[suggestedPinnables]="sp">
					<div *ngIf="originLocation && destinationFocused" pin-collection>
						<ng-container *ngIf="suggestedPinnables | async as suggestedPins">
							<ng-container *ngIf="suggestedPins && suggestedPins.length > 0">
								<div class="closest-rooms-header">Closest Rooms</div>
								<div class="pin-grid" [ngClass]="pinnableDimensions.isLarge ? 'grid-lg' : 'grid-sm'">
									<ng-container #ngForPinnables *ngFor="let pinnable of suggestedPins">
										<ng-container *ngIf="checkPinnable(false, pinnable)">
											<app-pinnable
												[height]="pinnableDimensions.height"
												[width]="pinnableDimensions.width"
												[valid]="pinnable.location && originLocation ? isValidPinnable(pinnable) : true"
												[forStaff]="isStaff && !formState.kioskMode"
												[passLimit]="pinnable.location ? passLimits[pinnable.location.id] : null"
												[disabledRoom]="pinnable.location ? !pinnable.location.enable : false"
												[forLater]="!!date"
												[pinnable]="pinnable"
												[currentPage]="'to'"
												[showActivePassesCounter]="isStaff"
												[isSameRoom]="pinnable.location && originLocation ? +pinnable.location.id === +originLocation.id : false"
												(onSelectEvent)="suggestedRoomSelected($event)"></app-pinnable>
										</ng-container>
									</ng-container>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</app-location-table-v2>

				<app-location-table-v2
					*ngIf="!!categoryConfig"
					#folderTable
					class="locations"
					[forStaff]="isStaff"
					[selectedStudents]="formState.data.roomStudents ? formState.data.roomStudents : formState.data.selectedStudents"
					[rightHeaderText]="true"
					[inputWidth]="'240px'"
					[forLater]="formState.forLater"
					[showStars]="false"
					[noRightStar]="true"
					[showPicture]="true"
					[showFavorites]="false"
					[hasLocks]="true"
					[invalidLocation]="originLocation?.id"
					[originLocation]="originLocation"
					[searchByTeacherName]="true"
					[placeholder]="placeholder"
					[dummyString]="'Check your spelling and try again.'"
					[currentPage]="'to'"
					[searchDisabled]="true"
					[updatedLocation$]="updatedLocation$"
					(onSelect)="destLocationSelected($event); categoryConfig = null"
					(onSelectPinnable)="pinnableSelected($event); categoryConfig = null"
					[mergedAllRooms]="true"
					[forKioskMode]="formState.kioskMode"
					[passLimitInfo]="formState.passLimitInfo"
					[hasSelectedPinnable]="hasSelectedPinnableValue"
					[showAsPinnables]="false"
					[loadPinnables]="true"
					[override]="categoryConfig"
					(folderBack)="folderBack()"
					[folderSearch]="true"
					[mainContentVisibility]="true"
					[suggestedPinnables]="sp">
				</app-location-table-v2>
			</ng-container>
		</div>
	</div>

	<app-pass-limit-info *ngIf="!isStaff" [passLimitInfo]="formState?.passLimitInfo"></app-pass-limit-info>
</div>
