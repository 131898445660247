import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SchoolActivity } from '../../../services/school-activity.service';
import {
	AddSchoolActivitySuccessAction,
	DeleteSchoolActivitySuccessAction,
	GetSchoolActivitiesByStatusSuccessAction,
	GetSchoolActivitiesSuccessAction,
	GetSchoolActivityByIdSuccessAction,
	UpdateSchoolActivitySuccessAction,
} from '../actions/school-activities.actions';

export const schoolActivitiesInitialState: EntityState<SchoolActivity> = {
	ids: [],
	entities: {},
};

export const schoolActivitiesAdapter: EntityAdapter<SchoolActivity> = createEntityAdapter<SchoolActivity>();

const reducer = createReducer(
	schoolActivitiesInitialState,
	on(AddSchoolActivitySuccessAction, (state, { schoolActivity }) => schoolActivitiesAdapter.addOne(schoolActivity, state)),
	on(GetSchoolActivitiesSuccessAction, (state, { schoolActivities }) => {
		if (schoolActivities) {
			return schoolActivitiesAdapter.setAll(schoolActivities, state);
		}
		return state;
	}),
	on(GetSchoolActivitiesByStatusSuccessAction, (state, { schoolActivities }) => {
		if (schoolActivities) {
			return schoolActivitiesAdapter.setAll(schoolActivities, state);
		}
		return state;
	}),
	on(GetSchoolActivityByIdSuccessAction, (state, { schoolActivity }) => {
		return schoolActivitiesAdapter.upsertOne(schoolActivity, state);
	}),
	on(UpdateSchoolActivitySuccessAction, (state, { schoolActivity }) => {
		return schoolActivitiesAdapter.upsertOne(schoolActivity, state);
	}),
	on(DeleteSchoolActivitySuccessAction, (state, { activityId }) => {
		return schoolActivitiesAdapter.removeOne(activityId, state);
	})
);

export function schoolActivitiesReducer(state: EntityState<SchoolActivity>, action: Action) {
	return reducer(state, action);
}
