import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { AllowMobileService } from '../services/allow-mobile.service';
import { AuthenticationService } from '../services/authentication.service';
import { StorageService } from '../services/storage.service';

@Injectable({
	providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
	constructor(
		private storage: StorageService,
		private allowMobile: AllowMobileService,
		private authenticationService: AuthenticationService,
		private router: Router
	) {}

	canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		const isAuthenticated$ = this.authenticationService.isAuthenticated$;
		// start with true for the canUseMobile value because it already depends on isAuthenticated, not starting
		// with a value means the guard would never resolve. A true value (as opposed to false) avoids an early
		// erroneous redirect to /mobile-restriction.
		const canUseMobile$ = this.allowMobile.canUseMobile$.pipe(startWith(true));
		const state$ = of(state);
		return combineLatest([isAuthenticated$, canUseMobile$, state$]).pipe(
			// TODO: remove these side effects
			tap(([isAuthenticated, canUseMobile, _state]) => {
				if (isAuthenticated) {
					if (this.storage.getItem('gg4l_invalidate')) {
						this.storage.removeItem('gg4l_invalidate');
					}

					this.storage.setItem('studentAllowMobile', canUseMobile);
				}
			}),
			map(([isAuthenticated, canUseMobile, state]) => {
				if (isAuthenticated && !canUseMobile) {
					return this.router.parseUrl('/mobile-restriction');
				} else if (!isAuthenticated) {
					const notAuthenticatedPath = state.url.includes('parent') ? 'parent-sign-up' : '';
					// Add the state query parameter
					const queryParams = { state: state.url };
					return this.router.createUrlTree([notAuthenticatedPath], { queryParams });
				} else {
					return true;
				}
			})
		);
	}
}
