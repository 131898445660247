<div class="wrapper">
	<ng-container *ngIf="!loading; else loadingState">
		<div class="content tw-animate-fadein-200" *ngIf="displayGroup.prevented_encounters?.length; else noPrevented">
			<div class="count">
				<div class="info">
					<app-octagon [size]="28" [backgroundColor]="'transparent'" [borderColor]="'#E32C66'" [borderWidth]="5">
						<div class="value" style="color: #e32c66">{{ displayGroup.prevented_encounters.length }}</div>
					</app-octagon>
					<div class="ds-dialog-subtitle" style="margin-left: 7px">Prevented Encounters</div>
					<span *ngIf="displayGroup.encounter_count" class="tw-ml-1.5 tw-text-gray-400 tw-font-normal tw-text-base"
						>{{ displayGroup.encounter_count }} Override<span *ngIf="displayGroup.encounter_count !== 1">s</span></span
					>
				</div>
			</div>

			<div class="encounters">
				<div class="encounter" *ngFor="let encounter of displayGroup.prevented_encounters">
					<app-report-description [collapse]="collapseGroups" [encounter]="encounter" [group]="displayGroup"></app-report-description>
				</div>
				<div class="created">
					<span>Created</span> {{ displayGroup.created | date }} at {{ displayGroup.created | date : 'hh:mm a' }}
					<span *ngIf="displayGroup.issuer">by {{ displayGroup.issuer }}</span>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-template #loadingState>
		<div class="tw-flex tw-mt-44 tw-ml-44 tw-animate-fadein-200">Loading...</div>
	</ng-template>

	<ng-template #noPrevented>
		<div class="empty-state">
			<div class="container">
				<app-octagon [size]="64" [backgroundColor]="'#d1d4db'" [borderColor]="'#bbbfc9'">
					<div class="value" style="font-size: 30px">0</div>
				</app-octagon>
				<div class="text">No prevented encounters yet.</div>
				<div class="subtitle">
					When one student tries to make a pass when another student has a pass, it won’t let them and show an error.
					<span style="font-weight: bold">When this happens, it will show up here.</span>
				</div>
			</div>
		</div>
	</ng-template>
</div>
