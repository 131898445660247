<div class="sc-search-container tw-p-4">
	<div class="sc-title">Accounts</div>
	<div *ngIf="loading" class="sc-loading-box">
		<mat-spinner></mat-spinner>
	</div>
	<div style="position: relative" *ngIf="!loading">
		<div class="search-box-container" [ngClass]="{ inactive: !isSearchFocused }" (click)="setFocusOnSearch()">
			<div *ngFor="let selection of selectedUsers">
				<app-selection [selection]="selection" [isUser]="!!selection.display_name" (removeSelectionEvent)="onRemove($event)"></app-selection>
			</div>
			<input
				#searchInput
				class="dynamic-search-box"
				[placeholder]="selectedUsers.length === 0 ? 'Search teachers and admins' : ''"
				[(ngModel)]="searchText"
				(input)="searchInputChanges$.next($event.target.value)"
				(focus)="onSearchFocus(true)"
				(blur)="onSearchFocus(false)" />
		</div>
		<div
			*ngIf="isSearchFocused && (searchResults?.length !== 0 || searchText.length === 0 || usersLoaded)"
			class="sc-search-results groups-students-list">
			<mat-list [style.padding-top]="'0px'">
				<mat-list-item *ngFor="let result of searchResults" class="sc-search group" (mousedown)="onSelect(result)" #_item>
					<div class="sc-search sc-search-selection" [matTooltip]="result.display_name" matTooltipClass="sc-tooltip" matTooltipPosition="below">
						<div
							*ngIf="result.profile_picture; else d"
							[ngStyle]="{ background: 'url(' + result.profile_picture + ') no-repeat left center/cover' }"
							class="profile-picture"></div>
						<ng-template #d>
							<img class="profile-picture" src="../../../../../assets/Avatar Default.svg" alt="Avatar" />
						</ng-template>
						<span class="sc-search name">{{ result.display_name }}</span>
					</div>
				</mat-list-item>
				<div *ngIf="searchResults?.length === 0 && searchText?.length > 0 && usersLoaded" class="no-results-found-container">No Results Found</div>
				<div *ngIf="searchText?.length === 0 && selectedUsers?.length === 0" class="no-results-found-container empty-search"></div>
			</mat-list>
		</div>
		<div class="tw-mt-2.5 tw-text-gray-500 tw-font-normal tw-text-sm">
			Admins or teachers who can override Encounter Prevention. It can take several minutes for this to update.
		</div>
		<div class="tw-flex tw-justify-end tw-mt-10 tw-gap-2 tw-text-sm tw-font-medium">
			<div class="tw-cursor-pointer tw-rounded-lg tw-p-3 tw-bg-gray-100 tw-text-gray-500" (click)="overrideRef.close()">Cancel</div>
			<div
				class="tw-cursor-pointer tw-rounded-lg tw-py-3 tw-px-9 tw-bg-green-500 tw-text-white"
				(click)="onSubmit()"
				[ngClass]="{ 'add-btn-disabled': loading }">
				Save
			</div>
		</div>
	</div>
</div>
