import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CreateFormService, FrameMotionTransition } from '../../create-hallpass-forms/create-form.service';
import { NextStep } from '../../animations';
import { User } from '../../models/User';
import { ToastService } from '../../services/toast.service';
import { ElementRef } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';
import { HallPass } from 'app/models/HallPass';
import { EncounterPreventionDialogComponent } from 'app/admin/accounts/encounter-prevention-dialog/encounter-prevention-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-encounter-prevention-tooltip',
	templateUrl: './encounter-prevention-tooltip.component.html',
	styleUrls: ['./encounter-prevention-tooltip.component.scss'],
	animations: [NextStep],
})
export class EncounterPreventionTooltipComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() groups!: ExclusionGroupWithOverrides[];
	@Input() user!: User;
	@Input() conflictStudentIds: number[] = [];
	@Input() conflictPasses: HallPass[] = [];
	@Output() leave = new EventEmitter<void>();

	frameMotion$!: BehaviorSubject<FrameMotionTransition>;
	filteredGroups: ExclusionGroupWithOverrides[] = [];
	pageIndex = 0;
	selectedGroup!: ExclusionGroupWithOverrides;

	private originalZIndex = '';

	constructor(private formService: CreateFormService, private toast: ToastService, private elRef: ElementRef, private dialog: MatDialog) {}

	ngOnInit(): void {
		this.setFilteredGroups();
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.setGroupIndex(0);
	}

	ngAfterViewInit() {
		const cdkOverlayContainer = this.elRef.nativeElement.ownerDocument.querySelector('.cdk-overlay-container');
		this.originalZIndex = window.getComputedStyle(cdkOverlayContainer).zIndex;
		cdkOverlayContainer.style.zIndex = '9999999999';
	}

	nextGroup(): void {
		if (this.pageIndex < this.filteredGroups.length - 1) {
			this.pageIndex++;
		}
		this.setSelectedGroup();
	}

	prevGroup(): void {
		if (this.pageIndex !== 0) {
			this.pageIndex--;
		}
		this.setSelectedGroup();
	}

	setGroupIndex(index: number): void {
		this.pageIndex = index;
		this.setSelectedGroup();
	}

	private setSelectedGroup(): void {
		this.selectedGroup = this.filteredGroups[this.pageIndex];
	}

	private setFilteredGroups(): void {
		let matchedGroups = this.groups.filter((group) => group.enabled);

		if (this.conflictStudentIds?.length > 0) {
			matchedGroups = this.groups.filter((group) =>
				this.conflictStudentIds.some((studentId) => group.users.some((student: User) => student.id === studentId))
			);
		}

		if (matchedGroups.length > 0) {
			if (this.conflictPasses.length > 0) {
				this.filteredGroups = matchedGroups.filter((group) =>
					this.conflictPasses.some((pass) => group.users.some((student: User) => student.id === pass.student.id))
				);
			} else if (this.conflictStudentIds.length > 0) {
				this.filteredGroups = matchedGroups.filter((group) =>
					this.conflictStudentIds.some((studentId) => group.users.some((student: User) => student.id === studentId))
				);
			} else {
				this.filteredGroups = matchedGroups;
			}
		}
	}

	goToEncounterPreventionGroup() {
		const user = User.fromJSON(this.user);
		if (user.isStaff()) {
			this.dialog.open(EncounterPreventionDialogComponent, {
				panelClass: 'overlay-dialog',
				backdropClass: 'custom-bd',
				width: '425px',
				height: '500px',
				data: { forceNextPage: 'groupDescription', currentUser: user, forceGroup: this.selectedGroup },
			});
			this.toast.closeAllToasts();
		}
	}

	ngOnDestroy() {
		const cdkOverlayContainer = this.elRef.nativeElement.ownerDocument.querySelector('.cdk-overlay-container');
		cdkOverlayContainer.style.zIndex = this.originalZIndex;
	}
}
