import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { StudentList } from '../../../models/StudentList';
import * as groupsActions from '../actions';
import { GroupsStates } from '../states';

export const groupsAdapter: EntityAdapter<StudentList> = createEntityAdapter<StudentList>();

export const groupsInitialState: GroupsStates = groupsAdapter.getInitialState({
	loading: false,
	loaded: false,
	currentGroupId: null,
});

const reducer = createReducer(
	groupsInitialState,
	on(groupsActions.getStudentGroups, groupsActions.postStudentGroup, groupsActions.updateStudentGroup, groupsActions.removeStudentGroup, (state) => ({
		...state,
		loading: true,
		loaded: false,
	})),
	on(groupsActions.getStudentGroupsSuccess, (state, { groups }) => {
		return groupsAdapter.setAll(groups, { ...state, loading: false, loaded: true });
	}),
	on(groupsActions.updateStudentGroupSuccess, (state, { group }) => {
		return groupsAdapter.upsertOne(group, { ...state, loading: false, loaded: true, currentGroupId: group.id });
	}),
	on(groupsActions.removeStudentGroupSuccess, (state, { id }) => {
		return groupsAdapter.removeOne(+id, { ...state, loading: false, loaded: true, currentGroupId: id });
	}),
	on(groupsActions.postStudentGroupSuccess, (state, { group }) => {
		return groupsAdapter.addOne(group, { ...state, loading: false, loaded: true, currentGroupId: group.id });
	}),
	on(groupsActions.getStudentsGroupsFailure, (state, { errorMessage }) => ({ ...state, loading: false, loaded: true }))
);

export function studentGroupsReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
