<div class="page-content tw-flex tw-flex-col tw-gap-10 tw-my-10">
	<div
		class="top-header"
		[ngClass]="{
			'': !darkTheme.isEnabled$.value,
			__dark: darkTheme.isEnabled$.value
		}">
		<span class="tw-text-xl tw-font-bold">Flex Periods</span>

		<div class="top-right-header">
			<div class="flex-feedback-button">
				<app-gradient-button
					[size]="'medium'"
					[leftIcon]="'./assets/Feedback (Blue-Gray).svg' | resolveAsset"
					[gradient]="'#E2E5EA, #E2E5EA'"
					[textColor]="'#7083A0'"
					[cursor]="'pointer'"
					(buttonClick)="openIntercom()">
					<div>Feedback?</div>
				</app-gradient-button>
			</div>

			<div class="create-period-button" (click)="openCreateFlex()" data-px-id="flex--create-period">
				<img [src]="'./assets/Plus (White).svg' | resolveAsset" alt="Add Flex Period" />
				<span>Create Period</span>
			</div>
		</div>
	</div>
	<ng-container *ngIf="school?.attendance_access">
		<sp-attendance></sp-attendance>
	</ng-container>
	<ng-container *ngIf="school">
		<app-flex-stats
			data-renderer="react"
			*ngFor="let period of flexPeriods"
			[flexPeriod]="period"
			[schoolId]="school.id"
			[numStudents]="studentCount"
			[timeZone]="school.timezone"
			(deleteFlexPeriod)="deletePeriod(period.id)"></app-flex-stats>
	</ng-container>
</div>

<ng-template #deleteDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 15px !important; margin: 4px 0">
		When you remove a flex period, activity sessions that have students in them will still exist, but new sessions or activities can't be created
	</p>
</ng-template>
