import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { HallPass } from '../../../../../models/HallPass';
import * as hallMonitorActions from '../actions';
import { IHallMonitorPassesState } from '../states';

export const adapter: EntityAdapter<HallPass> = createEntityAdapter<HallPass>();

export const hallMonitorPassesInitialState: IHallMonitorPassesState = adapter.getInitialState({
	loading: false,
	loaded: false,
});

const reducer = createReducer(
	hallMonitorPassesInitialState,
	on(hallMonitorActions.getHallMonitorPasses, hallMonitorActions.updateHallMonitorPasses, (state) => ({ ...state, loading: true, loaded: false })),
	on(hallMonitorActions.getHallMonitorPassesSuccess, hallMonitorActions.updateHallMonitorPassesSuccess, (state, { hallMonitorPasses }) => {
		return adapter.setAll(hallMonitorPasses, { ...state, loading: false, loaded: true });
	})
);

export function hallMonitorPassesReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
