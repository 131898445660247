import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeatureFlagService, FLAGS } from '../../services/feature-flag.service';
import { FeatureFlagPanelComponent } from '../feature-flag-panel/feature-flag-panel.component';

@Component({
	selector: 'settings-footer',
	template: `
		<div class="options" [style.color]="color">
			<span class="option" appCrossPointerEventTarget (pointerClickEvent)="openPrivacy.emit()">Privacy</span>
			<span class="option" appCrossPointerEventTarget (pointerClickEvent)="openTerms.emit()">Terms</span>
			<span *ngIf="showFeatureFlagPanel" class="option" appCrossPointerEventTarget (pointerClickEvent)="openFeatureFlagPanel()">Feature Flags</span>
		</div>
	`,
	styles: [
		`
			.options {
				display: flex;
				color: #7f879d;
				font-size: 12px;
				justify-content: center;
				margin-bottom: 12px;
			}

			.options .option {
				margin-left: 10px;
				cursor: pointer;
			}

			.options .option:hover {
				text-decoration: underline;
			}
		`,
	],
})
export class SettingsFooterComponent {
	@Input() color: string;
	@Output() openPrivacy = new EventEmitter<void>();
	@Output() openTerms = new EventEmitter<void>();
	showFeatureFlagPanel = false;

	constructor(private featureFlagService: FeatureFlagService, private dialog: MatDialog) {
		this.showFeatureFlagPanel = this.featureFlagService.isFeatureEnabledV2(FLAGS.FeatureFlagPanel);
	}

	openFeatureFlagPanel() {
		this.dialog.open(FeatureFlagPanelComponent, {
			panelClass: 'feature-flag-dialog-panel',
			width: '425px',
			height: '500px',
		});
	}
}
