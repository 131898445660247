import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
	selector: '[appCrossPointerEventTarget]',
})
export class CrossPointerEventTargetDirective implements OnInit, OnDestroy {
	@Output() pointerDownEvent: EventEmitter<MouseEvent | TouchEvent> = new EventEmitter();
	@Output() pointerClickEvent: EventEmitter<MouseEvent | TouchEvent> = new EventEmitter();
	@Output() pointerCancelEvent: EventEmitter<null> = new EventEmitter();

	private interactionStartUnlisten: () => void;
	private interactionEndUnlisten: () => void;

	constructor(private elementRef: ElementRef<HTMLElement>, private renderer2: Renderer2) {}

	ngOnInit(): void {
		const target = this.elementRef.nativeElement;

		if ('ontouchend' in document.documentElement) {
			this.interactionStartUnlisten = this.renderer2.listen(target, 'touchend', (evt: TouchEvent) => {
				const rect = (evt.target as HTMLElement).getBoundingClientRect();
				const singleTouch = evt.changedTouches[0];
				const allowTouch = {
					x: singleTouch.clientX >= rect.left && singleTouch.clientX <= rect.right,
					y: singleTouch.clientY >= rect.top && singleTouch.clientY <= rect.bottom,
				};
				if (evt.cancelable && Object.values(allowTouch).every((v) => !!v)) {
					evt.preventDefault();
					this.pointerClickEvent.emit(evt);
				} else {
					this.pointerCancelEvent.emit(null);
				}
			});
		} else {
			this.interactionStartUnlisten = this.renderer2.listen(target, 'click', (evt: MouseEvent) => {
				this.pointerClickEvent.emit(evt);
			});
		}

		if ('ontouchstart' in document.documentElement) {
			this.interactionEndUnlisten = this.renderer2.listen(target, 'touchstart', (evt: TouchEvent) => {
				this.pointerDownEvent.emit(evt as TouchEvent);
			});
		} else {
			this.interactionEndUnlisten = this.renderer2.listen(target, 'mousedown', (evt: MouseEvent) => {
				this.pointerDownEvent.emit(evt);
			});
		}
	}

	ngOnDestroy(): void {
		if (this.interactionStartUnlisten) {
			this.interactionStartUnlisten();
		}

		if (this.interactionEndUnlisten) {
			this.interactionEndUnlisten();
		}
	}
}
