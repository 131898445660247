<div class="wrapper">
	<app-round-input
		[style.margin]="'20px'"
		[minWidth]="'20px auto 5px auto'"
		[width]="inputWidth"
		[type]="'text'"
		[placeholder]="placeholder"
		[fieldIconPosition]="'left'"
		[closeIcon]="true"
		[focused]="isFocused"
		[backgroundColor]="!screenService.isDeviceLargeExtra ? '#FFFFFF' : '#F4F4F4'"
		(ontextupdate)="onSearch($event)"></app-round-input>
	<div class="main-content" *ngIf="type === 'location' ? mainContentVisibility && favoritesLoaded : mainContentVisibility">
		<div *ngIf="type === 'location' && showFavorites && !hideFavorites" class="table-left">
			<div class="fav-header" *ngIf="!forKioskMode">Favorites</div>
			<mat-divider class="divider" *ngIf="!forKioskMode"></mat-divider>

			<!-- Favorite Window -->
			<div class="favorite" *ngIf="favoritesLoaded && isFavoriteForm && !forKioskMode && pinnablesLoaded">
				<div class="choices" cdkDropList (cdkDropListDropped)="updateOrderLocation($event)">
					<div class="choice" *ngFor="let choice of starredChoices">
						<div class="example-custom-placeholder" *cdkDragPlaceholder></div>
						<app-location-cell
							*ngIf="choice.passLimit || isFavoriteForm"
							(onSelect)="choiceSelected($event)"
							(onStar)="star($event)"
							[value]="choice"
							[forStaff]="forStaff"
							[currentPage]="currentPage"
							[passLimit]="choice.passLimit"
							[starred]="true"
							[showStar]="!!showStars"
							[allowOnStar]="allowOnStar"
							[isFavorite]="true">
						</app-location-cell>
					</div>
				</div>
			</div>

			<!-- Create Pass Form -->
			<div *ngIf="favoritesLoaded && !isFavoriteForm && !forKioskMode && pinnablesLoaded" class="choices">
				<div class="choice" *ngFor="let choice of starredChoices">
					<app-location-cell
						*ngIf="choice.passLimit || !isFavoriteForm"
						(onSelect)="choiceSelected($event)"
						(onStar)="star($event)"
						[value]="choice.normalizedLocation"
						[currentPage]="currentPage"
						[passLimit]="choice.passLimit"
						[hasLocks]="hasLocks"
						[forLater]="forLater"
						[forStaff]="forStaff"
						[starred]="true"
						[showStar]="!!showStars"
						[allowOnStar]="allowOnStar"
						[valid]="choice.isValidLocation"
						[isSameRoom]="choice.disabledToolTip">
					</app-location-cell>
				</div>
			</div>

			<div *ngIf="starredChoices.length < 1 && !forKioskMode" class="no-favs" ondragstart="return false">
				<img class="star-icon" [src]="'./assets/Star (Yellow).svg'" />
				<div>
					<span class="ds-dialog-bold-text ds-pb-5">No Favorite Rooms</span> <br />
					<span class="ds-cell-description-text">Add favorites {{ !isFavoriteForm ? 'in settings' : '' }}</span>
				</div>
			</div>
			<div *ngIf="rightHeaderText && !hideFavorites" [style.margin-top]="forKioskMode ? '' : '25px'" class="fav-header">All Rooms</div>
			<mat-divider class="divider"></mat-divider>
		</div>
		<div class="table-right" [style.margin]="'0 ' + (showFavorites ? '0' : 'auto')" *ngIf="choices && !noChoices && pinnablesLoaded">
			<div class="quick-label" *ngIf="!search">Quick Select</div>
			<div *ngIf="loading$ | async; else result" class="loading-theme_blue-gray spinner ds-w-100 ds-h-100 ds-flex-center-center">
				<mat-spinner [diameter]="35" [color]="'accent'"></mat-spinner>
			</div>

			<ng-template #result>
				<div class="choices">
					<div class="choice-wrapper" *ngFor="let choice of choices" #item>
						<app-location-cell
							*ngIf="(choice.passLimit && choice.roomIsHidden) || isFavoriteForm"
							class="choice"
							[hasLocks]="hasLocks"
							[forLater]="forLater"
							[forStaff]="forStaff"
							[showStar]="showStars && !noRightStar"
							[starred]="choice.isSelected"
							[currentPage]="currentPage"
							[passLimit]="choice.passLimit"
							(onSelect)="choiceSelected($event)"
							[disabledRoom]="!choice.enable"
							(onStar)="star($event)"
							[allowOnStar]="allowOnStar"
							[value]="choice.normalizedLocation"
							[valid]="choice.isValidLocation"
							[isSameRoom]="choice.disabledToolTip"
							[kioskMode]="forKioskMode">
						</app-location-cell>
					</div>
				</div>
			</ng-template>
		</div>
		<div *ngIf="(loaded$ | async) && noChoices">
			<div>
				<img [src]="'./assets/emptyStates/no-matches-found.svg'" width="150" [style.margin]="'20px'" alt="no-matches-found.svg" />
			</div>
			<div style="color: #999999; font-size: 20px">
				<span>{{ dummyString }}</span>
			</div>
		</div>
	</div>
</div>
