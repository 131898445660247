import { Action, createReducer, on } from '@ngrx/store';
import * as introsActions from '../actions';
import { IIntrosState } from '../state';

export const introsInitialState: IIntrosState = {
	data: {},
	loading: false,
	loaded: false,
};

const reducer = createReducer(
	introsInitialState,
	on(
		introsActions.getIntros,
		introsActions.updateIntros,
		introsActions.updateIntrosShareSmartpass,
		introsActions.updateIntrosStudentPassLimits,
		introsActions.updateIntrosAdminPassLimitsMessage,
		introsActions.updateNewAdminHasSeenGetStarted,
		(state) => ({ ...state, loading: true, loaded: false, data: {} })
	),
	on(
		introsActions.getIntrosSuccess,
		introsActions.updateIntrosSuccess,
		introsActions.updateIntrosShareSmartpassSuccess,
		introsActions.updateIntrosStudentPassLimitsSuccess,
		introsActions.updateIntrosAdminPassLimitsMessageSuccess,
		introsActions.updateNewAdminHasSeenGetStartedSuccess,
		(state, { data }) => {
			return { ...state, loading: false, loaded: true, data: { ...state.data, ...data } };
		}
	)
);

export function IntrosReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
