<div class="trial-bar" [ngClass]="bannerClass" *ngIf="isAdmin; else teacherFreeTrial" [ngStyle]="getBannerStyle()">
	<!--------------- SUSPENDED ACCOUNT --------------->
	<ng-container *ngIf="(bannerType === 'customer' || bannerType === 'churned') && suspendedAccount">
		<div class="exclamation">!</div>
		<div class="left-container">
			<div class="trial-intro">Your School’s Account is Suspended</div>
			<div class="remaining-time">
				<span>Thank you for being a SmartPass customer. We’re looking forward to continuing our partnership.</span>
			</div>
		</div>

		<div class="button-container helpContainer">
			<button (click)="openIntercom()" class="help">Need Help?</button>
			<button (click)="externalPricingLink()" class="purchase">View Renewal Options</button>
		</div>
	</ng-container>
	<!--------------- CUSTOMER --------------->
	<ng-container *ngIf="bannerType === 'customer' && daysRemaining <= customerSubscriptionSecondWarning && !suspendedAccount">
		<sp-circle-countdown
			[diameter]="40"
			[ngStyle]="{ margin: '0 18px 0 2px' }"
			[progress]="activeAccount ? progressBar : suspendProgressBar"
			[wideCircleStroke]="true">
		</sp-circle-countdown>
		<div class="left-container">
			<div *ngIf="activeAccount; else trialEnded" class="trial-intro">It's Time to Renew</div>
			<div *ngIf="activeAccount" class="remaining-time">
				<span class="days">{{ daysRemaining }} </span> {{ getDayString(daysRemaining) }} left until your subscription expires.
			</div>
		</div>

		<div class="button-container helpContainer">
			<button (click)="openIntercom()" class="help">Need Help?</button>
			<button (click)="externalPricingLink()" class="purchase">View Renewal Options</button>
		</div>

		<ng-template #trialEnded>
			<div class="left-container">
				<div class="trial-intro">It’s Time to Renew</div>
				<div class="remaining-time">
					<span class="days">{{ suspensionDaysRemaining }}</span> {{ getDayString(suspensionDaysRemaining) }} left until your school's account is
					suspended.
				</div>
			</div>
		</ng-template>
	</ng-container>
	<!--------------- PAYMENT OVERDUE --------------->
	<ng-container *ngIf="bannerType === 'payment_overdue'">
		<sp-circle-countdown [diameter]="40" [ngStyle]="{ margin: '0 18px 0 2px' }" [progress]="suspendProgressBar" [wideCircleStroke]="true">
		</sp-circle-countdown>
		<div class="left-container">
			<div class="trial-intro">Payment is Overdue</div>
			<div class="remaining-time">
				<span class="days">{{ suspensionDaysRemaining }} </span> {{ getDayString(suspensionDaysRemaining) }} left until your school's account is
				suspended.
			</div>
		</div>

		<div class="button-container helpContainer">
			<button (click)="openIntercom()" class="help">Need Help?</button>
		</div>
	</ng-container>
	<!--------------- FREE TRIAL --------------->
	<ng-container [ngSwitch]="bannerType">
		<ng-container *ngSwitchCase="BannerType.Trial">
			<sp-circle-countdown
				[diameter]="40"
				[ngStyle]="{ margin: '0 18px 0 2px' }"
				[progress]="freeTrialEarlyAccessProgressBar"
				[wideCircleStroke]="true">
			</sp-circle-countdown>
			<div class="left-container">
				<div *ngIf="!suspendedAccount; else trialEnded" class="trial-intro">Free Trial in Progress</div>
				<div *ngIf="!suspendedAccount" class="remaining-time">
					<span class="days">{{ suspensionDaysRemaining }}</span> {{ getDayString(suspensionDaysRemaining) }} left
				</div>
			</div>

			<div class="button-container helpContainer">
				<button (click)="openIntercom()" class="help">Need Help?</button>
				<button (click)="externalPricingLink()" class="purchase">View Pricing</button>
			</div>

			<ng-template #trialEnded>
				<div class="left-container">
					<div class="trial-intro">Free Trial Ended</div>
					<div class="remaining-time">We hope you've loved SmartPass and are planning on moving forward.</div>
				</div>
			</ng-template>
		</ng-container>
		<!--------------- EARLY ACCESS --------------->
		<ng-container *ngSwitchCase="BannerType.EarlyAccess">
			<sp-circle-countdown
				[diameter]="40"
				[ngStyle]="{ margin: '0 18px 0 2px' }"
				[progress]="freeTrialEarlyAccessProgressBar"
				[wideCircleStroke]="true">
			</sp-circle-countdown>
			<div class="left-container">
				<div *ngIf="!suspendedAccount; else trialEnded" class="trial-intro">Early Access in Progress</div>
				<div *ngIf="!suspendedAccount" class="remaining-time">
					<span class="days">{{ suspensionDaysRemaining }}</span> {{ getDayString(suspensionDaysRemaining) }} left
				</div>
			</div>

			<div class="button-container helpContainer">
				<button (click)="openIntercom()" class="help">Need Help?</button>
				<button (click)="externalPricingLink()" class="purchase">View Pricing</button>
			</div>

			<ng-template #trialEnded>
				<div class="left-container">
					<div class="trial-intro">Early Access Ended</div>
					<div class="remaining-time">We hope you've loved SmartPass and are planning on moving forward.</div>
				</div>
			</ng-template>
		</ng-container>
	</ng-container>
</div>

<!--------------- TEACHER FREE TRIAL --------------->
<ng-template #teacherFreeTrial>
	<ng-container *ngIf="!isAdmin">
		<div class="trial-bar" [ngClass]="bannerClass" [ngStyle]="getBannerStyle()">
			<sp-circle-countdown
				[diameter]="40"
				[ngStyle]="{ margin: '0 18px 0 2px' }"
				[progress]="freeTrialEarlyAccessProgressBar"
				[wideCircleStroke]="true">
			</sp-circle-countdown>
			<div class="left-container">
				<div *ngIf="!suspendedAccount; else trialEnded" class="trial-intro">Free Trial in Progress</div>
				<div *ngIf="!suspendedAccount && suspensionDaysRemaining <= 14" class="remaining-time">
					<span class="days">{{ suspensionDaysRemaining }}</span> {{ getDayString(suspensionDaysRemaining) }} left
				</div>
			</div>

			<div class="button-container helpContainer">
				<button (click)="onFeedbackClick()" class="refiner">How {{ tenseString }} you like SmartPass?</button>
			</div>

			<ng-template #trialEnded>
				<div class="left-container">
					<div class="trial-intro">Free Trial Ended</div>
					<div class="remaining-time">We hope you've loved SmartPass and are planning on moving forward.</div>
				</div>
			</ng-template>
		</div>
	</ng-container>
</ng-template>
